import { Organization, OrganizationType, PlanType } from '@vegga-types/organization';
import { SessionStore } from '../models/session.store';
import { OrganizationFeaturesEnum } from '@vegga-statics/enums';

export class OrganizationStore extends SessionStore<Organization> {
  private static instance: OrganizationStore;

  private constructor(storeName: string) {
    super(storeName);
  }

  static getInstance(): OrganizationStore {
    if (!OrganizationStore.instance) {
      OrganizationStore.instance = new OrganizationStore('organization_store');
    }
    return OrganizationStore.instance;
  }

  storeOrganization(organization: Organization) {
    this.store(organization);
  }

  hasPermission(permission: string): boolean {
    return this.get().permissions.includes(permission);
  }

  hasPlan(plan: PlanType): boolean {
    return this.get().plan === plan;
  }

  getFeature(featureId: string): string | number | boolean | [] {
    const feature = this.get().features && this.get().features.find(feature => feature.key === featureId);
    return (feature && feature.value) || undefined;
  }

  isType(type: OrganizationType): boolean {
    return this.get().type === type;
  }

  getRole(): string {
    return (this.get() && this.get().role) || '';
  }

  getPlan(): string {
    return (this.get() && this.get().plan) || '';
  }

  getType(): string {
    return (this.get() && this.get().type) || '';
  }

  isMigratedOrg() {
    return this.getFeature(OrganizationFeaturesEnum.ORGANIZATION_USERS_MIGRATED);
  }
}
