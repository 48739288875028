import {
  A2500HistoryApi,
  Get2500AnalogHistoricalDataRequest,
  Get2500countersHistoricalDataRequest,
  Get2500flowHistoricalDataRequest,
  Get2500sectorsHistoricalDataExcelRequest,
  Get2500sectorsHistoricalDataRequest,
  Get2500countersHistoricalDataExcelRequest,
  Get2500flowHistoricalDataExcelRequest,
  Get2500analogHistoricalDataExcelRequest,
  A4500HistoryApi,
  Get4500generalHistoricalDataRequest,
  Get4500logicHistoricalDataRequest,
  Get4500averagePhCeHistoricalDataRequest,
  Get4500fertdepositsHistoricalDataRequest,
  Get4500sectorsHistoricalDataRequest,
  Get4500AnalogHistoricalDataRequest,
  Get4500countersHistoricalDataRequest,
  Get4500generalHistoricalDataExcelRequest,
  Get4500sectorsHistoricalDataExcelRequest,
  Get4500analogHistoricalDataExcelRequest,
  Get4500counterHistoricalDataExcelRequest,
  Get4500logicHistoricalDataExcelRequest,
  Get4500averagePhCeHistoricalDataExcelRequest,
  Get4500fertdepositsHistoricalDataExcelRequest,
  A4000HistoryApi,
  Get4000ProgramHistoricalDataRequest,
  Get4000filtersHistoricalDataExcelRequest,
  Get4000FilterHistoricalDataRequest,
  Get4000AnalogHistoricalDataRequest,
  Get4000CounterHistoricalDataRequest,
  Get4000IntegratedsHistoricalDataRequest,
  Get4000programsHistoricalDataExcelRequest,
  Get4000analogHistoricalDataExcelRequest,
  Get4000counterHistoricalDataExcelRequest,
  Get4000integratedHistoricalDataExcelRequest,
  Get4000sectorsHistoricalDataRequest,
  Get4000sectorsHistoricalDataExcelRequest,
  ABITHistoryApi,
  GetBITsectorsHistoricalDataRequest,
  GetBITsectorsHistoricalDataExcelRequest,
  GetBITAnalogHistoricalDataRequest,
  GetBITanalogHistoricalDataExcelRequest,
  GetBITcountersHistoricalDataRequest,
  GetBITcounterHistoricalDataExcelRequest,
  GetBITflowHistoricalDataRequest,
  GetBITflowHistoricalDataExcelRequest,
  A4000MeterSensorApi,
  GetMeterListA4000Request,
  A4000MeterSensorItem,
} from '@vegga-api-clients/irrigation-control-service';
import { SensorTypesEnum, A4500HistoryTypesEnum, A2500HistoryTypesEnum, A4000HistoryTypesEnum, ABITHistoryTypesEnum } from '@vegga-statics/enums';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { HttpClient } from '../../http';
import { VeggaFacade } from '../vegga-facade';
import { VeggaLoader } from '../../http/veggaLoader';
import {
  A2500HistoricalDataExcelRequest,
  A2500HistoricalDataRequest,
  A2500HistoryDataResponse,
  A4000HistoricalDataExcelRequest,
  A4000HistoricalDataRequest,
  A4000HistoricalDataResponse,
  A4500HistoricalDataExcelRequest,
  A4500HistoricalDataRequest,
  A4500HistoryDataResponse,
  ABITHistoricalDataExcelRequest,
  ABITHistoricalDataRequest,
  ABITHistoricalDataResponse,
  HistoryResponse,
  ProgramItem,
  SectorItem,
} from './entities/history.entities';
import { DEVICES } from '../devices/devices.interface';

@Injectable('historyFacade')
export class HistoryFacade {
  @Inject('httpClient') private httpClient: HttpClient;
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;

  private historyResponse = new VeggaResponse<HistoryResponse>();

  private extraFilterFormDataResponse = new VeggaResponse();

  get loader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get history$(): Observable<HistoryResponse> {
    return this.historyResponse.value$;
  }

  get history(): HistoryResponse {
    return this.historyResponse.value;
  }

  get isLoading$(): Observable<boolean> {
    return this.loader.isLoading$;
  }

  get sensorList$(): Observable<HistoryResponse[]> {
    return this.extraFilterFormDataResponse.value$ as Observable<HistoryResponse[]>;
  }

  private a2500HistoryApi: A2500HistoryApi;
  private a4500HistoryApi: A4500HistoryApi;
  private a4000HistoryApi: A4000HistoryApi;
  private aBITHistoryApi: ABITHistoryApi;
  private a4000MeterSensorApi: A4000MeterSensorApi;

  constructor() {
    this.a2500HistoryApi = new A2500HistoryApi();
    this.a2500HistoryApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.a4500HistoryApi = new A4500HistoryApi();
    this.a4500HistoryApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.a4000HistoryApi = new A4000HistoryApi();
    this.a4000HistoryApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.aBITHistoryApi = new ABITHistoryApi();
    this.aBITHistoryApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
    this.a4000MeterSensorApi = new A4000MeterSensorApi();
    this.a4000MeterSensorApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  getA2500HistoryExport(params: A2500HistoricalDataExcelRequest, historyType: A2500HistoryTypesEnum) {
    switch (historyType) {
      case A2500HistoryTypesEnum.SECTORS:
        return this.a2500HistoryApi.get2500sectorsHistoricalDataExcel(params as Get2500sectorsHistoricalDataExcelRequest);
      case A2500HistoryTypesEnum.ANALOGS:
        return this.a2500HistoryApi.get2500analogHistoricalDataExcel(params as Get2500analogHistoricalDataExcelRequest);
      case A2500HistoryTypesEnum.COUNTERS:
        return this.a2500HistoryApi.get2500countersHistoricalDataExcel(params as Get2500countersHistoricalDataExcelRequest);
      case A2500HistoryTypesEnum.FLOW:
        return this.a2500HistoryApi.get2500flowHistoricalDataExcel(params as Get2500flowHistoricalDataExcelRequest);
    }
  }

  getA4500HistoryExport(params: A4500HistoricalDataExcelRequest, historyType: A4500HistoryTypesEnum) {
    switch (historyType) {
      case A4500HistoryTypesEnum.SECTORS:
        return this.a4500HistoryApi.get4500sectorsHistoricalDataExcel(params as Get4500sectorsHistoricalDataExcelRequest);
      case A4500HistoryTypesEnum.GENERAL:
        return this.a4500HistoryApi.get4500generalHistoricalDataExcel(params as Get4500generalHistoricalDataExcelRequest);
      case A4500HistoryTypesEnum.ANALOGS:
        return this.a4500HistoryApi.get4500analogHistoricalDataExcel(params as Get4500analogHistoricalDataExcelRequest);
      case A4500HistoryTypesEnum.COUNTERS:
        return this.a4500HistoryApi.get4500counterHistoricalDataExcel(params as Get4500counterHistoricalDataExcelRequest);
      case A4500HistoryTypesEnum.LOGICS:
        return this.a4500HistoryApi.get4500logicHistoricalDataExcel(params as Get4500logicHistoricalDataExcelRequest);
      case A4500HistoryTypesEnum.AVGPHCE:
        return this.a4500HistoryApi.get4500averagePhCeHistoricalDataExcel(params as Get4500averagePhCeHistoricalDataExcelRequest);
      case A4500HistoryTypesEnum.FERTDEP:
        return this.a4500HistoryApi.get4500fertdepositsHistoricalDataExcel(params as Get4500fertdepositsHistoricalDataExcelRequest);
    }
  }

  getA4000HistoryExport(params: A4000HistoricalDataExcelRequest, historyType: A4000HistoryTypesEnum) {
    switch (historyType) {
      case A4000HistoryTypesEnum.PROGRAMS:
        return this.a4000HistoryApi.get4000programsHistoricalDataExcel(params as Get4000programsHistoricalDataExcelRequest);
      case A4000HistoryTypesEnum.FILTERS:
        return this.a4000HistoryApi.get4000filtersHistoricalDataExcel(params as Get4000filtersHistoricalDataExcelRequest);
      case A4000HistoryTypesEnum.ANALOGS:
        return this.a4000HistoryApi.get4000analogHistoricalDataExcel(params as Get4000analogHistoricalDataExcelRequest);
      case A4000HistoryTypesEnum.COUNTERS:
        return this.a4000HistoryApi.get4000counterHistoricalDataExcel(params as Get4000counterHistoricalDataExcelRequest);
      case A4000HistoryTypesEnum.INTEGRATED:
        return this.a4000HistoryApi.get4000integratedHistoricalDataExcel(params as Get4000integratedHistoricalDataExcelRequest);
      case A4000HistoryTypesEnum.SECTORS:
        return this.a4000HistoryApi.get4000sectorsHistoricalDataExcel(params as Get4000sectorsHistoricalDataExcelRequest);
    }
  }

  getABITHistoryExport(params: ABITHistoricalDataExcelRequest, historyType: ABITHistoryTypesEnum) {
    switch (historyType) {
      case ABITHistoryTypesEnum.SECTORS:
        return this.aBITHistoryApi.getBITsectorsHistoricalDataExcel(params as GetBITsectorsHistoricalDataExcelRequest);
      case ABITHistoryTypesEnum.ANALOGS:
        return this.aBITHistoryApi.getBITanalogHistoricalDataExcel(params as GetBITanalogHistoricalDataExcelRequest);
      case ABITHistoryTypesEnum.COUNTERS:
        return this.aBITHistoryApi.getBITcounterHistoricalDataExcel(params as GetBITcounterHistoricalDataExcelRequest);
      case ABITHistoryTypesEnum.FLOW:
        return this.aBITHistoryApi.getBITflowHistoricalDataExcel(params as GetBITflowHistoricalDataExcelRequest);
    }
  }

  loadA2500History(params: A2500HistoricalDataRequest, historyType: A2500HistoryTypesEnum) {
    let req$: Observable<HistoryResponse>;
    switch (historyType) {
      case A2500HistoryTypesEnum.SECTORS:
        req$ = this.a2500HistoryApi.get2500sectorsHistoricalData(params as Get2500sectorsHistoricalDataRequest);
        break;
      case A2500HistoryTypesEnum.ANALOGS:
        req$ = this.a2500HistoryApi.get2500AnalogHistoricalData(params as Get2500AnalogHistoricalDataRequest);
        break;
      case A2500HistoryTypesEnum.COUNTERS:
        req$ = this.a2500HistoryApi.get2500countersHistoricalData(params as Get2500countersHistoricalDataRequest);
        break;
      case A2500HistoryTypesEnum.FLOW:
        req$ = this.a2500HistoryApi.get2500flowHistoricalData(params as Get2500flowHistoricalDataRequest);
        break;
    }
    const subscription = handleResponse(req$, this.historyResponse).subscribe({
      next: (history: A2500HistoryDataResponse) => {
        this.historyResponse.set(history);
      },
      error: err => {
        this.historyResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadA4000History(params: A4000HistoricalDataRequest, historyType: A4000HistoryTypesEnum) {
    let req$: Observable<HistoryResponse>;
    switch (historyType) {
      case A4000HistoryTypesEnum.PROGRAMS:
        req$ = this.a4000HistoryApi.get4000ProgramHistoricalData(params as Get4000ProgramHistoricalDataRequest);
        break;
      case A4000HistoryTypesEnum.FILTERS:
        req$ = this.a4000HistoryApi.get4000FilterHistoricalData(params as Get4000FilterHistoricalDataRequest);
        break;
      case A4000HistoryTypesEnum.ANALOGS:
        req$ = this.a4000HistoryApi.get4000AnalogHistoricalData(params as Get4000AnalogHistoricalDataRequest);
        break;
      case A4000HistoryTypesEnum.COUNTERS:
        req$ = this.a4000HistoryApi.get4000CounterHistoricalData(params as Get4000CounterHistoricalDataRequest);
        break;
      case A4000HistoryTypesEnum.INTEGRATED:
        req$ = this.a4000HistoryApi.get4000IntegratedsHistoricalData(params as Get4000IntegratedsHistoricalDataRequest);
        break;
      case A4000HistoryTypesEnum.SECTORS:
        req$ = this.a4000HistoryApi.get4000sectorsHistoricalData(params as Get4000sectorsHistoricalDataRequest);
        break;
    }
    const subscription = handleResponse(req$, this.historyResponse).subscribe({
      next: (history: A4000HistoricalDataResponse) => {
        this.historyResponse.set(history);
      },
      error: err => {
        this.historyResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadA4500History(params: A4500HistoricalDataRequest, historyType: A4500HistoryTypesEnum) {
    let req$: Observable<HistoryResponse>;

    switch (historyType) {
      case A4500HistoryTypesEnum.GENERAL:
        req$ = this.a4500HistoryApi.get4500generalHistoricalData(params as Get4500generalHistoricalDataRequest);
        break;
      case A4500HistoryTypesEnum.SECTORS:
        req$ = this.a4500HistoryApi.get4500sectorsHistoricalData(params as Get4500sectorsHistoricalDataRequest);
        break;
      case A4500HistoryTypesEnum.ANALOGS:
        req$ = this.a4500HistoryApi.get4500AnalogHistoricalData(params as Get4500AnalogHistoricalDataRequest);
        break;
      case A4500HistoryTypesEnum.COUNTERS:
        req$ = this.a4500HistoryApi.get4500countersHistoricalData(params as Get4500countersHistoricalDataRequest);
        break;
      case A4500HistoryTypesEnum.LOGICS:
        req$ = this.a4500HistoryApi.get4500logicHistoricalData(params as Get4500logicHistoricalDataRequest);
        break;
      case A4500HistoryTypesEnum.AVGPHCE:
        req$ = this.a4500HistoryApi.get4500averagePhCeHistoricalData(params as Get4500averagePhCeHistoricalDataRequest);
        break;
      case A4500HistoryTypesEnum.FERTDEP:
        req$ = this.a4500HistoryApi.get4500fertdepositsHistoricalData(params as Get4500fertdepositsHistoricalDataRequest);
        break;
    }

    const subscription = handleResponse(req$, this.historyResponse).subscribe({
      next: (history: A4500HistoryDataResponse) => {
        this.historyResponse.set(history);
      },
      error: err => {
        this.historyResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadABITHistory(params: ABITHistoricalDataRequest, historyType: ABITHistoryTypesEnum) {
    let req$: Observable<HistoryResponse>;

    switch (historyType) {
      case ABITHistoryTypesEnum.SECTORS:
        req$ = this.aBITHistoryApi.getBITsectorsHistoricalData(params as GetBITsectorsHistoricalDataRequest);
        break;
      case ABITHistoryTypesEnum.ANALOGS:
        req$ = this.aBITHistoryApi.getBITAnalogHistoricalData(params as GetBITAnalogHistoricalDataRequest);
        break;
      case ABITHistoryTypesEnum.COUNTERS:
        req$ = this.aBITHistoryApi.getBITcountersHistoricalData(params as GetBITcountersHistoricalDataRequest);
        break;
      case ABITHistoryTypesEnum.FLOW:
        req$ = this.aBITHistoryApi.getBITflowHistoricalData(params as GetBITflowHistoricalDataRequest);
        break;
    }

    const subscription = handleResponse(req$, this.historyResponse).subscribe({
      next: (history: ABITHistoricalDataResponse) => {
        this.historyResponse.set(history);
      },
      error: err => {
        this.historyResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  loadSectorList(deviceId: string) {
    const req$ = this.httpClient.get<SectorItem[]>(`${environment.AGRONICWEB_API_URL}/api/v1/units/${deviceId}/sectors`, {});

    const subscription = handleResponse(req$, this.extraFilterFormDataResponse)
      .pipe(
        map((sectors: SectorItem[]) => {
          return sectors.map((sector: SectorItem) => ({
            name: sector.name,
            id: sector.pk.id,
          }));
        }),
      )
      .subscribe({
        next: sectorsList => {
          this.extraFilterFormDataResponse.set(sectorsList);
        },
        error: err => {
          this.extraFilterFormDataResponse.setError(err, {});
        },
      });

    this.loader.waitFor(subscription);
  }

  loadProgramsList(deviceId: string, deviceType: number) {
    let req$: Observable<ProgramItem[]>;

    switch (deviceType) {
      case DEVICES.A_4000:
        req$ = this.httpClient.get<ProgramItem[]>(`${environment.API_IRRIGATION_CONTROL_ENDPOINT}/devices/A4000/${deviceId}/programs`, {});
        break;

      default:
        break;
    }

    const subscription = handleResponse(req$, this.extraFilterFormDataResponse)
      .pipe(
        map((programs: ProgramItem[]) => {
          return programs.map((program: ProgramItem) => ({
            name: program.name,
            id: program.pk.id,
          }));
        }),
      )
      .subscribe({
        next: programsList => {
          this.extraFilterFormDataResponse.set(programsList);
        },
        error: err => {
          this.extraFilterFormDataResponse.setError(err, {});
        },
      });

    this.loader.waitFor(subscription);
  }

  loadSensorsList(deviceId: string, type: SensorTypesEnum) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const req$ = this.httpClient.get<any>(
      `${environment.AGRONICWEB_API_URL}/api/v1/units/${deviceId}/${type}?${type === 'analogs' || type === 'logics' ? 'active=true' : 'operative=true'}`,
      {},
    );

    const subscription = handleResponse(req$, this.extraFilterFormDataResponse)
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((sensors: any) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          sensors.map((sensor: any) => ({
            name: sensor.name,
            id: sensor.pk.id,
          })),
        ),
      )
      .subscribe({
        next: sensorList => {
          this.extraFilterFormDataResponse.set(sensorList);
        },
        error: err => {
          this.extraFilterFormDataResponse.setError(err, {});
        },
      });

    this.loader.waitFor(subscription);
  }

  loadMetersList4000(params: GetMeterListA4000Request) {
    const req$ = this.a4000MeterSensorApi.getMeterListA4000(params);

    const subscription = handleResponse(req$, this.extraFilterFormDataResponse)
      .pipe(
        map((sensors: A4000MeterSensorItem[]) =>
          sensors.map((sensor: A4000MeterSensorItem) => ({
            name: sensor.name,
            id: sensor.id,
          })),
        ),
      )
      .subscribe({
        next: sensorList => {
          this.extraFilterFormDataResponse.set(sensorList);
        },
        error: err => {
          this.extraFilterFormDataResponse.setError(err, {});
        },
      });

    this.loader.waitFor(subscription);
  }

  reset(): void {
    this.historyResponse.clear();
  }
}
