import { Injectable } from '../di';

export type ErrorAccessData = {
  code: string;
  status: string;
  redirectUrls: RedirectUrls;
};

export type RedirectUrls = {
  label: string;
  url: string;
};

@Injectable('errorAccessService')
export class ErrorAccessService {
  private canAcces = false;
  /**
   * Contains data about current error
   */
  private errorAccessData: ErrorAccessData;

  canAccessErrorView(): boolean {
    return this.canAcces;
  }

  enableErrorViewAccess(): void {
    this.canAcces = true;
  }

  disableErrorViewAccess(): void {
    this.canAcces = false;
  }

  setErrorAccessData(errorAccessData: ErrorAccessData): void {
    this.errorAccessData = errorAccessData;
  }

  getErrorAccessData(): ErrorAccessData {
    return this.errorAccessData;
  }

  cleanErrorAccessData(): void {
    this.errorAccessData = undefined;
  }
}
