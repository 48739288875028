//@ts-ignore: In-browser module import
import { getToken, showLoader, hideLoader } from '@vegga/front-utils';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpInterceptor } from '../http';
import { HttpHandler, HttpMethod } from '../http';
import { HttpRequest } from '../http/request';
import { navigateToUrl } from 'single-spa';

export class AuthInterceptor implements HttpInterceptor {
  countRequest!: number;
  excludePathItems!: boolean;

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.countRequest && req.method !== HttpMethod.GET) {
      showLoader();
    }

    this.excludePathItems =
      req.url.includes('/core-service/usernames') ||
      req.url.includes('/core-service/register') ||
      req.url.includes('/core-service/users/resetPassword') ||
      /(?=.*\/core-service\/migrations\/users)(?!.*\/subusers)(.+)/.test(req.url);

    const SECURED_APIS = [
      '/agronic/api/v1/',
      '/agronic-service/api/v1/',
      '/agronic-service-devices/api/v1/',
      '/core-service/',
      '/irrigation-control/',
      '/irrigation-control-service/',
      '/irrigation-control-service-features/',
      '/irrigation-control-service-poc/',
      '/irrigation-control-service-devices/',
      '/help/',
      '/geodata/',
    ];
    const isSecuredApi = SECURED_APIS.some(secApi => req.url.includes(secApi));

    return from(isSecuredApi && !this.excludePathItems ? getToken() : of({})).pipe(
      switchMap(token => {
        if (token) {
          req.headers = { ...req.headers, authorization: `Bearer ${token}` };
        }

        this.countRequest++;
        return next.handle(req).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401 && !/\/core-service\/migrations\/users$/g.test(req.url)) {
              navigateToUrl('/authentication/sign-in');
            }
            return throwError(() => err);
          }),
          finalize(() => {
            this.countRequest--;
            if (!this.countRequest) {
              hideLoader();
            }
          }),
        );
      }),
    ) as Observable<HttpEvent<unknown>>;
  }
}
