import { DeviceApi, DeviceNameResponse, UpdateDeviceNameRequest } from '@vegga-api-clients/irrigation-control-service';
import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpClient, HttpErrorResponse, RequestParams } from '../../http';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { VeggaFacade } from '../vegga-facade';
import { DEVICES, LegacyDevice, LegacyDeviceById, LegacyDeviceByList, ConditionersDevice, LEGACY_DEVICES_MAP } from './devices.interface';

@Injectable('devicesFacade')
export class DevicesFacade {
  @Inject('httpClient') private httpClient: HttpClient;
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  private devicesICMResponse = new VeggaResponse<LegacyDevice[], HttpErrorResponse>();
  private deviceICMResponse = new VeggaResponse<LegacyDeviceById, HttpErrorResponse>();
  private legacyUnitResponse = new VeggaResponse<LegacyDevice>();
  private currentDeviceSubject = new BehaviorSubject<unknown>(null);
  private isDeviceSyncActive = new BehaviorSubject(false);
  private devicesApi: DeviceApi;
  private devicesConditioners = new VeggaResponse();

  private loader = new VeggaLoader();

  constructor() {
    this.devicesApi = new DeviceApi();
    this.devicesApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get globalLoader(): VeggaLoader {
    return this.veggaFacade.loader;
  }

  get isLoading$() {
    return this.loader.isLoading$;
  }

  get conditionersList$(): Observable<ConditionersDevice[]> {
    return this.devicesConditioners.value$ as Observable<ConditionersDevice[]>;
  }

  /**
   * Legacy units/devices response, fetched from old agronic api
   */
  get devicesICM$(): Observable<LegacyDeviceByList[]> {
    return this.devicesICMResponse.value$;
  }
  /**
   * Legacy units/devices synchronous value
   */
  get devicesICM(): LegacyDeviceByList[] {
    return this.devicesICMResponse.value;
  }
  /**
   * Legacy units/devices error, fetched from old agronic api
   */
  get devicesICMError$(): Observable<HttpErrorResponse> {
    return this.devicesICMResponse.error$;
  }
  /**
   * Legacy unit/device response, fetched from old agronic api
   */
  get deviceICM$(): Observable<LegacyDeviceById> {
    return this.deviceICMResponse.value$;
  }
  /**
   * Legacy units/device error, fetched from old agronic api
   */
  get deviceICMError$(): Observable<HttpErrorResponse> {
    return this.deviceICMResponse.error$;
  }

  get currentDevice(): unknown {
    return this.currentDeviceSubject.getValue();
  }

  get currentDevice$(): unknown {
    return this.currentDeviceSubject.asObservable().pipe(
      filter(device => !!device),
      map((device: LegacyDeviceById) => {
        if (device.type === DEVICES.A_2500) {
          return { ...device, inoptions: device.inoptions[0] };
        }
        return device;
      }),
    );
  }

  set currentDevice(device: unknown) {
    this.currentDeviceSubject.next(device);
  }

  get currentDeviceSyncStatus$(): Observable<boolean> {
    return this.isDeviceSyncActive.asObservable();
  }

  set currentDeviceSyncStatus(isActive: boolean) {
    this.isDeviceSyncActive.next(isActive);
  }

  get legacyUnit$(): Observable<LegacyDevice> {
    return this.legacyUnitResponse.value$;
  }

  /**
   * Legacy method that points to old agronic units/devices endpoint
   * as BE is still in development.
   * TODO: Implement method with new device/unit endpoints
   */
  loadICMDevices(userId: string) {
    const req$ = this.httpClient.get<LegacyDevice[]>(`${environment.AGRONICWEB_API_URL}/api/v1/users/${userId}/units`, {});

    const subscription = handleResponse(req$, this.devicesICMResponse).subscribe({
      next: devices => {
        const parsedDevices = this.parseUnits(devices).map((unit: LegacyDevice) => ({ ...unit.device, lvl: unit.level }));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.devicesICMResponse.set(parsedDevices as any);
      },
      error: err => {
        this.devicesICMResponse.setError(err, {});
      },
    });

    this.globalLoader.waitFor(subscription);
    this.loader.waitFor(subscription);
  }

  loadConditionersList(userId: string) {
    const req$ = this.httpClient.get<ConditionersDevice[]>(`${environment.AGRONICWEB_API_URL}/api/v1/units/${userId}/conditioners`, {});

    const subscription = handleResponse(req$, this.devicesConditioners)
      .pipe(
        map((conditioners: ConditionersDevice[]) => {
          return conditioners.map((condition: ConditionersDevice) => ({
            name: condition.name,
            id: condition.id,
            from: condition.from,
            function: condition.function,
          }));
        }),
      )
      .subscribe({
        next: conditioners => {
          this.devicesConditioners.set(conditioners);
        },
        error: err => {
          this.devicesConditioners.setError(err, {});
        },
      });
    this.globalLoader.waitFor(subscription);
    this.loader.waitFor(subscription);
  }

  /**
   * Legacy method that points to old agronic units/devices endpoint
   * as BE is still in development.
   * TODO: Implement method with new device/unit endpoints
   */
  loadICMDeviceById(deviceId: string, params: RequestParams = {}) {
    const req$ = this.httpClient.get<LegacyDeviceById>(`${environment.AGRONICWEB_API_URL}/api/v1/units/${deviceId}`, { query: params });

    const subscription = req$.subscribe({
      next: (device: LegacyDeviceById) => {
        this.deviceICMResponse.set(device);

        if (this.devicesICMResponse.value) {
          const listDevice = this.devicesICMResponse.value.find(device => device.id === +deviceId);
          const parsedDevice = { ...device, inoptions: Array.isArray(device.inoptions) ? device.inoptions[0] : device.inoptions };
          this.legacyUnitResponse.set({ ...parsedDevice, ...listDevice, name: parsedDevice.name });
        }
      },
      error: err => {
        this.deviceICMResponse.setError(err, {});
      },
    });

    this.globalLoader.waitFor(subscription);
    this.loader.waitFor(subscription);
  }

  /**
   * Updates selected device name
   * @param req Object containing id, type and new name
   */
  updateDeviceName(req: UpdateDeviceNameRequest): Observable<DeviceNameResponse> {
    req.deviceType = LEGACY_DEVICES_MAP[req.deviceType];
    return this.devicesApi.updateDeviceName(req).pipe(
      tap(res => {
        this.legacyUnitResponse.set({ ...this.legacyUnitResponse.value, name: res.deviceName });
        const updatedItemIndex = this.devicesICMResponse.value.findIndex((device: LegacyDevice) => device.id === req.deviceId);
        const devices = [...this.devicesICMResponse.value];
        devices[updatedItemIndex] = { ...devices[updatedItemIndex], name: res.deviceName };
        this.devicesICMResponse.set([...devices]);
      }),
    );
  }

  /**
   * Legacy method that executes business logic to extract
   * device/unit status
   */
  private checkUnitStatus(obj) {
    if (obj.ram !== undefined) {
      if (obj.connect) {
        if (obj.ram.systemStopMalfunction) {
          obj.status = 'systemStop';
        } /*"Paro Sistema"*/ else if (obj.ram.outService) {
          obj.status = 'outService';
        } /*"Fuera de servicio"*/ else if (obj.ram.generalMalfunction) {
          obj.status = 'generalMalfunction';
        } /*"Avería General"*/ else if (obj.ram.flowMalfunction) {
          obj.status = 'flowMalfunction';
        } /*"Avería Caudal"*/ else if (obj.ram.counterMalfunction) {
          obj.status = 'counterMalfunction';
        } /*"Avería Contador"*/ else if (obj.ram.ferlitzerMalfunction) {
          obj.status = 'ferlitzerMalfunction';
        } /*"Avería Fertilizante sin control"*/ else if (obj.ram.filterMalfunction) {
          obj.status = 'filterMalfunction';
        } /*"Avería Filtros sin control"*/ else if (obj.ram.phMalfunction) {
          obj.status = 'phMalfunction';
        } /*"Avería regulación PH"*/ else if (obj.ram.ceMalfunction) {
          obj.status = 'ceMalfunction';
        } /*"Avería control conductividad"*/ else if (obj.ram.definitiveStopMalfunction) {
          obj.status = 'definitiveStopMalfunction';
        } /*"Paro Definitivo"*/ else {
          obj.status = 'ok';
        }
      } else {
        obj.status = 'notconnected';
      }
    } else {
      if (obj.connect) {
        if (obj.systemStopMalfunction) {
          obj.status = 'systemStop';
        } /*"Paro Sistema"*/ else if (obj.outService) {
          obj.status = 'outService';
        } /*"Fuera de servicio"*/ else if (obj.generalMalfunction) {
          obj.status = 'generalMalfunction';
        } /*"Avería General"*/ else if (obj.flowMalfunction) {
          obj.status = 'flowMalfunction';
        } /*"Avería Caudal"*/ else if (obj.counterMalfunction) {
          obj.status = 'counterMalfunction';
        } /*"Avería Contador"*/ else if (obj.ferlitzerMalfunction) {
          obj.status = 'ferlitzerMalfunction';
        } /*"Avería Fertilizante sin control"*/ else if (obj.filterMalfunction) {
          obj.status = 'filterMalfunction';
        } /*"Avería Filtros sin control"*/ else if (obj.phMalfunction) {
          obj.status = 'phMalfunction';
        } /*"Avería regulación PH"*/ else if (obj.ceMalfunction) {
          obj.status = 'ceMalfunction';
        } /*"Avería control conductividad"*/ else if (obj.definitiveStopMalfunction) {
          obj.status = 'definitiveStopMalfunction';
        } /*"Paro Definitivo"*/ else {
          obj.status = 'ok';
        }
      } else {
        obj.status = 'notconnected';
      }
    }

    // checkStatusImg(obj);
  }

  private parseUnits(units: LegacyDeviceByList[]) {
    units.forEach(unit => {
      this.checkUnitStatus(unit.device);
      // this.checkNotifications(unit.device);
      unit.device.lvl = +unit.level;
    });
    // showNotification(notifications[0]);
    return units;
  }

  clearDeviceICMResponse(): void {
    this.deviceICMResponse.clear();
  }

  clearDeviceICMError(): void {
    this.deviceICMResponse.clearError();
  }
}
