import { Channel, CreateChannelFormConfig, IrrideskSubscription } from './types';

export const parseSubscriptionResponse = (subscriptionResponse: any): IrrideskSubscription => {
  const irrideskChannels = subscriptionResponse.channels.map((ch: any) => ({
    ...subscriptionResponse.irrideskInfo.farms[0].channels.find((channel: any) => ch.irrideskId === channel.id),
    pk: ch.pk,
    name: ch.name,
    status: ch.status,
    type: ch.type,
    userId: ch.userId,
    irrideskId: ch.irrideskId,
    maxSensors: ch.maxSensors,
    wrapper: ch.wrapper,
    device: { deviceId: ch.deviceId, deviceIdIrridesk: ch.deviceIdIrridesk },
  }));

  return {
    id: subscriptionResponse.id,
    userId: subscriptionResponse.userId,
    maxSensors: subscriptionResponse.maxSensors,
    irrideskId: subscriptionResponse.irrideskId,
    farmName: subscriptionResponse.irrideskInfo.farms[0].name,
    channels: parseChannels(irrideskChannels),
  };
};

const parseChannels = (irrideskChannels: any[]): Channel[] => {
  return irrideskChannels.map((ch): any => ({
    device: ch.device,
    pk: ch.pk,
    name: ch.name,
    status: ch.status,
    type: ch.type,
    active: ch.active,
    irrideskId: ch.irrideskId,
    accepted: ch.seasonalPlan_id?.accepted,
    wrapper: ch.wrapper,
    programs: ch.wrapper?.map((wr: any) => ({
      name: wr.name,
      idInDevice: wr.idInDevice,
      multiplier_mm: wr.multiplier_mm,
      min: wr.min,
      max: wr.max,
      active: wr.active,
      automata: wr.automata,
      id: wr.id,
    })),
    seasonal_config: {
      seasonalConfig_id: {
        id: ch.seasonalConfig_id?.id,
        year: ch.seasonalConfig_id?.year,
        strategy: ch.seasonalConfig_id?.strategy,
        constrain_irrigation: ch.seasonalConfig_id?.constrain_irrigation,
        constrain_comfort: ch.seasonalConfig_id?.constrain_comfort,
        use_multiplier: ch.seasonalConfig_id?.use_multiplier,
        max_accum_irrigation: ch.seasonalConfig_id?.max_accum_irrigation,
        min_accum_irrigation: ch.seasonalConfig_id?.min_accum_irrigation,
        target_max_vigor: ch.seasonalConfig_id?.target_max_vigor,
        min_vigor_to_obey: ch.seasonalConfig_id?.min_vigor_to_obey,
      },
      crop_id: {
        id: ch.crop_id?.id,
        name: ch.crop_id?.name,
        potRootDepth: ch.crop_id?.potRootDepth,
        distanceInRow: ch.crop_id?.distanceInRow,
        distBtwRows: ch.crop_id?.distBtwRows,
        rowAngle: ch.crop_id?.rowAngle,
        treeHeight: ch.crop_id?.treeHeight,
        treeWidth: ch.crop_id?.treeWidth,
        treeDepth: ch.crop_id?.treeDepth,
        growingTrees: ch.crop_id?.growingTrees,
        cropLabel: ch.crop_id?.cropReferences_id?.label,
      },
      soil_id: {
        id: ch.soil_id?.id,
        name: ch.soil_id?.name,
        depth: ch.soil_id?.depth,
        slope: ch.soil_id?.slope,
        variability: ch.soil_id?.variability,
        compactness: ch.soil_id?.compactness,
        waterlogging: ch.soil_id?.waterlogging,
        salinity: ch.soil_id?.salinity,
        swc_fc: ch.soil_id?.swc_fc,
        swc_wp: ch.soil_id?.swc_wp,
        soilLabel: ch.soil_id?.texturalClass_id?.label,
      },
      irrsys_id: {
        id: ch.irrsys_id?.id,
        name: ch.irrsys_id?.name,
        uniformityCoef: ch.irrsys_id?.uniformityCoef,
        distBtwEmitters: ch.irrsys_id?.distBtwEmitters,
        pipesPerTreeLine: ch.irrsys_id?.pipesPerTreeLine,
        emitterRate: ch.irrsys_id?.emitterRate,
        wetDiameter: ch.irrsys_id?.wetDiameter,
        distBtwPipes: ch.irrsys_id?.distBtwPipes,
        type: ch.irrsys_id?.instance_class,
      },
      seasonalPlan_id: ch.seasonalPlan_id,
    },
  }));
};

export const getCropsOptions = (data: any) => {
  const cropGroups = data.crop_option.choices.map((opt: any) => {
    return { value: opt[0], label: opt[1] };
  });

  const cropClasses = cropGroups
    .map((group: any) =>
      Object.entries(data.ref_crop.restrictions[group.value].options).map(opt => {
        return { type: group.value, value: opt[0], label: (opt[1] as any).label, options: (opt[1] as any).options };
      }),
    )
    .flat();

  const crops = cropClasses
    .map((cropClass: any) => {
      const [_, type, __, options] = Object.values(cropClass);
      return (options as any[]).map((opt: any) => ({ type, value: opt.code, label: opt.label }));
    })
    .flat();

  return {
    cropGroups,
    cropClasses,
    crops,
  };
};

export const parseForm = (form: any): CreateChannelFormConfig => {
  const channel_outlines = form.channel_outlines;

  channel_outlines.irri_option.choices = channel_outlines.irri_option.choices.map(opt => ({ value: opt[0], label: opt[1] }));
  channel_outlines.ref_soil.choices = channel_outlines.ref_soil.choices.map(opt => ({ value: opt[0], label: opt[1] }));
  channel_outlines.soil_option.choices = channel_outlines.soil_option.choices.map(opt => ({ value: opt[0], label: opt[1] }));

  return { ...form, channel_outlines };
};
