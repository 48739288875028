import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpClient } from '../../http';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';

@Injectable('cropsFacade')
export class CropsFacade {
  private cropsResponse = new VeggaResponse();
  private cropsI18nResponse = new VeggaResponse();
  private allCropsI18nResponse = new VeggaResponse();
  private agronicAPIBasePath = environment.AGRONICWEB_API_URL;
  @Inject('httpClient') private httpClient: HttpClient;

  constructor() {}

  get crops(): Observable<unknown> {
    return this.cropsResponse.value$;
  }

  get cropsI18n$(): Observable<unknown> {
    return this.cropsI18nResponse.value$;
  }

  get allCropsI18n$(): Observable<unknown> {
    return this.allCropsI18nResponse.value$;
  }

  getCrops() {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/units/crops/all`, {});
    handleResponse(req$, this.cropsResponse).subscribe(crops => {
      this.cropsResponse.set(crops);
    });
  }

  getCropsI18n(): void {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/units/crops/all`, {});

    req$.subscribe({
      next: cropsI18n => {
        this.cropsI18nResponse.set(cropsI18n);
      },
      error: err => {
        this.cropsI18nResponse.setError(err, {});
      },
    });
  }

  getAllCropsI18n(): void {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/units/crop/i18n`, {});

    req$.subscribe({
      next: cropsI18n => {
        this.allCropsI18nResponse.set(cropsI18n);
      },
      error: err => {
        this.allCropsI18nResponse.setError(err, {});
      },
    });
  }
}
