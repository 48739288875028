import { Injectable } from '@di';
import { environment } from '@env';
import { VeggaLoader, VeggaResponse } from '@http';
import {
  IrrigationCommunitiesApi,
  IrrigationCommunityDeviceItem,
  IrrigationCommunityHydrantItemResponse,
  IrrigationCommunityHydrantRequest,
  IrrigationCommunityHydrantResponse,
} from '@vegga-api-clients/irrigation-control-service';
import { Observable, tap } from 'rxjs';

@Injectable('irrigationCommunityHydrantsFacade')
export class IrrigationCommunitiesHydrantsFacade {
  private hydrantsResponse = new VeggaResponse<IrrigationCommunityHydrantItemResponse[]>();
  private hydrantByIdResponse = new VeggaResponse<IrrigationCommunityHydrantResponse>();
  private hydrantPossibleAssociatedDevicesResponse = new VeggaResponse<IrrigationCommunityDeviceItem[]>();
  private detailLoader = new VeggaLoader();
  private listLoader = new VeggaLoader();
  private irrigationCommunitiesApi: IrrigationCommunitiesApi;

  constructor() {
    this.irrigationCommunitiesApi = new IrrigationCommunitiesApi();
    this.irrigationCommunitiesApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get hydrants$(): Observable<IrrigationCommunityHydrantItemResponse[]> {
    return this.hydrantsResponse.value$;
  }

  get hydrants(): IrrigationCommunityHydrantItemResponse[] {
    return this.hydrantsResponse.value;
  }

  get hydrantById$(): Observable<IrrigationCommunityHydrantResponse> {
    return this.hydrantByIdResponse.value$;
  }

  get hydrantPossibleAssociatedDevices$(): Observable<IrrigationCommunityDeviceItem[]> {
    return this.hydrantPossibleAssociatedDevicesResponse.value$;
  }

  get isDetailLoading$(): Observable<boolean> {
    return this.detailLoader.isLoading2$;
  }

  get isListLoading$(): Observable<boolean> {
    return this.listLoader.isLoading2$;
  }

  loadHydrants() {
    const req$ = this.irrigationCommunitiesApi.listIrrigationCommunityHydrants();
    const subscription = req$.subscribe({
      next: hydrants => {
        // const parsedHydrants = hydrants.map((network) => ({
        //   ...network,
        //   hydrantsAmount: network.hydrants.length,
        //   accumulatedMaxFlow: network.timeSlots.reduce((maxFlow, timeSlot) => maxFlow + timeSlot.flow.maxNetworkFlow, 0),
        //   irrigatingHydrants: network.hydrants.filter(hydrant => hydrant.status === 'IRRIGATING').length
        // }))
        this.hydrantsResponse.set(hydrants);
      },
      error: err => {
        this.hydrantsResponse.setError(err, {});
      },
    });

    this.listLoader.waitFor(subscription);
  }

  loadHydrantById(id: number) {
    const req$ = this.irrigationCommunitiesApi.getIrrigationCommunityHydrantById({ id });
    const subscription = req$.subscribe({
      next: networkData => {
        this.hydrantByIdResponse.set(networkData);
      },
      error: err => {
        this.hydrantByIdResponse.setError(err, {});
      },
    });

    this.detailLoader.waitFor(subscription);
  }

  loadPossibleAssociatedDevices() {
    const req$ = this.irrigationCommunitiesApi.listIrrigationCommunityDevices();
    const subscription = req$.subscribe({
      next: devices => {
        this.hydrantPossibleAssociatedDevicesResponse.set(devices);
      },
      error: err => {
        this.hydrantPossibleAssociatedDevicesResponse.setError(err, {});
      },
    });

    this.detailLoader.waitFor(subscription);
  }

  postHydrant(irrigationCommunityHydrantRequest: IrrigationCommunityHydrantRequest): Observable<IrrigationCommunityHydrantResponse> {
    return this.irrigationCommunitiesApi
      .postIrrigationCommunityHydrants({ irrigationCommunityHydrantRequest })
      .pipe(tap(hydrant => this.hydrantsResponse.set([...this.hydrants, hydrant as unknown])));
  }

  deleteHydrant(id: number): Observable<void> {
    return this.irrigationCommunitiesApi.deleteIrrigationCommunityHydrant({ id }).pipe(tap(() => this.hydrantsResponse.set(this.hydrants.filter(hydrant => hydrant.id !== id))));
  }

  clearResponses(): void {
    this.hydrantsResponse.clear();
    this.hydrantByIdResponse.clear();
    this.hydrantPossibleAssociatedDevicesResponse.clear();
  }
}
