import { Inject, Injectable } from '@di';
import { environment } from '@env';
import { VeggaLoader, VeggaResponse } from '@http';
import {
  IrrigationCommunitiesApi,
  IrrigationCommunityHydrantCounterItem,
  IrrigationCommunityParcelItem,
  IrrigationCommunityParcelRequest,
  IrrigationCommunityParcelResponse,
} from '@vegga-api-clients/irrigation-control-service';
import { filter, map, Observable, switchMap, take, tap } from 'rxjs';
import { IrrigationCommunitiesIrrigatorsFacade } from '../irrigators/irrigation-community-irrigators.facade';

@Injectable('irrigationCommunityParcelsFacade')
export class IrrigationCommunitiesParcelsFacade {
  @Inject('irrigationCommunityIrrigatorsFacade') irrigatorsFacade: IrrigationCommunitiesIrrigatorsFacade;
  private parcelsResponse = new VeggaResponse<IrrigationCommunityParcelItem[]>();
  private parcelByIdResponse = new VeggaResponse<IrrigationCommunityParcelResponse>();
  private countersByHydrantIdResponse = new VeggaResponse<IrrigationCommunityHydrantCounterItem[]>();
  private detailLoader = new VeggaLoader();
  private listLoader = new VeggaLoader();
  private countersLoader = new VeggaLoader();
  private irrigationCommunitiesApi: IrrigationCommunitiesApi;

  constructor() {
    this.irrigationCommunitiesApi = new IrrigationCommunitiesApi();
    this.irrigationCommunitiesApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get parcels$(): Observable<IrrigationCommunityParcelItem[]> {
    return this.parcelsResponse.value$;
  }

  get parcels(): IrrigationCommunityParcelItem[] {
    return this.parcelsResponse.value;
  }

  get parcelById$(): Observable<IrrigationCommunityParcelResponse> {
    return this.parcelByIdResponse.value$;
  }

  get countersByHydrantId$(): Observable<IrrigationCommunityHydrantCounterItem[]> {
    return this.countersByHydrantIdResponse.value$;
  }

  get isDetailLoading$(): Observable<boolean> {
    return this.detailLoader.isLoading2$;
  }

  get isListLoading$(): Observable<boolean> {
    return this.listLoader.isLoading2$;
  }

  get areCountersLoading$(): Observable<boolean> {
    return this.countersLoader.isLoading$;
  }

  /**
   * Loads irrigation community parcels.
   * Since backend only informs about irrigationId, this method
   * also searches and assigns irrigator object from IrrigatorsFacade irrigatorsResponse.
   */
  loadParcels() {
    const req$ = this.irrigationCommunitiesApi.listIrrigationCommunityParcels();
    const subscription = this.irrigatorsFacade.irrigators$
      .pipe(
        take(1),
        switchMap(irrigators =>
          req$.pipe(
            map(parcels =>
              parcels.map(parcel => {
                const irrigatorData = irrigators.find(irrigator => irrigator.id === parcel.irrigatorId);
                return { ...parcel, irrigatorName: irrigatorData && `${irrigatorData.name} ${irrigatorData.surname}` };
              }),
            ),
          ),
        ),
      )
      .subscribe({
        next: parcels => {
          this.parcelsResponse.set(parcels);
        },
        error: err => {
          this.parcelsResponse.setError(err, {});
        },
      });

    this.listLoader.waitFor(subscription);
  }

  loadParcelById(id: number) {
    const req$ = this.irrigationCommunitiesApi.getIrrigationCommunityParcelById({ id });
    const subscription = req$.subscribe({
      next: networkData => {
        this.parcelByIdResponse.set(networkData);
      },
      error: err => {
        this.parcelByIdResponse.setError(err, {});
      },
    });

    this.detailLoader.waitFor(subscription);
  }

  loadCountersByHydrantId(id: number) {
    const req$ = this.irrigationCommunitiesApi.getIrrigationCommunityHydrantCounterById({ id });
    const subscription = req$.subscribe({
      next: counters => {
        this.countersByHydrantIdResponse.set(counters);
      },
      error: err => {
        this.countersByHydrantIdResponse.setError(err, {});
      },
    });

    this.countersLoader.waitFor(subscription);
  }

  postParcel(irrigationCommunityParcelRequest: IrrigationCommunityParcelRequest): Observable<IrrigationCommunityParcelResponse> {
    return this.irrigationCommunitiesApi.postIrrigationCommunityParcels({ irrigationCommunityParcelRequest }).pipe(
      filter(() => !!this.parcels),

      tap(parcel => {
        // const parcelWithIrrigator = {...parcel, irrigator: }
        this.parcelsResponse.set([...this.parcels, parcel]);
      }),
    );
  }

  deleteParcel(id: number): Observable<void> {
    return this.irrigationCommunitiesApi.deleteIrrigationCommunityParcel({ id }).pipe(tap(() => this.parcelsResponse.set(this.parcels.filter(parcel => parcel.id !== id))));
  }

  clearResponses(): void {
    this.parcelsResponse.clear();
    this.parcelByIdResponse.clear();
    this.countersByHydrantIdResponse.clear();
  }

  clearCountersResponse(): void {
    this.countersByHydrantIdResponse.clear();
  }
}
