export const MOCK_WEATHER_CONTROL_POINT_DATA = {
    "id": 3,
    "name": "AgroSens Meteo",
    "model": null,
    "packCode": null,
    "type": "WEATHER",
    "sensorPack": null,
    "sensors": [
        {
            "id": 19,
            "deviceId": 10045,
            "sensorCategory": "WIND_DIRECTION",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0",
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º"
                }
            },
            "progresId": 21,
            "isCalculated": null
        },
        {
            "id": 23,
            "deviceId": 10045,
            "sensorCategory": "ATMOSPHERIC_PRESSURE",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 991,
                    "valueFormatted": "991",
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa"
                }
            },
            "progresId": 25,
            "isCalculated": null
        },
        {
            "id": 26,
            "deviceId": 10045,
            "sensorCategory": "RAIN_GAUGE",
            "sensorType": "METER",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0.0",
                    "unit": "CUBIC_METERS_PER_HOUR",
                    "symbol": null
                }
            },
            "progresId": 3,
            "isCalculated": null
        },
        {
            "id": 20,
            "deviceId": 10045,
            "sensorCategory": "ANEMOMETER",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0",
                    "unit": "KILOMETERS_PER_HOUR",
                    "symbol": "km/h"
                }
            },
            "progresId": 22,
            "isCalculated": null
        },
        {
            "id": 21,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 11.9,
                    "valueFormatted": "11.9",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 23,
            "isCalculated": null
        },
        {
            "id": 24,
            "deviceId": 10045,
            "sensorCategory": "RADIATION",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 203,
                    "valueFormatted": "203",
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2"
                }
            },
            "progresId": 26,
            "isCalculated": null
        },
        {
            "id": 22,
            "deviceId": 10045,
            "sensorCategory": "RELATIVE_HUMIDITY",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 67,
                    "valueFormatted": "67",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 24,
            "isCalculated": null
        },
        {
            "id": 25,
            "deviceId": 10045,
            "sensorCategory": "WET_BULB",
            "sensorType": "ANALOG",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 8.7,
                    "valueFormatted": "8.7",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 27,
            "isCalculated": null
        }
    ],
    "farmId": 1,
    "farmName": "La Serra",
    "geoJson": "{ \"type\": \"Feature\", \"properties\": {}, \"geometry\": { \"coordinates\": [ 0.8706012556538951, 41.61802393073731 ], \"type\": \"Point\" } }",
    "availableAgronomicVariables": [
        "ANTI_FROST_SURVEILLANCE",
        "WET_BULB",
        "DEW_POINT",
        "VAPOR_PRESSURE_DEFICIT",
        "EFFECTIVE_RAIN",
        "ETO"
    ]
}

export const MOCK_WEATHER_DATA = [
    {
        "id": 21,
        "progresId": 23,
        "grouping": "HOUR",
        "sensorCategory": "TEMPERATURE",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 13.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 14.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 16.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 15.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 15.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 14.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 13.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 12.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 12,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 12.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 10.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 10.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 13.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 14.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 17.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 17.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 16.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 15.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 15.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 15.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 13.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 14,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 14.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 12.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 10.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 9.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 13.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 14.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 14.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 13.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 10.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 11.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 9.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 11.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 12.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 13.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 9.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 12.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 12.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 9.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 11.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 12.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 11.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 14.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 15.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 16.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 15.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 15.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 14.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 14.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 12.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 13.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 10.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 12.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 10.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 10.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 10.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 11,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 10,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 9.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 10.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 10.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 9.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 10.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 11.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 13.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 9.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 10.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 10.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 11.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 11.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 9.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 10.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 23,
        "progresId": 25,
        "grouping": "HOUR",
        "sensorCategory": "ATMOSPHERIC_PRESSURE",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1005,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 1005,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 976,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 975,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 977,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 978,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 979,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 980,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 981,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 982,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 983,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 984,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 985,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 987,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 988,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 989,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 990,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 1004,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 1003,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 1002,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 1001,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 1000,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 999,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 998,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 997,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 996,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 995,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 994,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 993,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 992,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 991,
                    "unit": "HECTOPASCAL",
                    "symbol": "hPa",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 20,
        "progresId": 22,
        "grouping": "HOUR",
        "sensorCategory": "ANEMOMETER",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 33,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 33,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 33,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 32,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 37,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 36,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 27,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 42,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 41,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 36,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 46,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 25,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 35,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 24,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 20,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 22,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 16,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 16,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 16,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 16,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 18,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 20,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 20,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 25,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 27,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 22,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 21,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 14,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 15,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 18,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 14,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 17,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 18,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 11,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 14,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 12,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 13,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 8,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 6,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 4,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 7,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 10,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 3,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 9,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 5,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 2,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 1,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "CUSTOM_DEFINED",
                    "symbol": "km/h",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 19,
        "progresId": 21,
        "grouping": "HOUR",
        "sensorCategory": "WIND_DIRECTION",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 189,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 195,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 184,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 56,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 82,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 166,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 160,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 171,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 148,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 166,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 162,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 168,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 164,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 218,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 154,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 205,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 174,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 24,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 73,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 244,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 173,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 238,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 229,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 74,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 97,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 342,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 212,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 212,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 229,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 225,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 259,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 273,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 235,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 273,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 266,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 202,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 200,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 255,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 246,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 226,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 228,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 265,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 221,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 227,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 222,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 235,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 239,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 286,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 240,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 297,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 213,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 196,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 86,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 84,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 90,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 307,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 310,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 193,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 244,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 182,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 227,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 194,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 202,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 229,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 250,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 217,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 228,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 247,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 256,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 221,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 175,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 187,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 189,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 186,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 199,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 196,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 196,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 211,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 193,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 194,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 100,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 130,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 154,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 348,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 163,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 95,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 275,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 256,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 267,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 196,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 198,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 195,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 193,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 191,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 194,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 191,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 351,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 164,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 131,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 97,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 155,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 155,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 164,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 171,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 103,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 344,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 310,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 301,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 271,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 262,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 262,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 230,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 237,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 202,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 185,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 343,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 343,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 167,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 167,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 180,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 149,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 198,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 105,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 173,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 100,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 281,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 153,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 186,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 177,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 310,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 235,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 311,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 289,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 300,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 327,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 326,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 349,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 352,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 120,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 167,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 126,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 83,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 121,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 110,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 144,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 92,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 294,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 95,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 336,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 336,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 338,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 304,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 349,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 347,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 347,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 94,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 94,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 102,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 133,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 114,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 115,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 109,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 140,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 128,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 95,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 28,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 97,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 102,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 316,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 291,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 316,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 275,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 275,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 293,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 292,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 103,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 103,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 114,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 109,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 117,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 124,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 106,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 108,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 119,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 126,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 279,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 319,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 311,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 286,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 265,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 259,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 153,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 106,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 99,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 106,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 102,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 135,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 110,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 99,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 121,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 106,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 115,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 104,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 274,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 322,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 322,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 281,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 256,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 184,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 132,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 114,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 99,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 91,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 119,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 103,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 99,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 117,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 128,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 160,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 166,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 128,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 101,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 90,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 355,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 32,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 8,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 64,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 17,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 17,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 36,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 49,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 126,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 82,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 117,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 215,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 215,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 213,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 169,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 82,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 37,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 27,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 50,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 39,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 119,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 148,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 188,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 207,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 212,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 219,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 127,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 180,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 172,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 172,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 21,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 40,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 20,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 102,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 76,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 83,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 127,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 150,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 134,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 160,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 176,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 112,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 171,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 184,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 199,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 119,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 80,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 37,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 60,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 37,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 74,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 60,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 78,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 141,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 182,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 166,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 181,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 190,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 175,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 82,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 6,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 58,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 31,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 49,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 55,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 120,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 178,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 90,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 6,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 162,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 181,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 177,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 176,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 177,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 132,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 47,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 37,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 25,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 48,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 54,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 72,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 43,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 209,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 249,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 221,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 227,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 129,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 109,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 169,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 131,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 175,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 188,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 16,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 7,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 29,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 53,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 62,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 41,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 31,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 18,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 36,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 78,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 54,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 120,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 104,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 131,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 126,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 134,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 173,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 151,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 56,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 41,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 42,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 72,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 56,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 58,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 47,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 50,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 17,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 27,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 4,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 55,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 7,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 25,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 28,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 22,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 29,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 15,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 132,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 235,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 226,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 207,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 23,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 96,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 44,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 33,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 19,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 79,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 49,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 50,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 44,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 15,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 80,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 45,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 2,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 10,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 50,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 51,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 129,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 44,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 70,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 98,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 224,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 204,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 25,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 19,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 75,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 91,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 88,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 74,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 33,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 58,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 75,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 57,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 81,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 89,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 52,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 84,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 32,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 178,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 271,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 228,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 214,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 159,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 21,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 32,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 57,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 83,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 71,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 59,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 70,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 57,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 44,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 89,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 85,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 62,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 43,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 34,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 272,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 245,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 154,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 29,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 76,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 75,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 30,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 32,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 23,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 87,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 75,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 65,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 46,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 28,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 83,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 77,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 68,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 111,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 3,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 0,
                    "unit": "SEXAGESIMAL_DEGREES",
                    "symbol": "º",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 22,
        "progresId": 24,
        "grouping": "HOUR",
        "sensorCategory": "RELATIVE_HUMIDITY",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 49,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 44,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 44,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 41,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 41,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 48,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 52,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 57,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 57,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 51,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 51,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 54,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 51,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 56,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 48,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 44,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 49,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 52,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 56,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 57,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 57,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 52,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 51,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 46,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 45,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 41,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 46,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 53,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 58,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 54,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 55,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 76,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 56,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 52,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 50,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 53,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 59,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 63,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 66,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 68,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 96,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 77,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 85,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 70,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 83,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 79,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 71,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 67,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 64,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 62,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 75,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 84,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 87,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 89,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 82,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 78,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 69,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 65,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 61,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 60,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 72,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 80,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 86,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 88,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 90,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 92,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 93,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 95,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 94,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 91,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 81,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 74,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 73,
                    "unit": "PERCENT",
                    "symbol": "%",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 25,
        "progresId": 27,
        "grouping": "HOUR",
        "sensorCategory": "WET_BULB",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 11.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 12.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 12.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 11.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 11.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": -0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 9.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 11.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 14.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 14.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 13.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 13.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 13.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 13.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 12.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 11.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 12.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 9.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 9.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 8.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 5.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 5.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 8.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 8.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 8.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 8.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 10.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 12.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 13.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 13.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 13.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 13,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 12.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 11.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 11.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 10.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 9.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 8.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 7.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 7.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 6.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 5.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 4.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 3.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 2.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 6.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 6.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 6.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 5.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 4.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 5.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 6.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 8.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 4.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": -0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 3.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 4.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 5.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 3.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 1.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 3.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 3.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": -0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": -0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 4.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 6.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 6.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 4.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 2.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": -0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": -1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": -1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": -2.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": -2.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": -1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 0.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 2.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 4.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 5.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 8.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 3.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 3.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 1.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": -0.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": -1.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": -1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 1.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 3.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 4.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 6.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 7.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 7.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 8.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 5.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 1.9,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": -0.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": -2.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": -2.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": -2.2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 4.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 6.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 7.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 7.7,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 7.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 5.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 3.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": -0.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": -0.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": -1.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": -1.1,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": -2,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": -1.4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": -1.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": -2.5,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": -0.6,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 4,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 7.3,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 7.8,
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 24,
        "progresId": 26,
        "grouping": "HOUR",
        "sensorCategory": "RADIATION",
        "sensorType": "ANALOG",
        "series": {
            "MINIMUM": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 65,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 100,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 160,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 99,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 54,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 63,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 100,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 300,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 386,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 282,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 152,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 147,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 186,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 125,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 90,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 202,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 278,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 235,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 189,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 187,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 169,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 44,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 153,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 236,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 225,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 221,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 132,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 60,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 121,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 221,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 17,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 30,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 218,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 188,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 305,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 477,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 398,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 315,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 211,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 19,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 16,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 54,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 65,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 112,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 188,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 219,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 301,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 201,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 19,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 103,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 130,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 126,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 190,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 217,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 160,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 21,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 50,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 84,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 158,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 154,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 243,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 276,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 313,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 206,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 22,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 101,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 198,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 244,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 367,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 428,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 314,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 211,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 23,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 150,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 305,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 426,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 502,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 512,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 439,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 310,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 208,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 83,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 212,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 393,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 498,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 523,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 440,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 306,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 205,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 31,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 4,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 84,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 312,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 115,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 115,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                }
            ],
            "MAXIMUM": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 15,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 76,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 121,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 237,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 643,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 643,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 263,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 161,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 148,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 116,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 146,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 300,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 386,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 486,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 603,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 650,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 646,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 518,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 396,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 218,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 13,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 131,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 273,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 397,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 598,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 360,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 408,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 330,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 323,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 240,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 44,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 154,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 356,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 443,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 553,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 413,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 362,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 145,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 326,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 321,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 221,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 17,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 40,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 348,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 425,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 505,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 573,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 561,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 512,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 420,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 315,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 211,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 19,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 16,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 54,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 81,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 153,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 253,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 384,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 507,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 421,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 301,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 201,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 19,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 122,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 191,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 198,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 190,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 480,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 506,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 343,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 175,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 21,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 50,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 84,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 173,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 230,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 278,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 460,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 585,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 424,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 313,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 206,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 22,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 125,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 202,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 277,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 386,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 589,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 521,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 428,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 321,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 211,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 23,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 152,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 305,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 437,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 502,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 543,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 551,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 512,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 439,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 310,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 208,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 26,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 88,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 276,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 393,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 498,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 538,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 541,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 523,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 440,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 315,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 205,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 31,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 4,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 96,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 312,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 445,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 544,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 153,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                }
            ],
            "AVERAGE": [
                {
                    "x": 1737932400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737936000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737939600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737943200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737946800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737950400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737954000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737957600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737961200000,
                    "y": 8,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737964800000,
                    "y": 29,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737968400000,
                    "y": 91,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737972000000,
                    "y": 133,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737975600000,
                    "y": 304,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737979200000,
                    "y": 170,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737982800000,
                    "y": 111,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737986400000,
                    "y": 95,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737990000000,
                    "y": 55,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737993600000,
                    "y": 57,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1737997200000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738004400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738008000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738011600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738015200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738018800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738022400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738026000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738029600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738033200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738036800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738040400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738044000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738047600000,
                    "y": 50,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738051200000,
                    "y": 226,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738054800000,
                    "y": 345,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738058400000,
                    "y": 438,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738062000000,
                    "y": 517,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738065600000,
                    "y": 419,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738069200000,
                    "y": 421,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738072800000,
                    "y": 357,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738076400000,
                    "y": 192,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738080000000,
                    "y": 68,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738083600000,
                    "y": 1,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738087200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738090800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738094400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738098000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738101600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738105200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738108800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738112400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738116000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738119600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738123200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738126800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738130400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738134000000,
                    "y": 43,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738137600000,
                    "y": 197,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738141200000,
                    "y": 319,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738144800000,
                    "y": 393,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738148400000,
                    "y": 273,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738152000000,
                    "y": 243,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738155600000,
                    "y": 234,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738159200000,
                    "y": 236,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738162800000,
                    "y": 96,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738166400000,
                    "y": 14,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738170000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738173600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738177200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738180800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738184400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738188000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738191600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738195200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738198800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738202400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738206000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738209600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738213200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738216800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738220400000,
                    "y": 51,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738224000000,
                    "y": 265,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738227600000,
                    "y": 324,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738231200000,
                    "y": 381,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738234800000,
                    "y": 291,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738238400000,
                    "y": 216,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738242000000,
                    "y": 107,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738245600000,
                    "y": 169,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738249200000,
                    "y": 272,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738252800000,
                    "y": 99,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738256400000,
                    "y": 2,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738260000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738263600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738267200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738270800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738274400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738278000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738281600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738285200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738288800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738292400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738296000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738299600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738303200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738306800000,
                    "y": 18,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738310400000,
                    "y": 192,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738314000000,
                    "y": 334,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738317600000,
                    "y": 431,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738321200000,
                    "y": 508,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738324800000,
                    "y": 525,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738328400000,
                    "y": 468,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738332000000,
                    "y": 362,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738335600000,
                    "y": 264,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738339200000,
                    "y": 115,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738342800000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738346400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738350000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738353600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738357200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738360800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738364400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738368000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738371600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738375200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738378800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738382400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738386000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738389600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738393200000,
                    "y": 6,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738396800000,
                    "y": 23,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738400400000,
                    "y": 71,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738404000000,
                    "y": 112,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738407600000,
                    "y": 197,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738411200000,
                    "y": 240,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738414800000,
                    "y": 401,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738418400000,
                    "y": 359,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738422000000,
                    "y": 253,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738425600000,
                    "y": 111,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738429200000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738432800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738436400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738440000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738443600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738447200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738450800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738454400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738458000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738461600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738465200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738468800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738472400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738476000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738479600000,
                    "y": 7,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738483200000,
                    "y": 47,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738486800000,
                    "y": 94,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738490400000,
                    "y": 159,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738494000000,
                    "y": 159,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738497600000,
                    "y": 167,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738501200000,
                    "y": 281,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738504800000,
                    "y": 349,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738508400000,
                    "y": 237,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738512000000,
                    "y": 95,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738515600000,
                    "y": 3,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738519200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738522800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738526400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738530000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738533600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738537200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738540800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738544400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738548000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738551600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738555200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738558800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738562400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738566000000,
                    "y": 17,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738569600000,
                    "y": 60,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738573200000,
                    "y": 135,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738576800000,
                    "y": 197,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738580400000,
                    "y": 229,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738584000000,
                    "y": 326,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738587600000,
                    "y": 441,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738591200000,
                    "y": 368,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738594800000,
                    "y": 264,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738598400000,
                    "y": 114,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738602000000,
                    "y": 4,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738605600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738609200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738612800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738616400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738620000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738623600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738627200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738630800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738634400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738638000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738641600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738645200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738648800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738652400000,
                    "y": 24,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738656000000,
                    "y": 84,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738659600000,
                    "y": 157,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738663200000,
                    "y": 243,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738666800000,
                    "y": 285,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738670400000,
                    "y": 502,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738674000000,
                    "y": 477,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738677600000,
                    "y": 367,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738681200000,
                    "y": 270,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738684800000,
                    "y": 120,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738688400000,
                    "y": 4,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738692000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738695600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738699200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738702800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738706400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738710000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738713600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738717200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738720800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738724400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738728000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738731600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738735200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738738800000,
                    "y": 53,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738742400000,
                    "y": 229,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738746000000,
                    "y": 364,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738749600000,
                    "y": 467,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738753200000,
                    "y": 526,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738756800000,
                    "y": 532,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738760400000,
                    "y": 473,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738764000000,
                    "y": 367,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738767600000,
                    "y": 260,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738771200000,
                    "y": 121,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738774800000,
                    "y": 5,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738778400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738782000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738785600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738789200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738792800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738796400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738800000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738803600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738807200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738810800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738814400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738818000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738821600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738825200000,
                    "y": 42,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738828800000,
                    "y": 153,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738832400000,
                    "y": 294,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738836000000,
                    "y": 444,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738839600000,
                    "y": 522,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738843200000,
                    "y": 533,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738846800000,
                    "y": 486,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738850400000,
                    "y": 375,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738854000000,
                    "y": 264,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738857600000,
                    "y": 122,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738861200000,
                    "y": 6,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738864800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738868400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738872000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738875600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738879200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738882800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738886400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738890000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738893600000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738897200000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738900800000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738904400000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738908000000,
                    "y": 0,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738911600000,
                    "y": 41,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738915200000,
                    "y": 176,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738918800000,
                    "y": 368,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738922400000,
                    "y": 379,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                },
                {
                    "x": 1738926000000,
                    "y": 133,
                    "unit": "SOLAR_RADIATION",
                    "symbol": "W/m2",
                    "additionalInfo": null
                }
            ]
        }
    },
    {
        "id": 26,
        "progresId": 3,
        "grouping": "HOUR",
        "sensorCategory": "RAIN_GAUGE",
        "sensorType": "METER",
        "series": {
            "LEAK": [
                {
                    "x": 1737964200000,
                    "y": 0,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1737966000000,
                    "y": 0,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1737970200000,
                    "y": 0,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 0,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                }
            ],
            "VOLUME": [
                {
                    "x": 1737964200000,
                    "y": 20,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1737966000000,
                    "y": 40,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1737970200000,
                    "y": 40,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                },
                {
                    "x": 1738000800000,
                    "y": 20,
                    "unit": "CUBIC_METERS",
                    "symbol": null,
                    "additionalInfo": null
                }
            ]
        }
    }
]

export const MOCK_DPV_DATA = {
    "id": 1,
    "grouping": "HOUR",
    "series": {
        "DPV": [
            {
                "x": 1737932400000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 0.21,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 0.24,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 0.26,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 0.22,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 0.45,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 0.36,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 0.38,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 0.46,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 0.43,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 0.24,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 0.38,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 0.49,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 0.55,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 0.69,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 0.79,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 0.82,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 0.92,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 0.8,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 0.69,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 0.6,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 0.57,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 0.52,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 0.46,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 0.41,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 0.38,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 0.24,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 0.14,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 0.2,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 0.35,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 0.39,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 0.47,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 0.51,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 0.46,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 0.55,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 0.62,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 0.59,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 0.5,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 0.43,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 0.39,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 0.31,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 0.31,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 0.21,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 0.19,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 0.14,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 0.2,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 0.25,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 0.35,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 0.37,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 0.43,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 0.38,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 0.21,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 0.2,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 0.14,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 0.29,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 0.39,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 0.49,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 0.6,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 0.7,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 0.75,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 0.67,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 0.5,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 0.29,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": 0.25,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": 0.21,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": 0.13,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": 0.13,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": 0.09,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": 0.09,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 0.22,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 0.28,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 0.33,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 0.44,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 0.42,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 0.32,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 0.19,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 0.15,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 0.32,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 0.35,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 0.15,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": 0.02,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.02,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 0.09,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 0.15,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 0.23,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 0.36,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738623600000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738627200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738630800000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738634400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738638000000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738641600000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738645200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738648800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738652400000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738656000000,
                "y": 0.02,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738659600000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738663200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738666800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738670400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738674000000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738677600000,
                "y": 0.22,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738681200000,
                "y": 0.36,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738684800000,
                "y": 0.47,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738688400000,
                "y": 0.46,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738692000000,
                "y": 0.19,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738695600000,
                "y": 0.11,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738699200000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738702800000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738706400000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738710000000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738713600000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738717200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738720800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738724400000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738728000000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738731600000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738735200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738738800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738742400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738746000000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738749600000,
                "y": 0.13,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738753200000,
                "y": 0.14,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738756800000,
                "y": 0.18,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738760400000,
                "y": 0.25,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738764000000,
                "y": 0.32,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738767600000,
                "y": 0.4,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738771200000,
                "y": 0.47,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738774800000,
                "y": 0.52,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738778400000,
                "y": 0.26,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738782000000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738785600000,
                "y": 0.12,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738789200000,
                "y": 0.09,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738792800000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738796400000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738800000000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738803600000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738807200000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738810800000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738814400000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738818000000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738821600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738825200000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738828800000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738832400000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738836000000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738839600000,
                "y": 0.16,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738843200000,
                "y": 0.2,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738846800000,
                "y": 0.27,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738850400000,
                "y": 0.36,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738854000000,
                "y": 0.42,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738857600000,
                "y": 0.52,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738861200000,
                "y": 0.53,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738864800000,
                "y": 0.29,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738868400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738872000000,
                "y": 0.1,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738875600000,
                "y": 0.08,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738879200000,
                "y": 0.07,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738882800000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738886400000,
                "y": 0.05,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738890000000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738893600000,
                "y": 0.04,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738897200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738900800000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738904400000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738908000000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738911600000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738915200000,
                "y": 0.03,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738918800000,
                "y": 0.06,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738922400000,
                "y": 0.17,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738926000000,
                "y": 0.3,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738926600000,
                "y": 0.34,
                "unit": "CUSTOM_DEFINED",
                "symbol": "kPa",
                "name": "DPV - La Serra",
                "additionalInfo": null
            }
        ]
    }
}

export const MOCK_ETO_DATA = {
    "id": 2,
    "grouping": "DAY",
    "series": {
        "ETO": [
            {
                "x": 1738018799000,
                "y": 0.61,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738105199000,
                "y": 1.92,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738191599000,
                "y": 1.09,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738277999000,
                "y": 1.17,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738364399000,
                "y": 1.66,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738450799000,
                "y": 0.77,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738537199000,
                "y": 0.74,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738623599000,
                "y": 0.95,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738709999000,
                "y": 1.08,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738796399000,
                "y": 1.52,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738882799000,
                "y": 1.41,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738925999000,
                "y": 0.41,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            }
        ],
        "ACCUMULATED": [
            {
                "x": 1738018799000,
                "y": 0.61,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738105199000,
                "y": 2.53,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738191599000,
                "y": 3.62,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738277999000,
                "y": 4.79,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738364399000,
                "y": 6.45,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738450799000,
                "y": 7.22,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738537199000,
                "y": 7.96,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738623599000,
                "y": 8.91,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738709999000,
                "y": 9.99,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738796399000,
                "y": 11.51,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738882799000,
                "y": 12.92,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            },
            {
                "x": 1738925999000,
                "y": 13.33,
                "unit": "MILLIMETERS",
                "symbol": "mm",
                "name": "ETo - La Serra",
                "additionalInfo": null
            }
        ]
    }
}

export const MOCK_ANTI_FROST_SURV = {
    "id": 3,
    "grouping": "HOUR",
    "series": {
        "DEW_POINT": [
            {
                "x": 1737932400000,
                "y": 5.37,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737932400000,
                "y": 5.37,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 5.21,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 5.21,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 5.49,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 5.49,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 5.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 5.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 4.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 4.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 5.53,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 5.53,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 5.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 5.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 6.16,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 6.16,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 6.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 6.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 7.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 7.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 9.14,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 9.14,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 11.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 11.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 12.42,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 12.42,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 12.23,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 12.23,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 11.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 11.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 11.56,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 11.56,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 11.41,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 11.41,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 11.43,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 11.43,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 10.22,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 10.22,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 8.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 8.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 7.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 7.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 5.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 5.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 3.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 3.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 2.76,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 2.76,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 2.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 2.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 1.96,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 1.96,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 1.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 1.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 1.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 1.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 2.93,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 2.93,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 2.84,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 2.84,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 2.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 2.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 1.15,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 1.15,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 0.98,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 0.98,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 0.41,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 0.41,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 1.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 1.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 1.54,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 1.54,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 1.87,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 1.87,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 2.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 2.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 2.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 2.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 2.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 2.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 2.79,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 2.79,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 2.84,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 2.84,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 2.92,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 2.92,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 2.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 2.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 1.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 1.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 1.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 1.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 1.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 1.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 1.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 1.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 2.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 2.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 2.77,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 2.77,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 2.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 2.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 3.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 3.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 3.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 3.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 3.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 3.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 2.57,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 2.57,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 2.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 2.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 2.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 2.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 3.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 3.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 3.43,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 3.43,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 3.37,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 3.37,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 2.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 2.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 3.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 3.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 2.87,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 2.87,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 2.49,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 2.49,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 2.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 2.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 2.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 2.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 2.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 2.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 1.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 1.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 1.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 1.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 3.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 3.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 3.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 3.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 3.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 3.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 4.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 4.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 4.25,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 4.25,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 4.62,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 4.62,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 4.73,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 4.73,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 4.47,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 4.47,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 3.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 3.72,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 3.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 3.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 3.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 3.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 3.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 3.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 2.53,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 2.53,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 2.45,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 2.45,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 2.34,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 2.34,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 2.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 2.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 1.59,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 1.59,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 0.22,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 0.22,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 0.21,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 0.21,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": -0.39,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": -0.39,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": -0.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": -0.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 1.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 1.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 2.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 2.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 3.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 3.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 3.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 3.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 3.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 3.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 3.13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 3.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 3.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 2.82,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 2.82,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 2.75,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 2.75,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 1.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 1.09,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 0.81,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 0.81,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 0.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 0.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": -0.29,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": -0.29,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": -0.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": -0.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": -0.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": -0.94,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": -1.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": -1.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": -2.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": -2.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": -2.28,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": -2.28,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": -1.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": -1.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": -1.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": -1.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": -1.93,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": -1.93,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": -1.75,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": -1.75,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": -1.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": -1.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": -0.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": -0.69,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 0.18,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 0.18,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 0.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 0.85,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 1.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 1.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 1.28,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 1.28,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 1.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 1.19,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 1.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 1.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 1.79,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 1.79,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 2.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 2.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 1.89,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 1.89,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 1.31,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 1.31,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 0.71,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 0.71,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 0.23,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 0.23,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": -0.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": -0.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": -0.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": -0.61,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": -0.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": -0.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": -0.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": -0.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": -0.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": -0.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 0.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 0.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 0.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 0.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 0.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 0.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 1.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 1.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 1.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 1.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 1.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 1.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 2.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 2.06,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 2.35,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 2.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 2.99,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 3.05,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 3.05,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 2.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 2.83,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 1.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 1.66,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 1.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 1.08,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 0.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 0.38,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": -0.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": -0.36,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": -1.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": -1.32,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": -2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": -2.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": -2.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": -2.04,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": -2.12,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": -2.12,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": -2.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": -2.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": -3.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": -3.02,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": -2.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": -2.17,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": -2.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": -2.46,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": -1.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": -1.11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.29,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.29,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 1.52,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 1.52,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 1.97,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 1.97,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 2.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 2.68,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 3.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 3.03,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 3.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 3.63,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 3.71,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 3.71,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 3.45,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 3.45,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 2.51,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 2.51,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 1.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 1.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 1.16,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 1.16,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": -0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": -0.33,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": -1.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Punt rosada",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": -1.26,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "PdR - La Serra meteo",
                "additionalInfo": null
            }
        ],
        "TEMPERATURE": [
            {
                "x": 1737932400000,
                "y": 8.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 7.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 9.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 9.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 10.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 11.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 14.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 15.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 16.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 15.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 15.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 14.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 14.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 12.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 12.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 13.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 11.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 10.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 9.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 9.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 9.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 7.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 5.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 10.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 12.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 12.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 13.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 12.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 11.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 10.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 10.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 9.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 9.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 8.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 8.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 7.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 6.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 4.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 5.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 7.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 10.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 10.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 10.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 11.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 11.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 11,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 10,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 8.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 8.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 7.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 8.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 5.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 3.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 4.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 7.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 8.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 9.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 9.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 9.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 10.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 10.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 8.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 5.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 5.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 4.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": 0.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 1.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": 0.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": 0.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 3.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 7.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 9.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 10.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 11.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 12.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 13.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 12.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 9.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 7.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 5.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": 5.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": 5.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": 0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": 1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": 0.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": 0.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": 1.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 3.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 5.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 6.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 7.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 8.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 5.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 4.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 3.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 2.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": 0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": 0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 1.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 1.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 2.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 3.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 5.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 8.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 8.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 4.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": -0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": -1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": -1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": -1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": -1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": -2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": -1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": -1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": -1.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": -0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 2.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 3.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 9.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 8.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 3.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 1.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 0.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Temperat",
                "additionalInfo": null
            }
        ],
        "WET_BULB": [
            {
                "x": 1737932400000,
                "y": 6.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737936000000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737939600000,
                "y": 7.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737943200000,
                "y": 7.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737946800000,
                "y": 7.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737950400000,
                "y": 7.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737954000000,
                "y": 7.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737957600000,
                "y": 8.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737961200000,
                "y": 8.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737964800000,
                "y": 9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737968400000,
                "y": 10.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737972000000,
                "y": 12.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737975600000,
                "y": 13.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737979200000,
                "y": 13.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737982800000,
                "y": 13.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737986400000,
                "y": 13,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737990000000,
                "y": 12.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737993600000,
                "y": 12.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1737997200000,
                "y": 11.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738000800000,
                "y": 11.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738004400000,
                "y": 11.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738008000000,
                "y": 10.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738011600000,
                "y": 9.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738015200000,
                "y": 8.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738018800000,
                "y": 7.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738022400000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738026000000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738029600000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738033200000,
                "y": 5.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738036800000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738040400000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738044000000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738047600000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738051200000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738054800000,
                "y": 6.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738058400000,
                "y": 7.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738062000000,
                "y": 7.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738065600000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738069200000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738072800000,
                "y": 7.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738076400000,
                "y": 7.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738080000000,
                "y": 7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738083600000,
                "y": 6.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738087200000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738090800000,
                "y": 6.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738094400000,
                "y": 6.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738098000000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738101600000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738105200000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738108800000,
                "y": 5.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738112400000,
                "y": 5.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738116000000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738119600000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738123200000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738126800000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738130400000,
                "y": 3.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738134000000,
                "y": 3.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738137600000,
                "y": 5.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738141200000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738144800000,
                "y": 6.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738148400000,
                "y": 7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738152000000,
                "y": 7.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738155600000,
                "y": 7.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738159200000,
                "y": 7.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738162800000,
                "y": 6.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738166400000,
                "y": 6.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738170000000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738173600000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738177200000,
                "y": 5.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738180800000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738184400000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738188000000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738191600000,
                "y": 4.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738195200000,
                "y": 4.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738198800000,
                "y": 4.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738202400000,
                "y": 3.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738206000000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738209600000,
                "y": 3.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738213200000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738216800000,
                "y": 2.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738220400000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738224000000,
                "y": 4.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738227600000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738231200000,
                "y": 6.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738234800000,
                "y": 6.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738238400000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738242000000,
                "y": 6.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738245600000,
                "y": 7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738249200000,
                "y": 7.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738252800000,
                "y": 7.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738256400000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738260000000,
                "y": 5.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738263600000,
                "y": 4.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738267200000,
                "y": 5.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738270800000,
                "y": 4.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738274400000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738278000000,
                "y": 4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738281600000,
                "y": 4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738285200000,
                "y": 3.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738288800000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738292400000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738296000000,
                "y": 0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738299600000,
                "y": 0.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738303200000,
                "y": 0.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738306800000,
                "y": 0.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738310400000,
                "y": 2.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738314000000,
                "y": 4.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738317600000,
                "y": 5.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738321200000,
                "y": 6.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738324800000,
                "y": 6.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738328400000,
                "y": 7.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738332000000,
                "y": 8.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738335600000,
                "y": 8.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738339200000,
                "y": 7.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738342800000,
                "y": 6.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738346400000,
                "y": 4.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738350000000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738353600000,
                "y": 3.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738357200000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738360800000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738368000000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738371600000,
                "y": -0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738375200000,
                "y": -0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738378800000,
                "y": 0.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738382400000,
                "y": 0,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738386000000,
                "y": -0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738389600000,
                "y": -0.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738393200000,
                "y": 0.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738396800000,
                "y": 0.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738400400000,
                "y": 2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738404000000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738407600000,
                "y": 3.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738411200000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738414800000,
                "y": 4.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738418400000,
                "y": 5.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738422000000,
                "y": 5.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738425600000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738429200000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738432800000,
                "y": 3.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738436400000,
                "y": 3.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738440000000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738443600000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738447200000,
                "y": 1.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738450800000,
                "y": 0.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738454400000,
                "y": 0,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738458000000,
                "y": 0,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738461600000,
                "y": 0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738465200000,
                "y": 0.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738468800000,
                "y": 0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738472400000,
                "y": 0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738476000000,
                "y": 0.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738479600000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738483200000,
                "y": 1.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738486800000,
                "y": 2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738490400000,
                "y": 2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738494000000,
                "y": 2.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738497600000,
                "y": 3.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738501200000,
                "y": 3.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738504800000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738508400000,
                "y": 6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738512000000,
                "y": 5.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738515600000,
                "y": 3.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738519200000,
                "y": 2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738522800000,
                "y": 1.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738526400000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738530000000,
                "y": 0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738533600000,
                "y": -0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738537200000,
                "y": -1.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738540800000,
                "y": -1.9,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738544400000,
                "y": -1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738548000000,
                "y": -1.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738551600000,
                "y": -1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738555200000,
                "y": -2.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738558800000,
                "y": -1.7,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738562400000,
                "y": -2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738566000000,
                "y": -2.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738569600000,
                "y": -0.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738573200000,
                "y": 0.5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738576800000,
                "y": 2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738580400000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738584000000,
                "y": 4.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738587600000,
                "y": 5,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738591200000,
                "y": 6.4,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738594800000,
                "y": 6.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738598400000,
                "y": 6.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738602000000,
                "y": 4.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738605600000,
                "y": 2.8,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738609200000,
                "y": 2.2,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738612800000,
                "y": 1.1,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738616400000,
                "y": 0.3,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            },
            {
                "x": 1738620000000,
                "y": -0.6,
                "unit": "DEGREES_CELSIUS",
                "symbol": "ºC",
                "name": "Bulb",
                "additionalInfo": null
            }
        ]
    }
}

export const MOCK_SOIL_CONTROL_POINT_DATA = {
    "id": 1,
    "name": "AgroSens Riego Aquacheck",
    "model": null,
    "packCode": null,
    "type": "SOIL",
    "sensorPack": null,
    "sensors": [
        {
            "id": 6,
            "deviceId": 10045,
            "sensorCategory": "VOLUMETRIC_WATER_CONTENT",
            "sensorType": "ANALOG",
            "sensorDepth": 60,
            "readings": {
                "currentValue": {
                    "value": 28.2,
                    "valueFormatted": "28.2",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 5,
            "isCalculated": null
        },
        {
            "id": 8,
            "deviceId": 10045,
            "sensorCategory": "VOLUMETRIC_WATER_CONTENT",
            "sensorType": "ANALOG",
            "sensorDepth": 80,
            "readings": {
                "currentValue": {
                    "value": 30.5,
                    "valueFormatted": "30.5",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 7,
            "isCalculated": null
        },
        {
            "id": 2,
            "deviceId": 10045,
            "sensorCategory": "VOLUMETRIC_WATER_CONTENT",
            "sensorType": "ANALOG",
            "sensorDepth": 20,
            "readings": {
                "currentValue": {
                    "value": 17.7,
                    "valueFormatted": "17.7",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 1,
            "isCalculated": null
        },
        {
            "id": 5,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": 40,
            "readings": {
                "currentValue": {
                    "value": 6.3,
                    "valueFormatted": "6.3",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 4,
            "isCalculated": null
        },
        {
            "id": 30,
            "deviceId": 10045,
            "sensorCategory": "RAIN_GAUGE",
            "sensorType": "METER",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0.0",
                    "unit": "CUBIC_METERS_PER_HOUR",
                    "symbol": null
                }
            },
            "progresId": 3,
            "isCalculated": null
        },
        {
            "id": 7,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": 60,
            "readings": {
                "currentValue": {
                    "value": 7.2,
                    "valueFormatted": "7.2",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 6,
            "isCalculated": null
        },
        {
            "id": 4,
            "deviceId": 10045,
            "sensorCategory": "VOLUMETRIC_WATER_CONTENT",
            "sensorType": "ANALOG",
            "sensorDepth": 40,
            "readings": {
                "currentValue": {
                    "value": 26.6,
                    "valueFormatted": "26.6",
                    "unit": "PERCENT",
                    "symbol": "%"
                }
            },
            "progresId": 3,
            "isCalculated": null
        },
        {
            "id": 9,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": 80,
            "readings": {
                "currentValue": {
                    "value": 7.8,
                    "valueFormatted": "7.8",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 8,
            "isCalculated": null
        },
        {
            "id": 33,
            "deviceId": 10046,
            "sensorCategory": "IRRIGATION_COUNTER",
            "sensorType": "METER",
            "sensorDepth": null,
            "readings": {
                "currentValue": {
                    "value": 0,
                    "valueFormatted": "0",
                    "unit": "LITERS_PER_HOUR",
                    "symbol": null
                }
            },
            "progresId": 8,
            "isCalculated": null
        },
        {
            "id": 3,
            "deviceId": 10045,
            "sensorCategory": "TEMPERATURE",
            "sensorType": "ANALOG",
            "sensorDepth": 20,
            "readings": {
                "currentValue": {
                    "value": 5.7,
                    "valueFormatted": "5.7",
                    "unit": "DEGREES_CELSIUS",
                    "symbol": "ºC"
                }
            },
            "progresId": 2,
            "isCalculated": null
        }
    ],
    "farmId": 1,
    "farmName": "La Serra",
    "geoJson": "{ \"type\": \"Feature\", \"properties\": {}, \"geometry\": { \"coordinates\": [ 0.8703896121940033, 41.61831965178598 ], \"type\": \"Point\" } }",
    "availableAgronomicVariables": [
        "AVAILABLE_WATER",
        "CAMPAIGN_IRRIGATION"
    ]
}

export const MOCK_AVAILABLE_WATER = {
    "id": 2,
    "deviceId": 10046,
    "sectorId": 48,
    "sectorName": "Sec. 48_ ",
    "availableWaterSoilCapacity": 100,
    "availableWaterWiltingThreshold": 50,
    "status": "NOT_IRRIGATING",
    "manualStatus": "PROGRAMMED",
    "errorStatus": "NO_ERROR",
    "grouping": "TEN_MINUTES",
    "series": {
      "20": [
        {
          "x": 1728639000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728639600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728640200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728640800000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728641400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728642000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728642600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728643200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728643800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728644400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728645000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728645600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728646200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728646800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728647400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728648000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728648600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728649200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728649800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728650400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728651000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728651600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728652200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728652800000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728653400000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728654000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728654600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728655200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728655800000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728656400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728657000000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728657600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728658200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728658800000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728659400000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728660000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728660600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728661200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728661800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728662400000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728663000000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728663600000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728664200000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728664800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728665400000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728666000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728666600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728667200000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728667800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728668400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728669000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728669600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728670200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728670800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728671400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728672000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728672600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728673200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728673800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728674400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728675000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728675600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728676200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728676800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728677400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728678000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728678600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728679200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728679800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728680400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728681000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728681600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728682200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728682800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728683400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728684000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728684600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728685200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728685800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728686400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728687000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728687600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728688200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728688800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728689400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728690000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728690600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728691200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728691800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728692400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728693000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728693600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728694200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728694800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728695400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728696000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728696600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728697200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728697800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728698400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728699000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728699600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728700200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728700800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728701400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728702000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728702600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728703200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728703800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728704400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728705000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728705600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728706200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728706800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728707400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728708000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728708600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728709200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728709800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728710400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728711000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728711600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728712200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728712800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728713400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728714000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728714600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728715200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728715800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728716400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728717000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728717600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728718200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728718800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728719400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728720000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728720600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728721200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728721800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728722400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728723000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728723600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728724200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728724800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728725400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728726000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728726600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728727200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728727800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728728400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728729000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728729600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728730200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728730800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728731400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728732000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728732600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728733200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728733800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728734400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728735000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728735600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728736200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728736800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728737400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728738000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728738600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728739200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728739800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728740400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728741000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728741600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728742200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728742800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728743400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728744000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728744600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728745200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728745800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728746400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728747000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728747600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728748200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728748800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728749400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728750000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728750600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728751200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728751800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728752400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728753000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728753600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728754200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728754800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728755400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728756000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728756600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728757200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728757800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728758400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728759000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728759600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728760200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728760800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728761400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728762000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728762600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728763200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728763800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728764400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728765000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728765600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728766200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728766800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728767400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728768000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728768600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728769200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728769800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728770400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728771000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728771600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728772200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728772800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728773400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728774000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728774600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728775200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728775800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728776400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728777000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728777600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728778200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728778800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728779400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728780000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728780600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728781200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728781800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728782400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728783000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728783600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728784200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728784800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728785400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728786000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728786600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728787200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728787800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728788400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728789000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728789600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728790200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728790800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728791400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728792000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728792600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728793200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728793800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728794400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728795000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728795600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728796200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728796800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728797400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728798000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728798600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728799200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728799800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728800400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728801000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728801600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728802200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728802800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728803400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728804000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728804600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728805200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728805800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728806400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728807000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728807600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728808200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728808800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728809400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728810000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728810600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728811200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728811800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728812400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728813000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728813600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728814200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728814800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728815400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728816000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728816600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728817200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728817800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728818400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728819000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728819600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728820200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728820800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728821400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728822000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728822600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728823200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728823800000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728824400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728825000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728825600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728826200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728826800000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728827400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728828000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728828600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728829200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728829800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728830400000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728831000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728831600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728832200000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728832800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728833400000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728834000000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728834600000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728835200000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728835800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728836400000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728837000000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728837600000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728838200000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728838800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728839400000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728840000000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728840600000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728841200000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728841800000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728842400000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728843000000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728843600000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728844200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728844800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728845400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728846000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728846600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728847200000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728847800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728848400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728849000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728849600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728850200000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728850800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728851400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728852000000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728852600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728853200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728853800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728854400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728855000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728855600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728856200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728856800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728857400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728858000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728858600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728859200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728859800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728860400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728861000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728861600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728862200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728862800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728863400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728864000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728864600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728865200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728865800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728866400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728867000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728867600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728868200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728868800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728869400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728870000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728870600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728871200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728871800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728872400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728873000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728873600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728874200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728874800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728875400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728876000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728876600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728877200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728877800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728878400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728879000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728879600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728880200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728880800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728881400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728882000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728882600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728883200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728883800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728884400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728885000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728885600000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728886200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728886800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728887400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728888000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728888600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728889200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728889800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728890400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728891000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728891600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728892200000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728892800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728893400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728894000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728894600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728895200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728895800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728896400000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728897000000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728897600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728898200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728898800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728899400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728900000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728900600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728901200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728901800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728902400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728903000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728903600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728904200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728904800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728905400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728906000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728906600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728907200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728907800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728908400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728909000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728909600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728910200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728910800000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728911400000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728912000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728912600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728913200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728913800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728914400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728915000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728915600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728916200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728916800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728917400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728918000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728918600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728919200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728919800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728920400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728921000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728921600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728922200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728922800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728923400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728924000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728924600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728925200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728925800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728926400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728927000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728927600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728928200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728928800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728929400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728930000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728930600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728931200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728931800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728932400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728933000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728933600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728934200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728934800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728935400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728936000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728936600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728937200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728937800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728938400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728939000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728939600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728940200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728940800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728941400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728942000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728942600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728943200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728943800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728944400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728945000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728945600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728946200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728946800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728947400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728948000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728948600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728949200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728949800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728950400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728951000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728951600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728952200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728952800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728953400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728954000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728954600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728955200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728955800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728956400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728957000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728957600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728958200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728958800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728959400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728960000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728960600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728961200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728961800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728962400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728963000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728963600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728964200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728964800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728965400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728966000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728966600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728967200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728967800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728968400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728969000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728969600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728970200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728970800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728971400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728972000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728972600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728973200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728973800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728974400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728975000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728975600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728976200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728976800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728977400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728978000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728978600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728979200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728979800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728980400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728981000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728981600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728982200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728982800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728983400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728984000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728984600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728985200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728985800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728986400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728987000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728987600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728988200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728988800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728989400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728990000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728990600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728991200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728991800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728992400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728993000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728993600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728994200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728994800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728995400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728996000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728996600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728997200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728997800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728998400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728999000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1728999600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729000200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729000800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729001400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729002000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729002600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729003200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729003800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729004400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729005000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729005600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729006200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729006800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729007400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729008000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729008600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729009200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729009800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729010400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729011000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729011600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729012200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729012800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729013400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729014000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729014600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729015200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729015800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729016400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729017000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729017600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729018200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729018800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729019400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729020000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729020600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729021200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729021800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729022400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729023000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729023600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729024200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729024800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729025400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729026000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729026600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729027200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729027800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729028400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729029000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729029600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729030200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729030800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729031400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729032000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729032600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729033200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729033800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729034400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729035000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729035600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729036200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729036800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729037400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729038000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729038600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729039200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729039800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729040400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729041000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729041600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729042200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729042800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729043400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729044000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729044600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729045200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729045800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729046400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729047000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729047600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729048200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729048800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729049400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729050000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729050600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729051200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729051800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729052400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729053000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729053600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729054200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729054800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729055400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729056000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729056600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729057200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729057800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729058400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729059000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729059600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729060200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729060800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729061400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729062000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729062600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729063200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729063800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729064400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729065000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729065600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729066200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729066800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729067400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729068000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729068600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729069200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729069800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729070400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729071000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729071600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729072200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729072800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729073400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729074000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729074600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729075200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729075800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729076400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729077000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729077600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729078200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729078800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729079400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729080000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729080600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729081200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729081800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729082400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729083000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729083600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729084200000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729084800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729085400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729086000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729086600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729087200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729087800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729088400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729089000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729089600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729090200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729090800000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729091400000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729092000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729092600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729093200000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729093800000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729094400000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729095000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729095600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729096200000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729096800000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729097400000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729098000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729098600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729099200000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729099800000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729100400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729101000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729101600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729102200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729102800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729103400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729104000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729104600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729105200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729105800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729106400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729107000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729107600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729108200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729108800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729109400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729110000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729110600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729111200000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729111800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729112400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729113000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729113600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729114200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729114800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729115400000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729116000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729116600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729117200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729117800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729118400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729119000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729119600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729120200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729120800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729121400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729122000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729122600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729123200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729123800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729124400000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729125000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729125600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729126200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729126800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729127400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729128000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729128600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729129200000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729129800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729130400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729131000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729131600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729132200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729132800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729133400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729134000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729134600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729135200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729135800000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729136400000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729137000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729137600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729138200000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729138800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729139400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729140000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729140600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729141200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729141800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729142400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729143000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729143600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729144200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729144800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729145400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729146000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729146600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729147200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729147800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729148400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729149000000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729149600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729150200000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729150800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729151400000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729152000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729152600000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729153200000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729153800000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729154400000,
          "y": 97.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729155000000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729155600000,
          "y": 98.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729156200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729156800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729157400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729158000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729158600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729159200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729159800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729160400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729161000000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729161600000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729162200000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729162800000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729163400000,
          "y": 101.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729164000000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729164600000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729165200000,
          "y": 102.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729165800000,
          "y": 103.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729166400000,
          "y": 105,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729167000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729167600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729168200000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729168800000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729169400000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729170000000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729170600000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729171200000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729171800000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729172400000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729173000000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729173600000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729174200000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729174800000,
          "y": 108.75,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729175400000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729176000000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729176600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729177200000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729177800000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729178400000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729179000000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729179600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729180200000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729180800000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729181400000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729182000000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729182600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729183200000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729183800000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729184400000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729185000000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729185600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729186200000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729186800000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729187400000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729188000000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729188600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729189200000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729189800000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729190400000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729191000000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729191600000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729192200000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729192800000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729193400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729194000000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729194600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729195200000,
          "y": 107.5,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729195800000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729196400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729197000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729197600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729198200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729198800000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729199400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729200000000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729200600000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729201200000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729201800000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        },
        {
          "x": 1729202400000,
          "y": 106.25,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 20 cm",
          "sensorDepth": 20,
          "deviceId": 10045,
          "sensorId": 10,
          "additionalInfo": null
        }
      ],
      "40": [
        {
          "x": 1728639000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728639600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728640200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728640800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728641400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728642000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728642600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728643200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728643800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728644400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728645000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728645600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728646200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728646800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728647400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728648000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728648600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728649200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728649800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728650400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728651000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728651600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728652200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728652800000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728653400000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728654000000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728654600000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728655200000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728655800000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728656400000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728657000000,
          "y": 104.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728657600000,
          "y": 104.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728658200000,
          "y": 104.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728658800000,
          "y": 104.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728659400000,
          "y": 104.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728660000000,
          "y": 104.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728660600000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728661200000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728661800000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728662400000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728663000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728663600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728664200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728664800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728665400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728666000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728666600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728667200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728667800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728668400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728669000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728669600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728670200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728670800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728671400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728672000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728672600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728673200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728673800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728674400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728675000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728675600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728676200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728676800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728677400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728678000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728678600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728679200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728679800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728680400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728681000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728681600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728682200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728682800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728683400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728684000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728684600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728685200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728685800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728686400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728687000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728687600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728688200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728688800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728689400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728690000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728690600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728691200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728691800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728692400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728693000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728693600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728694200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728694800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728695400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728696000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728696600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728697200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728697800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728698400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728699000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728699600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728700200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728700800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728701400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728702000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728702600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728703200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728703800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728704400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728705000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728705600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728706200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728706800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728707400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728708000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728708600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728709200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728709800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728710400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728711000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728711600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728712200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728712800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728713400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728714000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728714600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728715200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728715800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728716400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728717000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728717600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728718200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728718800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728719400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728720000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728720600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728721200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728721800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728722400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728723000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728723600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728724200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728724800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728725400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728726000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728726600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728727200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728727800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728728400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728729000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728729600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728730200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728730800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728731400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728732000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728732600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728733200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728733800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728734400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728735000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728735600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728736200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728736800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728737400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728738000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728738600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728739200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728739800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728740400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728741000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728741600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728742200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728742800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728743400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728744000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728744600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728745200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728745800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728746400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728747000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728747600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728748200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728748800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728749400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728750000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728750600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728751200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728751800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728752400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728753000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728753600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728754200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728754800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728755400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728756000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728756600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728757200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728757800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728758400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728759000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728759600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728760200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728760800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728761400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728762000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728762600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728763200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728763800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728764400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728765000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728765600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728766200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728766800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728767400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728768000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728768600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728769200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728769800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728770400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728771000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728771600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728772200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728772800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728773400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728774000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728774600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728775200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728775800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728776400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728777000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728777600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728778200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728778800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728779400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728780000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728780600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728781200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728781800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728782400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728783000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728783600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728784200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728784800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728785400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728786000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728786600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728787200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728787800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728788400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728789000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728789600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728790200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728790800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728791400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728792000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728792600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728793200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728793800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728794400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728795000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728795600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728796200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728796800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728797400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728798000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728798600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728799200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728799800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728800400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728801000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728801600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728802200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728802800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728803400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728804000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728804600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728805200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728805800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728806400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728807000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728807600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728808200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728808800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728809400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728810000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728810600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728811200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728811800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728812400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728813000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728813600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728814200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728814800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728815400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728816000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728816600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728817200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728817800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728818400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728819000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728819600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728820200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728820800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728821400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728822000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728822600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728823200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728823800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728824400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728825000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728825600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728826200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728826800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728827400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728828000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728828600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728829200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728829800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728830400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728831000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728831600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728832200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728832800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728833400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728834000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728834600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728835200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728835800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728836400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728837000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728837600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728838200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728838800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728839400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728840000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728840600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728841200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728841800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728842400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728843000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728843600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728844200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728844800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728845400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728846000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728846600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728847200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728847800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728848400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728849000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728849600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728850200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728850800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728851400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728852000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728852600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728853200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728853800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728854400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728855000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728855600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728856200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728856800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728857400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728858000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728858600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728859200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728859800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728860400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728861000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728861600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728862200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728862800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728863400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728864000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728864600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728865200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728865800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728866400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728867000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728867600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728868200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728868800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728869400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728870000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728870600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728871200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728871800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728872400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728873000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728873600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728874200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728874800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728875400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728876000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728876600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728877200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728877800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728878400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728879000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728879600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728880200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728880800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728881400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728882000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728882600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728883200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728883800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728884400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728885000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728885600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728886200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728886800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728887400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728888000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728888600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728889200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728889800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728890400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728891000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728891600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728892200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728892800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728893400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728894000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728894600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728895200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728895800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728896400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728897000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728897600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728898200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728898800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728899400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728900000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728900600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728901200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728901800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728902400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728903000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728903600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728904200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728904800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728905400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728906000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728906600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728907200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728907800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728908400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728909000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728909600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728910200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728910800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728911400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728912000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728912600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728913200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728913800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728914400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728915000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728915600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728916200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728916800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728917400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728918000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728918600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728919200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728919800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728920400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728921000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728921600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728922200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728922800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728923400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728924000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728924600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728925200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728925800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728926400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728927000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728927600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728928200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728928800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728929400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728930000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728930600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728931200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728931800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728932400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728933000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728933600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728934200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728934800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728935400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728936000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728936600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728937200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728937800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728938400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728939000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728939600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728940200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728940800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728941400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728942000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728942600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728943200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728943800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728944400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728945000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728945600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728946200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728946800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728947400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728948000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728948600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728949200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728949800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728950400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728951000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728951600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728952200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728952800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728953400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728954000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728954600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728955200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728955800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728956400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728957000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728957600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728958200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728958800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728959400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728960000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728960600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728961200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728961800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728962400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728963000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728963600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728964200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728964800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728965400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728966000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728966600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728967200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728967800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728968400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728969000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728969600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728970200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728970800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728971400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728972000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728972600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728973200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728973800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728974400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728975000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728975600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728976200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728976800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728977400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728978000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728978600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728979200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728979800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728980400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728981000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728981600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728982200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728982800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728983400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728984000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728984600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728985200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728985800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728986400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728987000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728987600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728988200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728988800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728989400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728990000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728990600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728991200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728991800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728992400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728993000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728993600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728994200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728994800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728995400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728996000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728996600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728997200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728997800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728998400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728999000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1728999600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729000200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729000800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729001400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729002000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729002600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729003200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729003800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729004400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729005000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729005600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729006200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729006800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729007400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729008000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729008600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729009200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729009800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729010400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729011000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729011600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729012200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729012800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729013400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729014000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729014600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729015200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729015800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729016400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729017000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729017600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729018200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729018800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729019400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729020000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729020600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729021200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729021800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729022400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729023000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729023600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729024200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729024800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729025400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729026000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729026600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729027200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729027800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729028400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729029000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729029600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729030200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729030800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729031400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729032000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729032600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729033200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729033800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729034400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729035000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729035600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729036200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729036800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729037400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729038000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729038600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729039200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729039800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729040400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729041000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729041600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729042200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729042800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729043400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729044000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729044600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729045200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729045800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729046400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729047000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729047600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729048200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729048800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729049400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729050000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729050600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729051200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729051800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729052400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729053000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729053600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729054200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729054800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729055400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729056000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729056600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729057200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729057800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729058400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729059000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729059600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729060200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729060800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729061400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729062000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729062600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729063200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729063800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729064400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729065000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729065600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729066200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729066800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729067400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729068000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729068600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729069200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729069800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729070400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729071000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729071600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729072200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729072800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729073400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729074000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729074600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729075200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729075800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729076400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729077000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729077600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729078200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729078800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729079400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729080000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729080600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729081200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729081800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729082400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729083000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729083600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729084200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729084800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729085400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729086000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729086600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729087200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729087800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729088400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729089000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729089600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729090200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729090800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729091400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729092000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729092600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729093200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729093800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729094400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729095000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729095600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729096200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729096800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729097400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729098000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729098600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729099200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729099800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729100400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729101000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729101600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729102200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729102800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729103400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729104000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729104600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729105200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729105800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729106400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729107000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729107600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729108200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729108800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729109400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729110000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729110600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729111200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729111800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729112400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729113000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729113600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729114200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729114800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729115400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729116000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729116600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729117200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729117800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729118400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729119000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729119600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729120200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729120800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729121400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729122000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729122600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729123200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729123800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729124400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729125000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729125600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729126200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729126800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729127400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729128000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729128600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729129200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729129800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729130400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729131000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729131600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729132200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729132800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729133400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729134000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729134600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729135200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729135800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729136400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729137000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729137600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729138200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729138800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729139400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729140000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729140600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729141200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729141800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729142400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729143000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729143600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729144200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729144800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729145400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729146000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729146600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729147200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729147800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729148400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729149000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729149600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729150200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729150800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729151400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729152000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729152600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729153200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729153800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729154400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729155000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729155600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729156200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729156800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729157400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729158000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729158600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729159200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729159800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729160400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729161000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729161600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729162200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729162800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729163400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729164000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729164600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729165200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729165800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729166400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729167000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729167600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729168200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729168800000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729169400000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729170000000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729170600000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729171200000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729171800000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729172400000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729173000000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729173600000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729174200000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729174800000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729175400000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729176000000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729176600000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729177200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729177800000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729178400000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729179000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729179600000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729180200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729180800000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729181400000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729182000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729182600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729183200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729183800000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729184400000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729185000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729185600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729186200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729186800000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729187400000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729188000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729188600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729189200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729189800000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729190400000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729191000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729191600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729192200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729192800000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729193400000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729194000000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729194600000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729195200000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729195800000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729196400000,
          "y": 101.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729197000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729197600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729198200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729198800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729199400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729200000000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729200600000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729201200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729201800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        },
        {
          "x": 1729202400000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 40 cm",
          "sensorDepth": 40,
          "deviceId": 10045,
          "sensorId": 13,
          "additionalInfo": null
        }
      ],
      "60": [
        {
          "x": 1728639000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728639600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728640200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728640800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728641400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728642000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728642600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728643200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728643800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728644400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728645000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728645600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728646200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728646800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728647400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728648000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728648600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728649200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728649800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728650400000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728651000000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728651600000,
          "y": 130,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728652200000,
          "y": 135.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728652800000,
          "y": 138.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728653400000,
          "y": 140,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728654000000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728654600000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728655200000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728655800000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728656400000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728657000000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728657600000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728658200000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728658800000,
          "y": 142.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728659400000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728660000000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728660600000,
          "y": 141.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728661200000,
          "y": 138.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728661800000,
          "y": 134.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728662400000,
          "y": 128.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728663000000,
          "y": 120,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728663600000,
          "y": 115.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728664200000,
          "y": 114.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728664800000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728665400000,
          "y": 111.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728666000000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728666600000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728667200000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728667800000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728668400000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728669000000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728669600000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728670200000,
          "y": 104.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728670800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728671400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728672000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728672600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728673200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728673800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728674400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728675000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728675600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728676200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728676800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728677400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728678000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728678600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728679200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728679800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728680400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728681000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728681600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728682200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728682800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728683400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728684000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728684600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728685200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728685800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728686400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728687000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728687600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728688200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728688800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728689400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728690000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728690600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728691200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728691800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728692400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728693000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728693600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728694200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728694800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728695400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728696000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728696600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728697200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728697800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728698400000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728699000000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728699600000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728700200000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728700800000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728701400000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728702000000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728702600000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728703200000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728703800000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728704400000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728705000000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728705600000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728706200000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728706800000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728707400000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728708000000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728708600000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728709200000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728709800000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728710400000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728711000000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728711600000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728712200000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728712800000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728713400000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728714000000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728714600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728715200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728715800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728716400000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728717000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728717600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728718200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728718800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728719400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728720000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728720600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728721200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728721800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728722400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728723000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728723600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728724200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728724800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728725400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728726000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728726600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728727200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728727800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728728400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728729000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728729600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728730200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728730800000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728731400000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728732000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728732600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728733200000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728733800000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728734400000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728735000000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728735600000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728736200000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728736800000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728737400000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728738000000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728738600000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728739200000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728739800000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728740400000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728741000000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728741600000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728742200000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728742800000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728743400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728744000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728744600000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728745200000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728745800000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728746400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728747000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728747600000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728748200000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728748800000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728749400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728750000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728750600000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728751200000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728751800000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728752400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728753000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728753600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728754200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728754800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728755400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728756000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728756600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728757200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728757800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728758400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728759000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728759600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728760200000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728760800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728761400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728762000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728762600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728763200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728763800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728764400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728765000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728765600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728766200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728766800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728767400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728768000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728768600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728769200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728769800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728770400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728771000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728771600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728772200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728772800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728773400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728774000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728774600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728775200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728775800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728776400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728777000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728777600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728778200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728778800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728779400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728780000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728780600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728781200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728781800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728782400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728783000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728783600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728784200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728784800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728785400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728786000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728786600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728787200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728787800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728788400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728789000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728789600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728790200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728790800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728791400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728792000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728792600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728793200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728793800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728794400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728795000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728795600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728796200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728796800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728797400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728798000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728798600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728799200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728799800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728800400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728801000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728801600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728802200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728802800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728803400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728804000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728804600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728805200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728805800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728806400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728807000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728807600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728808200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728808800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728809400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728810000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728810600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728811200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728811800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728812400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728813000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728813600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728814200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728814800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728815400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728816000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728816600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728817200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728817800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728818400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728819000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728819600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728820200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728820800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728821400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728822000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728822600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728823200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728823800000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728824400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728825000000,
          "y": 104.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728825600000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728826200000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728826800000,
          "y": 115.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728827400000,
          "y": 117.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728828000000,
          "y": 117.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728828600000,
          "y": 117.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728829200000,
          "y": 115.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728829800000,
          "y": 115.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728830400000,
          "y": 115.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728831000000,
          "y": 114.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728831600000,
          "y": 114.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728832200000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728832800000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728833400000,
          "y": 111.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728834000000,
          "y": 111.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728834600000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728835200000,
          "y": 110,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728835800000,
          "y": 108.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728836400000,
          "y": 108.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728837000000,
          "y": 108.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728837600000,
          "y": 107.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728838200000,
          "y": 107.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728838800000,
          "y": 107.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728839400000,
          "y": 107.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728840000000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728840600000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728841200000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728841800000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728842400000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728843000000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728843600000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728844200000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728844800000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728845400000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728846000000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728846600000,
          "y": 102.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728847200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728847800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728848400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728849000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728849600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728850200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728850800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728851400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728852000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728852600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728853200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728853800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728854400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728855000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728855600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728856200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728856800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728857400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728858000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728858600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728859200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728859800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728860400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728861000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728861600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728862200000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728862800000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728863400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728864000000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728864600000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728865200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728865800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728866400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728867000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728867600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728868200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728868800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728869400000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728870000000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728870600000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728871200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728871800000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728872400000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728873000000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728873600000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728874200000,
          "y": 90,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728874800000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728875400000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728876000000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728876600000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728877200000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728877800000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728878400000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728879000000,
          "y": 88.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728879600000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728880200000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728880800000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728881400000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728882000000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728882600000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728883200000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728883800000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728884400000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728885000000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728885600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728886200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728886800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728887400000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728888000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728888600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728889200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728889800000,
          "y": 87.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728890400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728891000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728891600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728892200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728892800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728893400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728894000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728894600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728895200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728895800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728896400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728897000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728897600000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728898200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728898800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728899400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728900000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728900600000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728901200000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728901800000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728902400000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728903000000,
          "y": 85.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728903600000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728904200000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728904800000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728905400000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728906000000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728906600000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728907200000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728907800000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728908400000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728909000000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728909600000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728910200000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728910800000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728911400000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728912000000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728912600000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728913200000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728913800000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728914400000,
          "y": 84.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728915000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728915600000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728916200000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728916800000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728917400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728918000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728918600000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728919200000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728919800000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728920400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728921000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728921600000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728922200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728922800000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728923400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728924000000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728924600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728925200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728925800000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728926400000,
          "y": 82.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728927000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728927600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728928200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728928800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728929400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728930000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728930600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728931200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728931800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728932400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728933000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728933600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728934200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728934800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728935400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728936000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728936600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728937200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728937800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728938400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728939000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728939600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728940200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728940800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728941400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728942000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728942600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728943200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728943800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728944400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728945000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728945600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728946200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728946800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728947400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728948000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728948600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728949200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728949800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728950400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728951000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728951600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728952200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728952800000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728953400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728954000000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728954600000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728955200000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728955800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728956400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728957000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728957600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728958200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728958800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728959400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728960000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728960600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728961200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728961800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728962400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728963000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728963600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728964200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728964800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728965400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728966000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728966600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728967200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728967800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728968400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728969000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728969600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728970200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728970800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728971400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728972000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728972600000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728973200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728973800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728974400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728975000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728975600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728976200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728976800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728977400000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728978000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728978600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728979200000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728979800000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728980400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728981000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728981600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728982200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728982800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728983400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728984000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728984600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728985200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728985800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728986400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728987000000,
          "y": 80,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728987600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728988200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728988800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728989400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728990000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728990600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728991200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728991800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728992400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728993000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728993600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728994200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728994800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728995400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728996000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728996600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728997200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728997800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728998400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728999000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1728999600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729000200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729000800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729001400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729002000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729002600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729003200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729003800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729004400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729005000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729005600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729006200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729006800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729007400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729008000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729008600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729009200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729009800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729010400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729011000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729011600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729012200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729012800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729013400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729014000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729014600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729015200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729015800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729016400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729017000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729017600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729018200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729018800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729019400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729020000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729020600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729021200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729021800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729022400000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729023000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729023600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729024200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729024800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729025400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729026000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729026600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729027200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729027800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729028400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729029000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729029600000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729030200000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729030800000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729031400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729032000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729032600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729033200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729033800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729034400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729035000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729035600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729036200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729036800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729037400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729038000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729038600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729039200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729039800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729040400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729041000000,
          "y": 78.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729041600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729042200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729042800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729043400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729044000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729044600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729045200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729045800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729046400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729047000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729047600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729048200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729048800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729049400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729050000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729050600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729051200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729051800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729052400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729053000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729053600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729054200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729054800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729055400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729056000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729056600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729057200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729057800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729058400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729059000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729059600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729060200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729060800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729061400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729062000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729062600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729063200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729063800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729064400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729065000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729065600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729066200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729066800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729067400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729068000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729068600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729069200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729069800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729070400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729071000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729071600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729072200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729072800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729073400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729074000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729074600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729075200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729075800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729076400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729077000000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729077600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729078200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729078800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729079400000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729080000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729080600000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729081200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729081800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729082400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729083000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729083600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729084200000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729084800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729085400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729086000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729086600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729087200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729087800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729088400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729089000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729089600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729090200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729090800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729091400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729092000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729092600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729093200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729093800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729094400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729095000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729095600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729096200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729096800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729097400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729098000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729098600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729099200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729099800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729100400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729101000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729101600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729102200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729102800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729103400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729104000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729104600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729105200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729105800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729106400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729107000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729107600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729108200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729108800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729109400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729110000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729110600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729111200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729111800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729112400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729113000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729113600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729114200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729114800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729115400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729116000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729116600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729117200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729117800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729118400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729119000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729119600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729120200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729120800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729121400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729122000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729122600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729123200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729123800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729124400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729125000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729125600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729126200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729126800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729127400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729128000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729128600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729129200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729129800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729130400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729131000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729131600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729132200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729132800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729133400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729134000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729134600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729135200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729135800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729136400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729137000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729137600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729138200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729138800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729139400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729140000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729140600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729141200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729141800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729142400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729143000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729143600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729144200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729144800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729145400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729146000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729146600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729147200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729147800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729148400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729149000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729149600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729150200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729150800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729151400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729152000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729152600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729153200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729153800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729154400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729155000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729155600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729156200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729156800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729157400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729158000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729158600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729159200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729159800000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729160400000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729161000000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729161600000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729162200000,
          "y": 74.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729162800000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729163400000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729164000000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729164600000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729165200000,
          "y": 75.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729165800000,
          "y": 77.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729166400000,
          "y": 81.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729167000000,
          "y": 108.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729167600000,
          "y": 132.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729168200000,
          "y": 140,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729168800000,
          "y": 142.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729169400000,
          "y": 142.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729170000000,
          "y": 144.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729170600000,
          "y": 144.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729171200000,
          "y": 142.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729171800000,
          "y": 140,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729172400000,
          "y": 132.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729173000000,
          "y": 121.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729173600000,
          "y": 115.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729174200000,
          "y": 114.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729174800000,
          "y": 114.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729175400000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729176000000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729176600000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729177200000,
          "y": 112.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729177800000,
          "y": 111.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729178400000,
          "y": 111.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729179000000,
          "y": 111.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729179600000,
          "y": 105.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729180200000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729180800000,
          "y": 100,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729181400000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729182000000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729182600000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729183200000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729183800000,
          "y": 98.57,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729184400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729185000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729185600000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729186200000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729186800000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729187400000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729188000000,
          "y": 97.14,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729188600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729189200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729189800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729190400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729191000000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729191600000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729192200000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729192800000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729193400000,
          "y": 95.71,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729194000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729194600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729195200000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729195800000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729196400000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729197000000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729197600000,
          "y": 94.29,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729198200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729198800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729199400000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729200000000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729200600000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729201200000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729201800000,
          "y": 92.86,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        },
        {
          "x": 1729202400000,
          "y": 91.43,
          "unit": "PERCENT",
          "symbol": "%",
          "name": "VWC 60 cm",
          "sensorDepth": 60,
          "deviceId": 10045,
          "sensorId": 16,
          "additionalInfo": null
        }
      ],
      "IRRIGATION_PULSES": [
        {
          "x": 1728646799000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728646800000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728646801000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728647999000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728648000000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728648001000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728649199000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728649200000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728649201000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728650399000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728650400000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728650401000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728651599000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728651600000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728652200000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728652800000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728653400000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728653401000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728654599000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728654600000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728655200000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728655201000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728656399000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728656400000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728656401000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728657599000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728657600000,
          "y": 0.23,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728657601000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728658799000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728658800000,
          "y": 0.23,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728658801000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728818399000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728818400000,
          "y": 0.25,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728818401000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728819599000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728819600000,
          "y": 0.23,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728819601000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728820799000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728820800000,
          "y": 0.23,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728820801000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728821999000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728822000000,
          "y": 0.23,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728822001000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728823199000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728823200000,
          "y": 0.23,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728823201000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729164599000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729164600000,
          "y": 0.23,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729165200000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729165201000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729166999000,
          "y": 0,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729167000000,
          "y": 0.23,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729167600000,
          "y": 0.22,
          "unit": "MILLIMETERS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        }
      ],
      "RAIN_GAUGE": [
        {
          "x": 1729160999000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729161000000,
          "y": 12,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729161600000,
          "y": 0.4,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729162200000,
          "y": 0.4,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729162800000,
          "y": 0.4,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729163400000,
          "y": 0.4,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729164000000,
          "y": 0.2,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729164001000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729169399000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729169400000,
          "y": 0.2,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729169401000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729181999000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729182000000,
          "y": 0.2,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729182001000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729186199000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729186200000,
          "y": 0.2,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        },
        {
          "x": 1729186201000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729201799000,
          "y": 0,
          "unit": "MILLIMETERS_PER_HOUR",
          "symbol": null,
          "name": null,
          "sensorDepth": null,
          "deviceId": null,
          "sensorId": null,
          "additionalInfo": null
        },
        {
          "x": 1729201800000,
          "y": 0.2,
          "unit": "LITERS_PER_SQUARE_METER",
          "symbol": null,
          "name": "Pluviom.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 3,
          "additionalInfo": null
        }
      ],
      "ACCUMULATED_IRRIGATION": [
        {
          "x": 1728646800000,
          "y": 2.2,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728648000000,
          "y": 4.4,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728649200000,
          "y": 6.6,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728650400000,
          "y": 8.8,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728651600000,
          "y": 11,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728652200000,
          "y": 13.2,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728652800000,
          "y": 15.4,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728653400000,
          "y": 17.6,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728654600000,
          "y": 19.8,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728655200000,
          "y": 22,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728656400000,
          "y": 24.2,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728657600000,
          "y": 26.5,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728658800000,
          "y": 28.8,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728818400000,
          "y": 31.3,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728819600000,
          "y": 33.6,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728820800000,
          "y": 35.9,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728822000000,
          "y": 38.2,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728823200000,
          "y": 40.5,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729164600000,
          "y": 42.8,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729165200000,
          "y": 45,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729167000000,
          "y": 47.3,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729167600000,
          "y": 49.5,
          "unit": "CUBIC_METERS_HA",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        }
      ],
      "ACCUMULATED_IRRIGATION_TIME": [
        {
          "x": 1728646800000,
          "y": 600,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728648000000,
          "y": 1200,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728649200000,
          "y": 1800,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728650400000,
          "y": 2400,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728651600000,
          "y": 3000,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728652200000,
          "y": 3600,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728652800000,
          "y": 4200,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728653400000,
          "y": 4800,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728654600000,
          "y": 5400,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728655200000,
          "y": 6000,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728656400000,
          "y": 6600,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728657600000,
          "y": 7200,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728658800000,
          "y": 7800,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728818400000,
          "y": 8400,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728819600000,
          "y": 9000,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728820800000,
          "y": 9600,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728822000000,
          "y": 10200,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1728823200000,
          "y": 10800,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729164600000,
          "y": 11400,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729165200000,
          "y": 12000,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729167000000,
          "y": 12600,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        },
        {
          "x": 1729167600000,
          "y": 13200,
          "unit": "SECONDS",
          "symbol": null,
          "name": "Cont.",
          "sensorDepth": null,
          "deviceId": 10045,
          "sensorId": 2,
          "additionalInfo": null
        }
      ]
    }
  }

export const MOCK_CAMPAIGN_IRRIGATION = {
    "id": 1,
    "grouping": null,
    "lastIrrigation": null,
    "series": {
        "CAMPAIGN_IRRIGATION": [
            {
                "x": 1735686000000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1740783600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1743458400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1746050400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1748728800000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1751320800000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1753999200000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1756677600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1759269600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1761951600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1764543600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            }
        ],
        "CAMPAIGN_IRRIGATION_ACCUMULATED": [
            {
                "x": 1735686000000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1738364400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1740783600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1743458400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1746050400000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1748728800000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1751320800000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1753999200000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1756677600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1759269600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1761951600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            },
            {
                "x": 1764543600000,
                "y": 0,
                "unit": "CUBIC_METERS_HA",
                "name": null,
                "additionalInfo": null
            }
        ]
    }
}

export const MOCK_NUTRI_DYNAMICS = {
    "id": 2,
    "grouping": "TEN_MINUTES",
    "series": {
      "LINE_ELECTRICAL_CONDUCTIVITY": {
        "0": [
          {
            "x": 1728638400000,
            "y": 0.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639000000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728641400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728644400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728647400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728650400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728653400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728656400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728659400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728662400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728665400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728668400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728671400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728674400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728677400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728680400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728683400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728686400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728689400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728692400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728695400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728698400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728701400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728704400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728707400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728710400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728713400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728716400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728719400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728722400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728725400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728728400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728731400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728734400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728737400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728740400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728743400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728746400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728749400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728752400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728755400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728758400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728761400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728764400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728767400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728770400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728773400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728776400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728779400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728782400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728785400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728788400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728791400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728794400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728797400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728800400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728803400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728806400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728809400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728812400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728815400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728818400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728821400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728824400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728827400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728830400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728833400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728836400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728839400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728842400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728845400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728848400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728851400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728854400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728857400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728860400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728863400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728866400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728869400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728872400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728875400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728878400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728881400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728884400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728887400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728890400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728893400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728896400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728899400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728902400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728905400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728908400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728911400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728914400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728917400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728920400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728923400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728926400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728929400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728932400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728935400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728938400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728941400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728944400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728947400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728950400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728953400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728956400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728959400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728962400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728965400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728968400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728971400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728974400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975600000,
            "y": 0.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728977400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728980400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728983400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728986400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728989400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728992400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728995400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728998400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729001400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729004400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729007400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729010400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729013400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729016400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729019400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729022400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729025400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729028400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729031400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729034400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729037400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729040400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729043400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729046400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048200000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729049400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729052400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729055400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729058400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729061400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729064400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729067400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729070400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729073400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729076400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729079400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729082400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729085400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729088400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729091400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729094400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729097400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729100400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729103400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729106400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729109400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110000000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729112400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729115400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729118400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729121400000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729124400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729127400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729130400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131600000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729133400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729136400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138800000,
            "y": 0.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729139400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729142400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729145400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729148400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729151400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729154400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729157400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729160400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729163400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729166400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168200000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168800000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169400000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170000000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170600000,
            "y": 0.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729172400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729175400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729178400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729181400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729184400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186200000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186800000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729187400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188600000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729190400000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191000000,
            "y": 0.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729193400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729196400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729199400000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200000000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200600000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201200000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201800000,
            "y": 0.6,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          }
        ]
      },
      "TEMPERATURE": {
        "20": [
          {
            "x": 1728638400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728641400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728644400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728647400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728650400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728653400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655200000,
            "y": 17.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655800000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728656400000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728659400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728662400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728665400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728668400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728671400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728674400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728677400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678600000,
            "y": 17.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728680400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728683400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728686400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687600000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688200000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688800000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728689400000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690000000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690600000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691200000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691800000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728692400000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693000000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693600000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694200000,
            "y": 17.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694800000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728695400000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696000000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696600000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697200000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697800000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728698400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728701400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728704400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728707400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728710400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728713400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728716400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717000000,
            "y": 16.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728719400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728722400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728725400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728728400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730200000,
            "y": 16.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728731400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728734400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728737400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728740400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728743400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744000000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744600000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745200000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745800000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728746400000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747000000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747600000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748200000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748800000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728749400000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750000000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750600000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728752400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728755400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728758400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728761400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728764400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728767400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728770400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772800000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728773400000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774000000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774600000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775200000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775800000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728776400000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777000000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777600000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778200000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778800000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728779400000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780000000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780600000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781200000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781800000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728782400000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783000000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783600000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784200000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728785400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728788400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728791400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728794400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728797400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728800400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728803400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728806400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728809400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728812400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814200000,
            "y": 16.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728815400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728818400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728821400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728824400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825600000,
            "y": 17.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826200000,
            "y": 17.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826800000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728827400000,
            "y": 17.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828000000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828600000,
            "y": 17.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728830400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832200000,
            "y": 17.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728833400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728836400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728839400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840000000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840600000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841200000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841800000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728842400000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728845400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728848400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728851400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728854400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855000000,
            "y": 18.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855600000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856200000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856800000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728857400000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858000000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858600000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859200000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859800000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728860400000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861000000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728863400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728866400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728869400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728872400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728875400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728878400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728881400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728884400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728887400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889200000,
            "y": 17.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728890400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728893400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728896400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728899400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900000000,
            "y": 17.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728902400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728905400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728908400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728911400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913200000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913800000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728914400000,
            "y": 18.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728917400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728920400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728923400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925200000,
            "y": 18.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728926400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728929400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728932400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728935400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728938400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728941400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728944400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728947400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728950400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728953400000,
            "y": 18.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728956400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728959400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728962400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728965400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728968400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728971400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728974400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728977400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728980400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728983400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728986400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728989400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728992400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728995400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728998400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729001400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729004400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729007400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729010400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729013400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729016400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729019400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729022400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729025400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729028400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030200000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030800000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729031400000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032000000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032600000,
            "y": 19,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729034400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729037400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729040400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729043400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729046400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729049400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729052400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729055400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729058400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729061400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729064400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729067400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729070400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729073400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729076400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729079400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081800000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729082400000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083000000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729085400000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729088400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729091400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093200000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093800000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729094400000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095000000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095600000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096200000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096800000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729097400000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098000000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098600000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099200000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099800000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729100400000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101000000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101600000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102200000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102800000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729103400000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104000000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104600000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105200000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105800000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729106400000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107000000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107600000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108200000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108800000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729109400000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110000000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110600000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111200000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111800000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729112400000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113000000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113600000,
            "y": 23.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114200000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114800000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729115400000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116000000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116600000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117200000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117800000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729118400000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119000000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119600000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120200000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120800000,
            "y": 23.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729121400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729124400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729127400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729130400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729133400000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135200000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135800000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729136400000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137000000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137600000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138200000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729139400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729142400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143000000,
            "y": 22.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143600000,
            "y": 22.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144200000,
            "y": 22.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144800000,
            "y": 22.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729145400000,
            "y": 22.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146000000,
            "y": 22.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146600000,
            "y": 22.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147200000,
            "y": 22.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147800000,
            "y": 22.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729148400000,
            "y": 22.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149000000,
            "y": 22.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149600000,
            "y": 22.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150200000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150800000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729151400000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152000000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152600000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153200000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153800000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729154400000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155000000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155600000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156200000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156800000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729157400000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158000000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158600000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159200000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159800000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729160400000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161000000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161600000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162200000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162800000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729163400000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164000000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164600000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165200000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165800000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729166400000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167000000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167600000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168200000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168800000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169400000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170000000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170600000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171200000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171800000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729172400000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173000000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173600000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174200000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174800000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729175400000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176000000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176600000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177200000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177800000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729178400000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179000000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179600000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180200000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180800000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729181400000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182000000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182600000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183200000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183800000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729184400000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185000000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185600000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186200000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186800000,
            "y": 22.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729187400000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188000000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188600000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189200000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189800000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729190400000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191000000,
            "y": 22.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191600000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192200000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192800000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729193400000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194000000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194600000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195200000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195800000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729196400000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197000000,
            "y": 22.2,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197600000,
            "y": 22.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198200000,
            "y": 22.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198800000,
            "y": 22.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729199400000,
            "y": 22.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200000000,
            "y": 22,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200600000,
            "y": 22,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201200000,
            "y": 22,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201800000,
            "y": 22,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          }
        ],
        "40": [
          {
            "x": 1728638400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728641400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728644400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728647400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728650400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652200000,
            "y": 17.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728653400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728656400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728659400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728662400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728665400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728668400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728671400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728674400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728677400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728680400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728683400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728686400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728689400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728692400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728695400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728698400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728701400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728704400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728707400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728710400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728713400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728716400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728719400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728722400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728725400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728728400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730200000,
            "y": 17.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728731400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728734400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728737400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728740400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728743400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728746400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728749400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728752400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728755400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728758400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728761400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728764400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728767400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728770400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728773400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728776400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728779400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728782400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728785400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728788400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728791400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728794400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728797400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728800400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728803400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805200000,
            "y": 17.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728806400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728809400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728812400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728815400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728818400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728821400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728824400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728827400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728830400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831000000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831600000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832200000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832800000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728833400000,
            "y": 17.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728836400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837000000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837600000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838200000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838800000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728839400000,
            "y": 17.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840000000,
            "y": 17.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728842400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843000000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843600000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844200000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844800000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728845400000,
            "y": 17.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728848400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849000000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849600000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850200000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850800000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728851400000,
            "y": 17.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728854400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728857400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728860400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728863400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728866400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728869400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728872400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728875400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728878400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728881400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728884400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728887400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728890400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728893400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728896400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728899400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728902400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728905400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728908400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728911400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728914400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915600000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916200000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916800000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728917400000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918000000,
            "y": 18,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918600000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919200000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919800000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728920400000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921000000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921600000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922200000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922800000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728923400000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924000000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924600000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925200000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925800000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728926400000,
            "y": 18.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728929400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931200000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931800000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728932400000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933000000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933600000,
            "y": 18.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728935400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728938400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939000000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939600000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940200000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940800000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728941400000,
            "y": 18.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728944400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728947400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728950400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728953400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728956400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728959400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728962400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728965400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728968400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728971400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728974400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728977400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728980400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728983400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728986400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728989400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728992400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728995400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728998400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729001400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729004400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006800000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729007400000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008000000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008600000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009200000,
            "y": 18.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729010400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729013400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729016400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729019400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020000000,
            "y": 18.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729022400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729025400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729028400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729031400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729034400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729037400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729040400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729043400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044000000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044600000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045200000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045800000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729046400000,
            "y": 18.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729049400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729052400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729055400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729058400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729061400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729064400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729067400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069200000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069800000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729070400000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071000000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071600000,
            "y": 18.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729073400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729076400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078800000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729079400000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080000000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080600000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081200000,
            "y": 18.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081800000,
            "y": 22.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729082400000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083000000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083600000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084200000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084800000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729085400000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086000000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086600000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087200000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087800000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729088400000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089000000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089600000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090200000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090800000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729091400000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729094400000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729097400000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729100400000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729103400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729106400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729109400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729112400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729115400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729118400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729121400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729124400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729127400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729130400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729133400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729136400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729139400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729142400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729145400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729148400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729151400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729154400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155000000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155600000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156200000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156800000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729157400000,
            "y": 23.1,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729160400000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729163400000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164600000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165200000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165800000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729166400000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167000000,
            "y": 23,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167600000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168200000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168800000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169400000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170000000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170600000,
            "y": 22.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729172400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729175400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729178400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729181400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729184400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729187400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729190400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729193400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729196400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729199400000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200000000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200600000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201200000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201800000,
            "y": 22.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          }
        ],
        "60": [
          {
            "x": 1728638400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728641400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728644400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728647400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728650400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728653400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654600000,
            "y": 16.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728656400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728659400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728662400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728665400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728668400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728671400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728674400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728677400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728680400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728683400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728686400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728689400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728692400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728695400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728698400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728701400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728704400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728707400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728710400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728713400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728716400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728719400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728722400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728725400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728728400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728731400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728734400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728737400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728740400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728743400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728746400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728749400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728752400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728755400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728758400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728761400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728764400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728767400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728770400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728773400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728776400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728779400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728782400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728785400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728788400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728791400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728794400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728797400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728800400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728803400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728806400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728809400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728812400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728815400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728818400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728821400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728824400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728827400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728830400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728833400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728836400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728839400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728842400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728845400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728848400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728851400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852000000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852600000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853200000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853800000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728854400000,
            "y": 16.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728857400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728860400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728863400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728866400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728869400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728872400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728875400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728878400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728881400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728884400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728887400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728890400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728893400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728896400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728899400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728902400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728905400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728908400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728911400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728914400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728917400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728920400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728923400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728926400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728929400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728932400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728935400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937200000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937800000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728938400000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939000000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939600000,
            "y": 16.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728941400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728944400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728947400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728950400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728953400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728956400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958800000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728959400000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960000000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960600000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961200000,
            "y": 16.6,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961800000,
            "y": 16.7,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728962400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728965400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728968400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728971400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728974400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728977400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728980400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728983400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728986400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987600000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988200000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988800000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728989400000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990000000,
            "y": 16.8,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728992400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728995400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728998400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729001400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729004400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729007400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729010400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729013400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729016400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729019400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729022400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729025400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729028400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729031400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032600000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033200000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033800000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729034400000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035000000,
            "y": 16.9,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729037400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729040400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729043400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729046400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729049400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729052400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729055400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729058400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729061400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729064400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729067400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729070400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729073400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729076400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078800000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729079400000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080000000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080600000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081200000,
            "y": 17,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081800000,
            "y": 21,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729082400000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083000000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083600000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084200000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084800000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729085400000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086000000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086600000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087200000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087800000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729088400000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089000000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089600000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090200000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090800000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729091400000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092000000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092600000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093200000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093800000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729094400000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095000000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095600000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096200000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096800000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729097400000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098000000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098600000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099200000,
            "y": 21.3,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729100400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729103400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729106400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729109400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729112400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729115400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729118400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729121400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729124400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729127400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729130400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729133400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729136400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729139400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729142400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729145400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729148400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729151400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729154400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729157400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729160400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729163400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729166400000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167000000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167600000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168200000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168800000,
            "y": 21.5,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729172400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729175400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729178400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729181400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729184400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729187400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729190400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729193400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729196400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729199400000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200000000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200600000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201200000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201800000,
            "y": 21.4,
            "unit": "DEGREES_CELSIUS",
            "symbol": "ºC",
            "name": null,
            "additionalInfo": null
          }
        ]
      },
      "RAIN_GAUGE": {
        "0": [
          {
            "x": 1729160999000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161000000,
            "y": 12,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161600000,
            "y": 0.4,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162200000,
            "y": 0.4,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162800000,
            "y": 0.4,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729163400000,
            "y": 0.4,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164000000,
            "y": 0.2,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164001000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169399000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169400000,
            "y": 0.2,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169401000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729181999000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182000000,
            "y": 0.2,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182001000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186199000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186200000,
            "y": 0.2,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186201000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201799000,
            "y": 0,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201800000,
            "y": 0.2,
            "unit": "LITERS_PER_SQUARE_METER",
            "symbol": null,
            "name": null,
            "additionalInfo": null
          }
        ]
      },
      "SOIL_ELECTRICAL_CONDUCTIVITY": {
        "20": [
          {
            "x": 1728638400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728641400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728644400000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645000000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728647400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648000000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648600000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649200000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649800000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728650400000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651000000,
            "y": 3.43,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651600000,
            "y": 3.42,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652200000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728653400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654000000,
            "y": 3.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728656400000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657000000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657600000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658200000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728659400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661200000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661800000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728662400000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663000000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663600000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664200000,
            "y": 3.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664800000,
            "y": 3.39,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728665400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666000000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667800000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728668400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669000000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669600000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670200000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670800000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728671400000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672000000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672600000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673800000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728674400000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675000000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675600000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676800000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728677400000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678600000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728680400000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728683400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728686400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688200000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728689400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728692400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693000000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728695400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728698400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728701400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728704400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728707400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708000000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708600000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728710400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728713400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728716400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717000000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728719400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728722400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728725400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728728400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728731400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728734400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728737400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728740400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728743400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728746400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728749400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728752400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728755400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728758400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760200000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728761400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728764400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728767400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768600000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728770400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728773400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728776400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728779400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728782400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728785400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728788400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728791400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728794400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796200000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728797400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728800400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728803400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805200000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805800000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728806400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808800000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728809400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728812400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728815400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728818400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819600000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728821400000,
            "y": 3.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822600000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823200000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823800000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728824400000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825600000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826800000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728827400000,
            "y": 3.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828000000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828600000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829200000,
            "y": 3.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728830400000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831000000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831600000,
            "y": 3.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832200000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832800000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728833400000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834000000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834600000,
            "y": 3.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835200000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728836400000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837000000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837600000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838200000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838800000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728839400000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840000000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840600000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841200000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728842400000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843000000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843600000,
            "y": 3.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844200000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844800000,
            "y": 3.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728845400000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846000000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846600000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847200000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847800000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728848400000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849000000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849600000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850800000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728851400000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852000000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852600000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853800000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728854400000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855000000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855600000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856200000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856800000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728857400000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859200000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728860400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728863400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864600000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728866400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868200000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728869400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728872400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728875400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728878400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728881400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883200000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728884400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728887400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728890400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728893400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728896400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728899400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728902400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904200000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728905400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907200000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728908400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728911400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912000000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913800000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728914400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915000000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915600000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728917400000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918600000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728920400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728923400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924600000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728926400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728929400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728932400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728935400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728938400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728941400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728944400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728947400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728950400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728953400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728956400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728959400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728962400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728965400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728968400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728971400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728974400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728977400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728980400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981000000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728983400000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728986400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728989400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728992400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728995400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728998400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729001400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729004400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729007400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729010400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729013400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729016400000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729019400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729022400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729025400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729028400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029000000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030200000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729031400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729034400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729037400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729040400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729043400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729046400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048200000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729049400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050600000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729052400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729055400000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056000000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057200000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729058400000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059000000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729061400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729064400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065000000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066200000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066800000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729067400000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729070400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729073400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729076400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729079400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729082400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084800000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729085400000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087200000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087800000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729088400000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090200000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729091400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093800000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729094400000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096800000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729097400000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098000000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098600000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099200000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099800000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729100400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101000000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101600000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102200000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102800000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729103400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104000000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104600000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105200000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105800000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729106400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107000000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107600000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108200000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108800000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729109400000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110000000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110600000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111200000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111800000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729112400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113000000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113600000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114200000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114800000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729115400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116000000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116600000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117200000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117800000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729118400000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119000000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119600000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120200000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120800000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729121400000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122000000,
            "y": 3.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122600000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123200000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123800000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729124400000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125000000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125600000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126200000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126800000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729127400000,
            "y": 3.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128000000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129200000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129800000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729130400000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131000000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131600000,
            "y": 3.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132200000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132800000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729133400000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134000000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134600000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135200000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135800000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729136400000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137000000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137600000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138200000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138800000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729139400000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140000000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140600000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141200000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141800000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729142400000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143000000,
            "y": 3.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144200000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144800000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729145400000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146000000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146600000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147200000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147800000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729148400000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149600000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150200000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150800000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729151400000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152600000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153200000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153800000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729154400000,
            "y": 3.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155600000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156200000,
            "y": 3.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156800000,
            "y": 3.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729157400000,
            "y": 3.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158600000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159200000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159800000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729160400000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161000000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161600000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162200000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162800000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729163400000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164000000,
            "y": 3.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164600000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165200000,
            "y": 3.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165800000,
            "y": 3.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729166400000,
            "y": 3.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170000000,
            "y": 3.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170600000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171800000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729172400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173600000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729175400000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176000000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176600000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177200000,
            "y": 3.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177800000,
            "y": 3.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729178400000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179000000,
            "y": 3.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179600000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180200000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180800000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729181400000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182000000,
            "y": 3.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182600000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183200000,
            "y": 3.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183800000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729184400000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185000000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185600000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186200000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186800000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729187400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188000000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188600000,
            "y": 3.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189200000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189800000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729190400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191000000,
            "y": 3.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191600000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192200000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192800000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729193400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194000000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194600000,
            "y": 3.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195200000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195800000,
            "y": 3.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729196400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197000000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197600000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198200000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198800000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729199400000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200000000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200600000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201200000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201800000,
            "y": 3.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          }
        ],
        "40": [
          {
            "x": 1728638400000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639000000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640200000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640800000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728641400000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642600000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643200000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643800000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728644400000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645000000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645600000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646200000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646800000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728647400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649200000,
            "y": 4.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649800000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728650400000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651600000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652200000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652800000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728653400000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654000000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654600000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655200000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655800000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728656400000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657000000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657600000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658200000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658800000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728659400000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660000000,
            "y": 4.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660600000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661200000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661800000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728662400000,
            "y": 4.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663000000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663600000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664200000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664800000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728665400000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666000000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728668400000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669600000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728671400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728674400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728677400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728680400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682200000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728683400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728686400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688200000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728689400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728692400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693000000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694800000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728695400000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697200000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728698400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728701400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728704400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705000000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705600000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728707400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709200000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709800000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728710400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712800000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728713400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715200000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728716400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718800000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728719400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728722400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728725400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728728400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729000000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729600000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730800000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728731400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728734400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736200000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728737400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728740400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741000000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742800000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728743400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728746400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748200000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728749400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728752400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728755400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728758400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728761400000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728764400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728767400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768600000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769200000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728770400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728773400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728776400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728779400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728782400000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783000000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784800000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728785400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728788400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728791400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792000000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728794400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728797400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728800400000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728803400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728806400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807600000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728809400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728812400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728815400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728818400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820800000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728821400000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822000000,
            "y": 4.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822600000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823200000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823800000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728824400000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825000000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825600000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826200000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826800000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728827400000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828000000,
            "y": 4.06,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828600000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829200000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829800000,
            "y": 4.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728830400000,
            "y": 4.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831600000,
            "y": 4.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728833400000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728836400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728839400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841800000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728842400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728845400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846600000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847200000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847800000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728848400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849600000,
            "y": 4.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728851400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853800000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728854400000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856200000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728857400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728860400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728863400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864600000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728866400000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728869400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870600000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728872400000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728875400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728878400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728881400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882600000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728884400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728887400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728890400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728893400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728896400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897600000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728899400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728902400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728905400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728908400000,
            "y": 4.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909600000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728911400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728914400000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728917400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919800000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728920400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728923400000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924600000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728926400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927600000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728929400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728932400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933600000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728935400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728938400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728941400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942000000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728944400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946200000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728947400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728950400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728953400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728956400000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728959400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960000000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728962400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728965400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728968400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728971400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728974400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728977400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728980400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728983400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728986400000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988200000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728989400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990600000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728992400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994800000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728995400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997200000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728998400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999000000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999600000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000200000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000800000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729001400000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002000000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729004400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006800000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729007400000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009200000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729010400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012800000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729013400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729016400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017600000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018200000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729019400000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020600000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021800000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729022400000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024200000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729025400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026600000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729028400000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030200000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030800000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729031400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032600000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033200000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033800000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729034400000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729037400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038600000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729040400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042200000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042800000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729043400000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044600000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045800000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729046400000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047600000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048200000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729049400000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051800000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729052400000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053600000,
            "y": 3.85,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054200000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729055400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056600000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057200000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057800000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729058400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059000000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059600000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060200000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060800000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729061400000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062000000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062600000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063800000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729064400000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065000000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065600000,
            "y": 3.88,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066800000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729067400000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068000000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068600000,
            "y": 3.88,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069200000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069800000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729070400000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071000000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071600000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072200000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072800000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729073400000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074600000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075200000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075800000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729076400000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077000000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077600000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078200000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078800000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729079400000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080000000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081200000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081800000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729082400000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083000000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084800000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729085400000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086000000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087200000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087800000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729088400000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089000000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089600000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090200000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090800000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729091400000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092000000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092600000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093200000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093800000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729094400000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095000000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096200000,
            "y": 3.88,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729097400000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098000000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099800000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729100400000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101000000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729103400000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104000000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729106400000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107000000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108200000,
            "y": 3.88,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729109400000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110000000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729112400000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113000000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114200000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114800000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729115400000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116000000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117200000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729118400000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119000000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729121400000,
            "y": 3.87,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122000000,
            "y": 3.87,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122600000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123200000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729124400000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125000000,
            "y": 3.88,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126200000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126800000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729127400000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128000000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129800000,
            "y": 3.8,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729130400000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131000000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132800000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729133400000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134000000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134600000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135200000,
            "y": 3.87,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135800000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729136400000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137000000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137600000,
            "y": 3.88,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138800000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729139400000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140000000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140600000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141200000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141800000,
            "y": 3.86,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729142400000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143000000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143600000,
            "y": 3.91,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144200000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144800000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729145400000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146000000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146600000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147200000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147800000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729148400000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149000000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149600000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150200000,
            "y": 3.86,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150800000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729151400000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152000000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152600000,
            "y": 3.88,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153200000,
            "y": 3.83,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153800000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729154400000,
            "y": 3.84,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155000000,
            "y": 3.84,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155600000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156200000,
            "y": 3.87,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156800000,
            "y": 3.86,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729157400000,
            "y": 3.87,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158000000,
            "y": 3.84,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158600000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159200000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159800000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729160400000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161000000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161600000,
            "y": 3.9,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162200000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162800000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729163400000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164000000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164600000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165200000,
            "y": 4.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165800000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729166400000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167000000,
            "y": 4.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167600000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168200000,
            "y": 4.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168800000,
            "y": 4.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169400000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170000000,
            "y": 4.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170600000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171200000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171800000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729172400000,
            "y": 4.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173000000,
            "y": 4.05,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173600000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174200000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174800000,
            "y": 4.04,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729175400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176000000,
            "y": 4.02,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176600000,
            "y": 4.01,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177200000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177800000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729178400000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179000000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179600000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180800000,
            "y": 3.94,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729181400000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182600000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183800000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729184400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729187400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188600000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189200000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729190400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191600000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192200000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729193400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194000000,
            "y": 3.92,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194600000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195200000,
            "y": 3.95,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195800000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729196400000,
            "y": 3.93,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197600000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198200000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198800000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729199400000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200000000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200600000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201200000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201800000,
            "y": 3.98,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          }
        ],
        "60": [
          {
            "x": 1728638400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728639600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640200000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728640800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728641400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728642600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643200000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728643800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728644400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728645600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646200000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728646800000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728647400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728648600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728649800000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728650400000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651000000,
            "y": 4.4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728651600000,
            "y": 4.81,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652200000,
            "y": 4.74,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728652800000,
            "y": 4.7,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728653400000,
            "y": 4.64,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654000000,
            "y": 4.62,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728654600000,
            "y": 4.56,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655200000,
            "y": 4.52,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728655800000,
            "y": 4.52,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728656400000,
            "y": 4.5,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657000000,
            "y": 4.48,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728657600000,
            "y": 4.45,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658200000,
            "y": 4.41,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728658800000,
            "y": 4.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728659400000,
            "y": 4.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728660600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661200000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728661800000,
            "y": 3.89,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728662400000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663000000,
            "y": 3.96,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728663600000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664200000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728664800000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728665400000,
            "y": 3.97,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666000000,
            "y": 3.99,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728666600000,
            "y": 4,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667200000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728667800000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728668400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669000000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728669600000,
            "y": 4.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670200000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728670800000,
            "y": 4.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728671400000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672000000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728672600000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673200000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728673800000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728674400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675000000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728675600000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728676800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728677400000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728678600000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728679800000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728680400000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681000000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728681600000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682200000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728682800000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728683400000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684000000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728684600000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685200000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728685800000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728686400000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687000000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728687600000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688200000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728688800000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728689400000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690000000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728690600000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691200000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728691800000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728692400000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693000000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728693600000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694200000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728694800000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728695400000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728696600000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697200000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728697800000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728698400000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699000000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728699600000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700200000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728700800000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728701400000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728702600000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703200000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728703800000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728704400000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705000000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728705600000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706200000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728706800000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728707400000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708000000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728708600000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709200000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728709800000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728710400000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728711600000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712200000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728712800000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728713400000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714000000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728714600000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728715800000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728716400000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717000000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728717600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718200000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728718800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728719400000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720000000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728720600000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728721800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728722400000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728723600000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728724800000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728725400000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728726600000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728727800000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728728400000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728729600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728730800000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728731400000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728732600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728733800000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728734400000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735000000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728735600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728736800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728737400000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738000000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728738600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739200000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728739800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728740400000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728741600000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728742800000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728743400000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728744600000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728745800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728746400000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728747600000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748200000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728748800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728749400000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728750600000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751200000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728751800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728752400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753000000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728753600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728754800000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728755400000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728756600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728757800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728758400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728759600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728760800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728761400000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762000000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728762600000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728763800000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728764400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765000000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728765600000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766200000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728766800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728767400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768000000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728768600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728769800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728770400000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728771600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728772800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728773400000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728774600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775200000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728775800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728776400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728777600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728778800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728779400000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728780600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728781800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728782400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728783600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728784800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728785400000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786000000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728786600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728787800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728788400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728789600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728790800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728791400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792000000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728792600000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728793800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728794400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728795600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796200000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728796800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728797400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728798600000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799200000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728799800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728800400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728801600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728802800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728803400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728804600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728805800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728806400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728807600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728808800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728809400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728810600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811200000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728811800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728812400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728813600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728814800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728815400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728816600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817200000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728817800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728818400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728819600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820200000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728820800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728821400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728822600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728823800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728824400000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825000000,
            "y": 4.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728825600000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826200000,
            "y": 4.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728826800000,
            "y": 4.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728827400000,
            "y": 4.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828000000,
            "y": 4.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728828600000,
            "y": 4.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829200000,
            "y": 4.17,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728829800000,
            "y": 4.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728830400000,
            "y": 4.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831000000,
            "y": 4.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728831600000,
            "y": 4.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832200000,
            "y": 4.08,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728832800000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728833400000,
            "y": 4.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834000000,
            "y": 4.16,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728834600000,
            "y": 4.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835200000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728835800000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728836400000,
            "y": 4.15,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837000000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728837600000,
            "y": 4.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838200000,
            "y": 4.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728838800000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728839400000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840000000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728840600000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841200000,
            "y": 4.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728841800000,
            "y": 4.07,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728842400000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843000000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728843600000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844200000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728844800000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728845400000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846000000,
            "y": 4.13,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728846600000,
            "y": 4.18,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847200000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728847800000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728848400000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728849600000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850200000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728850800000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728851400000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728852600000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853200000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728853800000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728854400000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855000000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728855600000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856200000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728856800000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728857400000,
            "y": 4.22,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858000000,
            "y": 4.23,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728858600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859200000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728859800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728860400000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861000000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728861600000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862200000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728862800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728863400000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864000000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728864600000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728865800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728866400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728867600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728868800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728869400000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728870600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728871800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728872400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873000000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728873600000,
            "y": 4.19,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728874800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728875400000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728876600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728877800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728878400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728879600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728880800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728881400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728882600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728883800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728884400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728885600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728886800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728887400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728888600000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728889800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728890400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728891600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728892800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728893400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728894600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728895800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728896400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728897600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728898800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728899400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728900600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728901800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728902400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728903600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728904800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728905400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728906600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728907800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728908400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728909600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728910800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728911400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728912600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728913800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728914400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728915600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728916800000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728917400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728918600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728919800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728920400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728921600000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728922800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728923400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728924600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728925800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728926400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728927600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728928800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728929400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728930600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728931800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728932400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728933600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728934800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728935400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728936600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937200000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728937800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728938400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728939600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728940800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728941400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728942600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728943800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728944400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728945600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728946800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728947400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728948600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728949800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728950400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728951600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728952800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728953400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728954600000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728955800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728956400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728957600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728958800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728959400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728960600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728961800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728962400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728963600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728964800000,
            "y": 4.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728965400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728966600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967200000,
            "y": 4.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728967800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728968400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728969600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728970800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728971400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728972600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728973800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728974400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728975600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728976800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728977400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728978600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728979800000,
            "y": 4.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728980400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728981600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728982800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728983400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728984600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728985800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728986400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728987600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728988800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728989400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728990600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728991800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728992400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728993600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728994800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728995400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728996600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728997800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728998400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1728999600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729000800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729001400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729002600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729003800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729004400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729005600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729006800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729007400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729008600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729009800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729010400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729011600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729012800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729013400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729014600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729015800000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729016400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729017600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729018800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729019400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729020600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729021800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729022400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729023600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729024800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729025400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729026600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729027800000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729028400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729029600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729030800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729031400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729032600000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729033800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729034400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729035600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729036800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729037400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038000000,
            "y": 4.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729038600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729039800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729040400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729041600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729042800000,
            "y": 4.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729043400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729044600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729045800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729046400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729047600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729048800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729049400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729050600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729051800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729052400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729053600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729054800000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729055400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729056600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729057800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729058400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729059600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729060800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729061400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729062600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729063800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729064400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729065600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729066800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729067400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729068600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729069800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729070400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729071600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729072800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729073400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729074600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075200000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729075800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729076400000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729077600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729078800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729079400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729080600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729081800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729082400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083000000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729083600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729084800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729085400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729086600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729087800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729088400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729089600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729090800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729091400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729092600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729093800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729094400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729095600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729096800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729097400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729098600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729099800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729100400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101000000,
            "y": 4.2,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729101600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729102800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729103400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729104600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729105800000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729106400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729107600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729108800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729109400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729110600000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729111800000,
            "y": 4.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729112400000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729113600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729114800000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729115400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729116600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729117800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729118400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729119600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729120800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729121400000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122000000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729122600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729123800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729124400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729125600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729126800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729127400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128000000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729128600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729129800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729130400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729131600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729132800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729133400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729134600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729135800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729136400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729137600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729138800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729139400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729140600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729141800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729142400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729143600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729144800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729145400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729146600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729147800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729148400000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149000000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729149600000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729150800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729151400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729152600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729153800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729154400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729155600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156200000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729156800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729157400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158000000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729158600000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729159800000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729160400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161000000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729161600000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729162800000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729163400000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729164600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165200000,
            "y": 4.26,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729165800000,
            "y": 4.24,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729166400000,
            "y": 4.03,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167000000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729167600000,
            "y": 4.81,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168200000,
            "y": 4.75,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729168800000,
            "y": 4.68,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729169400000,
            "y": 4.62,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170000000,
            "y": 4.58,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729170600000,
            "y": 4.58,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171200000,
            "y": 4.53,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729171800000,
            "y": 4.14,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729172400000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173000000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729173600000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174200000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729174800000,
            "y": 4.09,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729175400000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176000000,
            "y": 4.11,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729176600000,
            "y": 4.1,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177200000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729177800000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729178400000,
            "y": 4.12,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179000000,
            "y": 4.21,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729179600000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180200000,
            "y": 4.31,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729180800000,
            "y": 4.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729181400000,
            "y": 4.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182000000,
            "y": 4.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729182600000,
            "y": 4.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183200000,
            "y": 4.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729183800000,
            "y": 4.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729184400000,
            "y": 4.34,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185000000,
            "y": 4.35,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729185600000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186200000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729186800000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729187400000,
            "y": 4.38,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188000000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729188600000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189200000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729189800000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729190400000,
            "y": 4.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191000000,
            "y": 4.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729191600000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192200000,
            "y": 4.36,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729192800000,
            "y": 4.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729193400000,
            "y": 4.33,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194000000,
            "y": 4.37,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729194600000,
            "y": 4.32,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195200000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729195800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729196400000,
            "y": 4.25,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729197600000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198200000,
            "y": 4.27,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729198800000,
            "y": 4.3,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729199400000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200000000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729200600000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201200000,
            "y": 4.29,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          },
          {
            "x": 1729201800000,
            "y": 4.28,
            "unit": "MILLISIEMENS_PER_CENTIMETER",
            "symbol": "mS/cm",
            "name": null,
            "additionalInfo": null
          }
        ]
      }
    }
  }