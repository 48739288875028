import { Inject, Injectable } from '../../di';
import { VeggaLoader } from '../../http/veggaLoader';
import { map, Observable } from 'rxjs';
import { VeggaResponse } from '../../http/veggaResponse';
import { HttpClient } from '../../http';
import { VeggaHelpParsedResponse, VeggaHelpResponse } from './vegga-facade.interface';
import { environment } from '../../../environments/environment';

@Injectable('veggaFacade')
export class VeggaFacade {
  @Inject('httpClient') private httpClient: HttpClient;
  loader = new VeggaLoader();
  viewHelpResponse = new VeggaResponse<VeggaHelpResponse>();

  private abortController = new AbortController();

  get isLoading$(): Observable<boolean> {
    return this.loader.isLoading$;
  }

  get viewHelp$(): Observable<VeggaHelpResponse> {
    return this.viewHelpResponse.value$;
  }

  /**
   * Requests help translated data by id
   * @param id help data id
   * @param step related step with help data
   * @returns mapped observable with translated data along related step
   */
  getViewHelp(id: number, step?: string | number): Observable<VeggaHelpParsedResponse> {
    return this.httpClient.get<VeggaHelpResponse>(`${environment.API_HELP}/${id}`, {}).pipe(
      map(res => {
        return {
          title: res.title.rendered,
          content: res.content.rendered,
          step,
        };
      }),
    );
  }

  abortRequests(): void {
    this.abortController.abort('REPEATED_REQUEST');
    this.abortController = new AbortController();
  }

  getAbortSignal(): AbortSignal {
    return this.abortController.signal;
  }
}
