import {
  GetIrrigationCommunityNetworkFlowConsumptionRequest,
  IrrigationCommunitiesApi,
  IrrigationCommunityFlowConsumptionResponse,
  IrrigationCommunityNetworkItemResponse,
  IrrigationCommunityNetworkRequest,
  IrrigationCommunityNetworkResponse,
} from '@vegga-api-clients/irrigation-control-service';
import { Observable, tap } from 'rxjs';
import { Injectable } from '@di';
import { environment } from '@env';
import { HttpErrorResponse, VeggaLoader, VeggaResponse } from '@http';

@Injectable('irrigationCommunityNetworksFacade')
export class IrrigationCommunityNetworksFacade {
  private networksResponse = new VeggaResponse<IrrigationCommunityNetworkItemResponse[]>();
  private networkDataResponse = new VeggaResponse<IrrigationCommunityFlowConsumptionResponse, HttpErrorResponse>();
  private detailLoader = new VeggaLoader();
  private listLoader = new VeggaLoader();
  private irrigationCommunitiesApi: IrrigationCommunitiesApi;

  constructor() {
    this.irrigationCommunitiesApi = new IrrigationCommunitiesApi();
    this.irrigationCommunitiesApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get networks$(): Observable<IrrigationCommunityNetworkItemResponse[]> {
    return this.networksResponse.value$;
  }

  get networks(): IrrigationCommunityNetworkItemResponse[] {
    return this.networksResponse.value;
  }

  get networkData$(): Observable<IrrigationCommunityFlowConsumptionResponse> {
    return this.networkDataResponse.value$;
  }

  get networkDataError$(): Observable<HttpErrorResponse> {
    return this.networkDataResponse.error$;
  }

  get isDetailLoading$(): Observable<boolean> {
    return this.detailLoader.isLoading2$;
  }

  get isListLoading$(): Observable<boolean> {
    return this.listLoader.isLoading2$;
  }

  loadNetworks() {
    const req$ = this.irrigationCommunitiesApi.listIrrigationCommunityNetwork();
    const subscription = req$.subscribe({
      next: networks => {
        const parsedNetworks = networks.map(network => ({
          ...network,
          hydrantsAmount: network.hydrants.length,
          irrigatingHydrants: network.hydrants.filter(hydrant => hydrant.status === 'IRRIGATING').length,
        }));
        this.networksResponse.set(parsedNetworks);
      },
      error: err => {
        this.networksResponse.setError(err, {});
      },
    });

    this.listLoader.waitFor(subscription);
  }

  loadNetworkData(params: GetIrrigationCommunityNetworkFlowConsumptionRequest) {
    const req$ = this.irrigationCommunitiesApi.getIrrigationCommunityNetworkFlowConsumption(params);
    const subscription = req$.subscribe({
      next: networkData => {
        this.networkDataResponse.set(networkData);
      },
      error: err => {
        this.networkDataResponse.setError(err, {});
      },
    });

    this.detailLoader.waitFor(subscription);
  }

  postNetwork(irrigationCommunityNetworkRequest: IrrigationCommunityNetworkRequest): Observable<IrrigationCommunityNetworkResponse> {
    return this.irrigationCommunitiesApi
      .postIrrigationCommunityNetwork({ irrigationCommunityNetworkRequest })
      .pipe(tap(network => this.networksResponse.set([...this.networks, network])));
  }

  cleanNetworksResponse(): void {
    this.networksResponse.clear();
  }

  cleanNetworkDataResponse(): void {
    this.networkDataResponse.clear();
  }

  clearResponses(): void {
    this.networksResponse.clear();
    this.networkDataResponse.clear();
  }

  clearNetworkDataError(): void {
    this.networkDataResponse.clearError();
  }
}
