import { concatMap, from, map, Observable, ObservableInput, of } from 'rxjs';
import { INTERCEPTORS } from '../interceptors';
import { HttpRequest } from './request';
import { HttpErrorResponse, HttpResponse } from './response';
import { Injectable } from '../di/injectable';
import { HttpInterceptor } from './httpInterceptor';
import { HttpQuery } from '@vegga-api-clients/irrigation-control-service';

export abstract class HttpHandler {
  abstract handle(req: HttpRequest<unknown>): Observable<unknown>;
}

export type HttpEvent<T> = HttpResponse<T> | HttpRequest<T>;

export type HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => Observable<HttpEvent<unknown>>;
export type HttpHandlerFn = (req: HttpRequest<unknown>) => Observable<unknown>;

type ChainedInterceptorFn<T> = (req: HttpRequest<T>, finalHandlerFn?: HttpHandlerFn) => Observable<T>;

export interface HttpClientResponse<T> {
  data: T;
  isOk: boolean;
}

export enum HttpMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export class HttpHeaders {
  headers!: Map<string, string>;
  constructor() {}
}

export type RequestInitCustom = RequestInit & {
  selector?: (response: Response) => ObservableInput<unknown>;
};

export interface HttpOptions {
  url?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  query?: HttpQuery;
  headers?: Headers | { [key: string]: string };
  method?: HttpMethod;
  observe?: 'body' | 'events' | 'response';
  params?: Record<string, string>;
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
  signal?: AbortSignal;
  status?: string;
}

export type RequestParams = Record<string, string>;

@Injectable('httpClient')
export class HttpClient {
  get<T>(url: string, options: HttpOptions): Observable<T> {
    return this.request(url, this.getDefaultOptions(options, HttpMethod.GET));
  }

  post<T>(url: string, options: HttpOptions): Observable<T> {
    return this.request(url, this.getDefaultOptions(options, HttpMethod.POST));
  }

  patch<T>(url: string, options: HttpOptions): Observable<T> {
    return this.request(url, this.getDefaultOptions(options, HttpMethod.PATCH));
  }

  put<T>(url: string, options: HttpOptions): Observable<T> {
    return this.request(url, this.getDefaultOptions(options, HttpMethod.PUT));
  }

  delete<T>(url: string, options: HttpOptions): Observable<T> {
    return this.request(url, this.getDefaultOptions(options, HttpMethod.DELETE));
  }

  private getDefaultOptions(options: HttpOptions, method: HttpMethod): HttpOptions {
    return { observe: 'response', method, headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }, ...options };
  }

  request<T>(url: string, options: HttpOptions): Observable<T> {
    options = {
      observe: 'response',
      ...options,
    };
    const requestInit: RequestInitCustom = {
      method: options.method,
      selector: (response: Response) => {
        if (!response.ok) {
          throw new HttpErrorResponse({
            url: response.url,
            status: response.status,
            error: response.statusText,
          });
        }
        if (response.status === 204) {
          return of(null);
        }

        switch (options.responseType) {
          case 'arraybuffer':
            return response.arrayBuffer();
          case 'blob':
            return response.blob();
          case 'text':
            return response.text();
          case 'json':
          default:
            return response.json();
        }
      },
    };

    return this.fetch(url, requestInit, options);
  }

  private fetch<T>(url: string, reqInit: RequestInitCustom, options: HttpOptions): Observable<T> {
    const parsedUrl = options.query ? `${url}?${new URLSearchParams(options.query)}` : url;
    const request = new HttpRequest<T>({
      body: options.body,
      method: options.method,
      headers: options.headers,
      url,
      query: options.query,
      signal: options.signal,
    });

    // backend http request is the last piece of the interceptors chain
    // applying accumulated interception config
    const backendHandlerFn = (req: HttpRequest<unknown>): Observable<Response> =>
      from(
        fetch(parsedUrl, {
          headers: req.headers,
          body: req.body && JSON.stringify(req.body),
          method: req.method,
          signal: req.signal,
        }),
      ).pipe(
        concatMap(reqInit.selector as (value: Response, index: number) => ObservableInput<Response>),
        map((response: Response) => {
          if (!response) {
            return null;
          }
          if (response.status === 401) {
            throw response;
          }

          switch (options.observe) {
            case 'response':
              return response;
            case 'events':
              return response;
            case 'body':
            default:
              return response;
          }
        }),
        // catchError((err) => {
        //   throw err
        // })
      );

    // reduces interceptors to function wrapper, like a(b(c(request))), where a,b,c are interceptor functions
    // result is an observable containing all interception config
    const runChainedInterceptors: ChainedInterceptorFn<unknown> = INTERCEPTORS.reduceRight(
      (next: (req: HttpRequest<unknown>, finalHandlerFn?: HttpHandlerFn) => Observable<unknown>, interceptor: HttpInterceptor) =>
        ((initialRequest: HttpRequest<unknown>, backendHandlerFn: HttpHandlerFn) =>
          interceptor.intercept(initialRequest, {
            handle: (downstreamRequest: HttpRequest<unknown>) => next(downstreamRequest, backendHandlerFn),
          })) as HttpHandlerFn,
      (req: HttpRequest<unknown>) => backendHandlerFn(req) as unknown as Observable<HttpEvent<unknown>>,
    );
    return runChainedInterceptors(request) as Observable<T>;
  }
}
