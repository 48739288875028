import { Injectable } from '../../di';
import { OrganizationFeaturesEnum, OrganizationPermissionEnum } from '@vegga-statics/enums';
import { CalculatedPersonalization } from './user-personalization.entities';
import { BehaviorSubject, Observable, skip } from 'rxjs';
import { OrganizationStore } from '../../organization/organization.store';

type UserPersonalization<T = string | number | boolean | []> = { [key: string]: T };

@Injectable('userPersonalizationService')
export class UserPersonalizationService {
  // private readonly organization: Organization | null;

  private readonly organization: OrganizationStore;

  private currentUserPersonalization: BehaviorSubject<UserPersonalization> = new BehaviorSubject({});
  currentUserPersonalization$: Observable<UserPersonalization>;

  constructor() {
    //TODO - Check this part when multi organization is implemented
    // this.organization = sessionStorage.getItem(ORGANIZATION_STORE_NAME) ? (JSON.parse(sessionStorage.getItem(ORGANIZATION_STORE_NAME) as string) as Organization) : null;
    this.organization = OrganizationStore.getInstance();

    this.currentUserPersonalization$ = this.currentUserPersonalization.asObservable().pipe(skip(1));
  }

  private get userPersonalization() {
    return this.currentUserPersonalization.value;
  }

  createUser(features: OrganizationFeaturesEnum[], permissions: OrganizationPermissionEnum[]) {
    const user: UserPersonalization = {};

    features.forEach(feature => {
      // To get the same key that the enum has
      const enumKey = Object.keys(OrganizationFeaturesEnum)[Object.values(OrganizationFeaturesEnum).indexOf(feature)];
      user[enumKey] = this.organization.getFeature(feature);
    });

    permissions.forEach(permission => {
      user[permission] = this.organization.hasPermission(permission);
    });

    this.currentUserPersonalization.next(user);
  }

  addedCalculatedPersonalization(calculatedPersonalization: CalculatedPersonalization[]) {
    calculatedPersonalization.forEach(calculatedPersonalization => {
      this.userPersonalization[`CALCULATED_${calculatedPersonalization.key}`] = calculatedPersonalization.value;
    });
  }

  clear(): void {
    this.currentUserPersonalization.next({});
  }
}
