export interface PlaceConfigItem {
  category: PlaceCategories;
  display_name: string;
  importance: number;
  lat: number;
  lon: number;
}

export type PlacesResponse = PlaceConfigItem[];
export enum PlaceCategories {
  BOUNDARY = 'boundary',
  PLACE = 'place',
  NATURAL = 'natural',
  HIGHWAY = 'highway',
}

export interface PlacesRequest {
  street: string;
  postalCode?: string;
  city?: string;
  country?: string;
  region?: string;
}

// longitude, latitude
export type Coordinates = [number, number];
export const EMPTY_RESPONSE = 'empty';
