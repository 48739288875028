import { forkJoin, Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpClient } from '../../http';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { getCropsOptions, parseForm, parseSubscriptionResponse } from './utils';
import {
  AccumulatedIrrigationResponse,
  Channel,
  ChannelFormConfig,
  ChannelWrapper,
  CreateChannelFormConfig,
  IrrideskSubscription,
  SeasonalConfig,
  SeasonalPlanInfo,
  Wrapper,
} from './types';
import { VeggaLoader } from '../../http/veggaLoader';

@Injectable('channelsFacade')
export class ChannelsFacade {
  @Inject('httpClient') private httpClient: HttpClient;
  private agronicAPIBasePath = environment.AGRONICWEB_API_URL;
  constructor() {}

  private channelsResponse = new VeggaResponse<IrrideskSubscription>();
  private wrappersResponse = new VeggaResponse<ChannelWrapper[]>();
  private channelConfigElementsResponse = new VeggaResponse<ChannelFormConfig>();
  private newChannelFormElementsResponse = new VeggaResponse<CreateChannelFormConfig>();
  private channelAccumulatedIrrigationResponse = new VeggaResponse<AccumulatedIrrigationResponse>();
  private channelSeasonalIrrigationResponse = new VeggaResponse<SeasonalPlanInfo>();
  private channels: IrrideskSubscription;
  private loader = new VeggaLoader();

  get channels$(): Observable<IrrideskSubscription> {
    return this.channelsResponse.value$;
  }

  get channelAccumulatedIrrigation$(): Observable<AccumulatedIrrigationResponse> {
    return this.channelAccumulatedIrrigationResponse.value$;
  }

  get channelSeasonalIrrigation$(): Observable<SeasonalPlanInfo> {
    return this.channelSeasonalIrrigationResponse.value$;
  }

  get channelConfigElements$(): Observable<ChannelFormConfig> {
    return this.channelConfigElementsResponse.value$;
  }

  get newChannelFormElements$(): Observable<CreateChannelFormConfig> {
    return this.newChannelFormElementsResponse.value$;
  }

  get isLoading$() {
    return this.loader.isLoading$;
  }

  getChannels() {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/subscription`, {});

    const subscription = handleResponse(req$, this.channelsResponse).subscribe(channels => {
      this.channels = channels;

      const hasChannels = this.channels.channels.map(ch => ch.id).find(ch => !!ch);
      if (!hasChannels) {
        this.channelsResponse.set(channels);
        this.loader.waitFor(subscription);
      }

      const irrideskIds = this.channels.channels.filter(ch => ch.irrideskId).map(ch => ch.irrideskId);

      // Create an array of observables for all the wrapper requests
      const wrapperRequests$ = irrideskIds.map(id =>
        handleResponse(this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/channel/${id}/wrappers`, {}), this.wrappersResponse),
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const allWrappersSubscription = forkJoin(wrapperRequests$).subscribe((wrappersArray: any[]) => {
        const prev = this.wrappersResponse.value ?? [];

        const allWrappers = [...prev, ...wrappersArray.flatMap(wrappers => wrappers.program_wrappers)];

        this.wrappersResponse.set(allWrappers);

        this.channels = {
          ...this.channels,
          channels: this.channels.channels.map((channel: Channel) => ({
            ...channel,
            wrapper: allWrappers.filter((wr, index, self) => wr.channel === channel.irrideskId && self.findIndex(o => o.id === wr.id) === index),
            irrideskInfo: this.channels.irrideskInfo,
          })),
        };

        const subscriptionResponse = parseSubscriptionResponse(this.channels);
        this.channelsResponse.set(subscriptionResponse);
      });
      this.loader.waitFor(allWrappersSubscription);
    });
  }

  getChannelWrapper(irrideskId: number) {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/channel/${irrideskId}/wrappers`, {});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subscription = handleResponse(req$, this.wrappersResponse).subscribe((wrappers: any) => {
      const prev = this.wrappersResponse.value ?? [];

      const allWrappers = [...prev, wrappers.program_wrappers].flat();

      this.wrappersResponse.set(allWrappers);

      this.channels = {
        ...this.channels,
        channels: this.channels.channels.map((channel: Channel) => ({
          ...channel,
          wrapper: allWrappers.filter((wr, index, self) => wr.channel === channel.irrideskId && self.findIndex(o => o.id === wr.id) === index),
          irrideskInfo: this.channels.irrideskInfo,
        })),
      };
      const subcriptionResponse = parseSubscriptionResponse(this.channels);
      this.channelsResponse.set(subcriptionResponse);
    });
    this.loader.waitFor(subscription);
  }

  getChannelAccumulatedIrrigation(irrideskId: number, from: string, to: string) {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/channel/${irrideskId}/dailystate`, { query: { ts: from, te: to } });
    const subscription = handleResponse(req$, this.channelAccumulatedIrrigationResponse).subscribe((data: AccumulatedIrrigationResponse) => {
      this.channelAccumulatedIrrigationResponse.set(data);
    });
    this.loader.waitFor(subscription);
  }

  getChannelSesasonalIrrigation(irrideskId: number) {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/channel/${irrideskId}/seasonal`, {});
    const subscription = handleResponse(req$, this.channelSeasonalIrrigationResponse).subscribe((data: SeasonalPlanInfo) => {
      this.channelSeasonalIrrigationResponse.set(data);
    });
    this.loader.waitFor(subscription);
  }

  saveChannelSesasonalIrrigation(irrideskId: number, seasonalConfig: SeasonalConfig) {
    const req$ = this.httpClient.post<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/channel/${irrideskId}/seasonal`, { body: seasonalConfig });
    const subscription = handleResponse(req$, this.channelSeasonalIrrigationResponse).subscribe((data: SeasonalPlanInfo) => {
      this.channelSeasonalIrrigationResponse.set(data);
    });
    this.loader.waitFor(subscription);
  }

  getChannelConfigElements(irrideskId: number) {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/channel/${irrideskId}`, {});
    const subscription = handleResponse(req$, this.channelConfigElementsResponse).subscribe((data: ChannelFormConfig) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.channelConfigElementsResponse.set((data as any).channels[0]);
    });
    this.loader.waitFor(subscription);
  }

  getNewChannelFormElements() {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/formfactory`, {});
    const subscription = handleResponse(req$, this.newChannelFormElementsResponse).subscribe((data: CreateChannelFormConfig) => {
      const cropsOptions = getCropsOptions(data.channel_outlines);
      const parsedForm = parseForm(data);

      data.channel_outlines.crop_option.choices = cropsOptions.cropGroups;
      this.newChannelFormElementsResponse.set({ ...parsedForm, cropsOptions });
    });
    this.loader.waitFor(subscription);
  }

  acceptPlan(subscriptionId: number, planId: number, plan: SeasonalPlanInfo) {
    const req$ = this.httpClient.post<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/${subscriptionId}/plan/${planId}/accept`, { body: { seasonalPlan_id: [plan] } });
    const subscription = handleResponse(req$, this.channelConfigElementsResponse).subscribe(() => {
      this.getChannels();
    });
    this.loader.waitFor(subscription);
  }

  toggleActiveChannel(subscriptionId: number, planId: number, channel: Channel) {
    const req$ = this.httpClient.post<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/${subscriptionId}/channel/${planId}`, { body: { channels: [channel] } });
    const subscription = handleResponse(req$, this.channelConfigElementsResponse).subscribe(() => {
      this.getChannels();
    });
    this.loader.waitFor(subscription);
  }

  saveWrappers(irrideskId: number, wrappers: Wrapper, subscritionId: number) {
    const req$ = this.httpClient.post<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/${subscritionId}/channel/${irrideskId}/wrappers`, { body: wrappers });
    const subscription = handleResponse(req$, this.channelsResponse).subscribe(() => {
      this.getChannels();
    });
    this.loader.waitFor(subscription);
  }

  createChannel(subscriptionId: number, channelId: number, ch: CreateChannelFormConfig) {
    delete ch.channel_outlines.temp_crop;
    const req$ = this.httpClient.post<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/${subscriptionId}/channel/${channelId}/init`, { body: ch });

    return handleResponse(req$, this.channelsResponse).pipe(tap(() => this.getChannels()));
  }

  saveCampaign(subscriptionId: number, channelId: number, channel: Channel, recalc: boolean) {
    const req$ = this.httpClient.post<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/${subscriptionId}/channel/${channelId}`, { body: { channels: [channel] } });
    const subscription = handleResponse(req$, this.channelsResponse).subscribe(() => {
      if (recalc) {
        this.recalculateSeasonalPlan(channelId);
      } else {
        this.getChannels();
      }
    });
    this.loader.waitFor(subscription);
  }

  recalculateSeasonalPlan(channelId: number) {
    const req$ = this.httpClient.get<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/channel/${channelId}/recalcSeasonalPlan`, {});
    const subscription = handleResponse(req$, this.channelsResponse).subscribe(() => {
      this.getChannels();
    });
    this.loader.waitFor(subscription);
  }

  deleteChannel(subscriptionId: number, channelId: string) {
    const req$ = this.httpClient.delete<unknown>(`${this.agronicAPIBasePath}/api/v1/irridesk/${subscriptionId}/channel/${channelId}`, {});
    return handleResponse(req$, this.channelsResponse).pipe(
      tap(() => this.channelsResponse.set({ ...this.channelsResponse.value, channels: this.channelsResponse.value.channels.filter(ch => ch.irrideskId !== channelId) })),
    );
  }

  clearGraphsResponse() {
    this.channelAccumulatedIrrigationResponse.clear();
    this.channelSeasonalIrrigationResponse.clear();
  }
}
