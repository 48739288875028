import { OrganizationResourcesApi, UsageFeaturesResponse } from '@vegga-api-clients/core-service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { VeggaFacade } from '../vegga-facade/vegga-facade';
@Injectable('organizationFacade')
export class OrganizationFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;

  private usageFeaturesResponse = new VeggaResponse<UsageFeaturesResponse>();
  private loader = new VeggaLoader();

  get organization$(): Observable<UsageFeaturesResponse> {
    return this.usageFeaturesResponse.value$;
  }
  private organizationResourcesApi: OrganizationResourcesApi;

  constructor() {
    this.organizationResourcesApi = new OrganizationResourcesApi();
    this.organizationResourcesApi.basePath = environment.API_CORE_ENDPOINT;
  }

  loadOrganizationUsageFeatures() {
    const req$ = this.organizationResourcesApi.listOrganizationUsageFeatures();
    const subscription = req$.subscribe({
      next: res => {
        this.usageFeaturesResponse.set(res);
      },
      error: err => {
        this.usageFeaturesResponse.setError(err, {});
      },
    });

    this.loader.waitFor(subscription);
  }

  clear(): void {}
}
