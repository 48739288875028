import {
  ExploitationControlPointAntiFrostSurveillanceResponse,
  ExploitationControlPointAvailableWaterResponse,
  ExploitationControlPointCampaignIrrigationResponse,
  ExploitationControlPointDewPointResponse,
  ExploitationControlPointDpvResponse,
  ExploitationControlPointEtoResponse,
  ExploitationControlPointHistoryItem,
  ExploitationControlPointNutrientDynamicsResponse,
  GetControlPointDataHistoryRequest,
} from '@vegga-api-clients/irrigation-control-service';
import { Observable } from 'rxjs';

export enum AgronomicMonitoringEntitiesEnum {
  METEO_STATION = 'meteoStation',
  DEVICE = 'device',
  PLOT = 'plot',
}

export type ControlPointWeatherHistory =
  | ExploitationControlPointHistoryItem
  | ExploitationControlPointEtoResponse
  | ExploitationControlPointDpvResponse
  | ExploitationControlPointDewPointResponse
  | ExploitationControlPointAntiFrostSurveillanceResponse;

export type ControlPointSoilHistory =
  | ExploitationControlPointAvailableWaterResponse
  | ExploitationControlPointNutrientDynamicsResponse
  | ExploitationControlPointCampaignIrrigationResponse;
export type ControlPointHistoryResponses = ControlPointWeatherHistoryResponses | ControlPointSoilHistoryResponses;

export interface ControlPointWeatherHistoryResponses {
  controlPoint: ExploitationControlPointHistoryItem;
  eto: ExploitationControlPointEtoResponse;
  dpv: ExploitationControlPointDpvResponse;
  frostSurveillance: ExploitationControlPointAntiFrostSurveillanceResponse;
}

export interface ControlPointSoilHistoryResponses {
  availableWater: ExploitationControlPointAvailableWaterResponse;
  nutrientDynamics: ExploitationControlPointNutrientDynamicsResponse;
  campaignIrrigation: ExploitationControlPointCampaignIrrigationResponse;
}

export type AgVarMethodMap<T> = {
  [key: string]: (params: GetControlPointDataHistoryRequest) => Observable<T>;
};

export type ControlPointDetailHelpI18NMap = {
  en: ControlPointHelpI18NMapItem[];
  es: ControlPointHelpI18NMapItem[];
  pt: ControlPointHelpI18NMapItem[];
  it: ControlPointHelpI18NMapItem[];
  fr: ControlPointHelpI18NMapItem[];
};

export type ControlPointHelpI18NMapItem = {
  step: string | number;
  ids: number[];
  floating?: string;
};

/**
 * Describes a map with Control Point detail help translations
 * by its type
 */
export const ControlPointWeatherViewHelpI18NMap: ControlPointDetailHelpI18NMap = {
  es: [
    { step: '1', ids: [375] },
    { step: '2', ids: [398] },
    { step: '3', ids: [414], floating: 'right' },
    { step: '4', ids: [431] },
    { step: '5', ids: [447] },
    { step: '6', ids: [464] },
    { step: '7', ids: [479] },
    { step: '8', ids: [495] },
    { step: '9', ids: [510] },
  ],
  en: [
    { step: '1', ids: [378] },
    { step: '2', ids: [401] },
    { step: '3', ids: [417], floating: 'right' },
    { step: '4', ids: [434] },
    { step: '5', ids: [450] },
    { step: '6', ids: [467] },
    { step: '7', ids: [482] },
    { step: '8', ids: [498] },
    { step: '9', ids: [514] },
  ],
  pt: [
    { step: '1', ids: [381] },
    { step: '2', ids: [404] },
    { step: '3', ids: [421], floating: 'right' },
    { step: '4', ids: [437] },
    { step: '5', ids: [453] },
    { step: '6', ids: [470] },
    { step: '7', ids: [485] },
    { step: '8', ids: [501] },
    { step: '9', ids: [517] },
  ],
  it: [
    { step: '1', ids: [385] },
    { step: '2', ids: [407] },
    { step: '3', ids: [424], floating: 'right' },
    { step: '4', ids: [441] },
    { step: '5', ids: [457] },
    { step: '6', ids: [473] },
    { step: '7', ids: [489] },
    { step: '8', ids: [504] },
    { step: '9', ids: [520] },
  ],
  fr: [
    { step: '1', ids: [389] },
    { step: '2', ids: [411] },
    { step: '3', ids: [428], floating: 'right' },
    { step: '4', ids: [444] },
    { step: '5', ids: [460] },
    { step: '6', ids: [476] },
    { step: '7', ids: [492] },
    { step: '8', ids: [507] },
    { step: '9', ids: [523] },
  ],
};

export const ControlPointSoilViewHelpI18NMap: ControlPointDetailHelpI18NMap = {
  es: [
    { step: '1', ids: [510] },
    { step: '2', ids: [526] },
    { step: '3', ids: [542] },
    { step: '4', ids: [557] },
    { step: '5', ids: [574] },
  ],
  en: [
    { step: '1', ids: [514] },
    { step: '2', ids: [529] },
    { step: '3', ids: [545] },
    { step: '4', ids: [562] },
    { step: '5', ids: [577] },
  ],
  pt: [
    { step: '1', ids: [517] },
    { step: '2', ids: [532] },
    { step: '3', ids: [548] },
    { step: '4', ids: [565] },
    { step: '5', ids: [580] },
  ],
  it: [
    { step: '1', ids: [520] },
    { step: '2', ids: [535] },
    { step: '3', ids: [551] },
    { step: '4', ids: [568] },
    { step: '5', ids: [583] },
  ],
  fr: [
    { step: '1', ids: [523] },
    { step: '2', ids: [538] },
    { step: '3', ids: [554] },
    { step: '4', ids: [571] },
    { step: '5', ids: [586] },
  ],
};

/**
 * Describes a map with Control Point detail help translations
 * by its type
 */
export const ControlPointFormWeatherTypeI18NMap = {
  es: {
    ids: [596],
  },
  en: {
    ids: [598],
  },
  fr: {
    ids: [606],
  },
  it: {
    ids: [604],
  },
  pt: {
    ids: [601],
  },
};

/**
 * Describes a map with Control Point detail help translations
 * by its type
 */
export const ControlPointFormSoilTypeI18NMap = {
  es: {
    ids: [609],
  },
  en: {
    ids: [612],
  },
  fr: {
    ids: [621],
  },
  it: {
    ids: [619],
  },
  pt: {
    ids: [616],
  },
};
