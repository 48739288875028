import { Injectable } from '@di';
import { environment } from '@env';
import { VeggaLoader, VeggaResponse } from '@http';
import {
  IrrigationCommunitiesApi,
  IrrigationCommunityIrrigatorItem,
  IrrigationCommunityIrrigatorItemRequest,
  IrrigationCommunityIrrigatorResponse,
} from '@vegga-api-clients/irrigation-control-service';
import { Observable, tap } from 'rxjs';

@Injectable('irrigationCommunityIrrigatorsFacade')
export class IrrigationCommunitiesIrrigatorsFacade {
  private irrigatorsResponse = new VeggaResponse<IrrigationCommunityIrrigatorItem[]>();

  private listLoader = new VeggaLoader();
  private irrigationCommunitiesApi: IrrigationCommunitiesApi;

  constructor() {
    this.irrigationCommunitiesApi = new IrrigationCommunitiesApi();
    this.irrigationCommunitiesApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  get irrigators$(): Observable<IrrigationCommunityIrrigatorItem[]> {
    return this.irrigatorsResponse.value$;
  }

  get irrigators(): IrrigationCommunityIrrigatorItem[] {
    return this.irrigatorsResponse.value;
  }

  get isListLoading$(): Observable<boolean> {
    return this.listLoader.isLoading2$;
  }

  loadIrrigators() {
    const req$ = this.irrigationCommunitiesApi.listIrrigationCommunityIrrigators();
    const subscription = req$.subscribe({
      next: irrigators => {
        this.irrigatorsResponse.set(irrigators);
      },
      error: err => {
        this.irrigatorsResponse.setError(err, {});
      },
    });

    this.listLoader.waitFor(subscription);
  }

  postIrrigator(irrigationCommunityIrrigatorItemRequest: IrrigationCommunityIrrigatorItemRequest): Observable<IrrigationCommunityIrrigatorResponse> {
    return this.irrigationCommunitiesApi
      .postIrrigationCommunityIrrigator({ irrigationCommunityIrrigatorItemRequest })
      .pipe(tap(irrigator => this.irrigatorsResponse.set([...this.irrigators, irrigator])));
  }

  updateIrrigator(id: number, irrigatorUpdateReq: IrrigationCommunityIrrigatorItemRequest): Observable<IrrigationCommunityIrrigatorResponse> {
    return this.irrigationCommunitiesApi.updateIrrigationCommunityIrrigatorById({ id, irrigationCommunityIrrigatorItemRequest: irrigatorUpdateReq }).pipe(
      tap(updatedIrrigator => {
        const updatedIrrigatorIndex = this.irrigators.findIndex((storedIrrigator: IrrigationCommunityIrrigatorResponse) => storedIrrigator.id === id);
        this.irrigators[updatedIrrigatorIndex] = updatedIrrigator;
        this.irrigatorsResponse.set([...this.irrigators]);
      }),
    );
  }

  deleteIrrigator(id: number): Observable<void> {
    return this.irrigationCommunitiesApi.deleteIrrigationCommunityIrrigator({ id }).pipe(tap(() => this.irrigatorsResponse.set(this.irrigators.filter(irrigator => irrigator.id !== id))));
  }

  clearResponses(): void {
    this.irrigatorsResponse.clear();
  }
}
