import { ReplaySubject, Subject, Subscription, filter, take, timer } from 'rxjs';

const INITIAL_DELAY = 100;
const INTERVAL = 200;

export class VeggaLoader {
  isLoadingSubjectLegacy = new Subject<boolean>();
  isLoadingSubject = new ReplaySubject<boolean>(1);
  subscriptions: Subscription[] = [];

  constructor() {
    this.setLoaderSubscription();
  }

  get isLoading$() {
    return this.isLoadingSubjectLegacy.asObservable();
  }

  get isLoading2$() {
    return this.isLoadingSubject.asObservable();
  }

  waitFor(subs: Subscription) {
    // init loading
    this.isLoadingSubject.next(true);
    //TODO: Remove when all facades uses new loader
    this.isLoadingSubjectLegacy.next(true);
    // add subscription to the list
    this.updateCachedSubs(subs);
    // init loader subscription
    this.setLoaderSubscription();
  }

  private updateCachedSubs(subs: Subscription): void {
    this.subscriptions = this.subscriptions.filter(subs => !subs.closed);
    this.subscriptions.push(subs);
  }

  private setLoaderSubscription(): void {
    timer(INITIAL_DELAY, INTERVAL)
      .pipe(
        // filter emissions when subscriptions array contains opened at least one opened subscription
        filter(() => this.subscriptions.every(subs => subs.closed)),
        // close timer observable to get it ready for next emissions
        take(1),
        // set delay
        // delay(750)
      )
      .subscribe(() => {
        this.isLoadingSubject.next(false);
        //TODO: Remove when all facades uses new loader
        this.isLoadingSubjectLegacy.next(false);
      });
  }
}
