import {
  AgronomicVariableKey,
  ControlPointCreationResponse,
  ControlPointDetailItem,
  ControlPointInquiryDevicesItem,
  ControlPointInquiryItemListResponse,
  ControlPointInquiryRequest,
  ControlPointInquiryStatus,
  ControlPointRequest,
  ControlPointRequestTypeEnum,
  ControlPointsApi,
  ExploitationControlPointAntiFrostSurveillanceResponse,
  ExploitationControlPointAvailableWaterResponse,
  ExploitationsApi,
  GetControlPointDataHistoryRequest,
} from '@vegga-api-clients/irrigation-control-service';
import { catchError, filter, forkJoin, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Inject, Injectable } from '../../di';
import { HttpErrorResponse, HttpOptions, HttpResponse } from '../../http';
import { VeggaLoader } from '../../http/veggaLoader';
import { VeggaResponse } from '../../http/veggaResponse';
import { handleResponse } from '../common.facade';
import { VeggaFacade } from '../vegga-facade/vegga-facade';
import { VeggaHelpParsedResponse } from '../vegga-facade/vegga-facade.interface';
import {
  AgVarMethodMap,
  ControlPointFormSoilTypeI18NMap,
  ControlPointFormWeatherTypeI18NMap,
  ControlPointHelpI18NMapItem,
  ControlPointHistoryResponses,
  ControlPointSoilHistory,
  ControlPointSoilHistoryResponses,
  ControlPointSoilViewHelpI18NMap,
  ControlPointWeatherHistory,
  ControlPointWeatherHistoryResponses,
  ControlPointWeatherViewHelpI18NMap,
} from './control-points.entities';
import {
  MOCK_ANTI_FROST_SURV,
  MOCK_AVAILABLE_WATER,
  MOCK_CAMPAIGN_IRRIGATION,
  MOCK_DPV_DATA,
  MOCK_ETO_DATA,
  MOCK_NUTRI_DYNAMICS,
  MOCK_SOIL_CONTROL_POINT_DATA,
  MOCK_WEATHER_CONTROL_POINT_DATA,
  MOCK_WEATHER_DATA,
} from './control-points.mocks';

@Injectable('controlPointsFacade')
export class ControlPointsFacade {
  @Inject('veggaFacade') private veggaFacade: VeggaFacade;
  private controlPointsDataResponse = new VeggaResponse<ControlPointDetailItem[]>();

  private controlPointAvailableWaterHistory = new VeggaResponse<ExploitationControlPointAvailableWaterResponse>();
  private controlPointFrostSurveillanceHistory = new VeggaResponse<ExploitationControlPointAntiFrostSurveillanceResponse>();
  private controlPointByIdResponse = new VeggaResponse<ControlPointDetailItem, HttpErrorResponse>();

  private controlPointHistoriesResponse = new VeggaResponse<ControlPointHistoryResponses>();
  private availableDevicesForControlPointInquiryResponse = new VeggaResponse<ControlPointInquiryDevicesItem[]>();
  private controlPointRequestsResponse = new VeggaResponse<ControlPointInquiryItemListResponse[]>();

  private controlPointsViewHelpResponse = new VeggaResponse<VeggaHelpParsedResponse[]>();
  private controlPointsFormTypesHelpResponse = new VeggaResponse<VeggaHelpParsedResponse[]>();

  private exploitationsApi: ExploitationsApi;
  private controlPointsApi: ControlPointsApi;

  private listLoader = new VeggaLoader();
  private formLoader = new VeggaLoader();
  private historicalDataLoader = new VeggaLoader();

  get isListLoading$() {
    return this.listLoader.isLoading$;
  }

  get isFormLoading$() {
    return this.formLoader.isLoading$;
  }

  get isHistoricalDataLoaderLoading$() {
    return this.historicalDataLoader.isLoading$;
  }

  get controlPointsData$(): Observable<ControlPointDetailItem[]> {
    return this.controlPointsDataResponse.value$;
  }

  get controlPointById$(): Observable<ControlPointDetailItem> {
    return this.controlPointByIdResponse.value$;
  }

  get controlPointByIdError$(): Observable<HttpErrorResponse> {
    return this.controlPointByIdResponse.error$;
  }

  get controlPointHistories$(): Observable<ControlPointHistoryResponses> {
    return this.controlPointHistoriesResponse.value$;
  }

  get availableDevicesForControlPointInquiry$(): Observable<ControlPointInquiryDevicesItem[]> {
    return this.availableDevicesForControlPointInquiryResponse.value$;
  }

  get controlPointRequests$(): Observable<ControlPointRequest[]> {
    return this.controlPointRequestsResponse.value$;
  }

  get controlPointsViewHelp$(): Observable<VeggaHelpParsedResponse[]> {
    return this.controlPointsViewHelpResponse.value$;
  }

  get controlPointsFormTypesHelp$(): Observable<VeggaHelpParsedResponse[]> {
    return this.controlPointsFormTypesHelpResponse.value$;
  }

  constructor() {
    this.exploitationsApi = new ExploitationsApi();
    this.controlPointsApi = new ControlPointsApi();
    this.exploitationsApi.basePath = this.controlPointsApi.basePath = environment.API_IRRIGATION_CONTROL_ENDPOINT;
  }

  loadControlPoints() {
    const req$ = this.exploitationsApi.getAllControlPoints();

    const subscription = handleResponse(req$, this.controlPointsDataResponse).subscribe({
      next: agronomicMonitoring => {
        this.controlPointsDataResponse.set(agronomicMonitoring);
      },
      error: err => {
        this.controlPointsDataResponse.setError(err, {});
      },
    });

    this.listLoader.waitFor(subscription);
  }

  loadControlPointById(controlPointId: number) {
    const req$ = this.exploitationsApi.getControlPoint({ id: controlPointId });

    const subscription = handleResponse(req$, this.controlPointByIdResponse).subscribe({
      next: ckeckpoint => {
        this.controlPointByIdResponse.set(ckeckpoint);
      },
      error: err => {
        this.controlPointByIdResponse.setError(err, {});
      },
    });

    this.listLoader.waitFor(subscription);
  }

  loadControlPointHistoriesByType(type: string, params: GetControlPointDataHistoryRequest) {
    this.veggaFacade.abortRequests();

    const req$ = this.getControlPointHistoryRequests(type, params);
    const subscription = req$.subscribe({
      next: cpHistoryResponses => {
        this.controlPointHistoriesResponse.set(cpHistoryResponses as ControlPointHistoryResponses);
      },
      error: err => {
        this.controlPointHistoriesResponse.setError(err, {});
      },
    });

    this.historicalDataLoader.waitFor(subscription);
  }

  loadAvailableDevicesForControlPointInquiry() {
    const req$ = this.controlPointsApi.getAvailableDevicesForControlPointInquiry();
    const subscription = req$.subscribe({
      next: cpHistoryResponses => {
        this.availableDevicesForControlPointInquiryResponse.set(cpHistoryResponses);
      },
      error: err => {
        this.availableDevicesForControlPointInquiryResponse.setError(err, {});
      },
    });

    this.formLoader.waitFor(subscription);
  }

  loadControlPointRequests(status: ControlPointInquiryStatus) {
    // custom error handling to keep showing cp list in case inquiries has some errors
    const req$ = this.controlPointsApi.listControlPointRequests({ status });
    const subscription = req$
      .pipe(
        catchError(err => {
          this.controlPointRequestsResponse.setError(err, {});
          return of([]);
        }),
      )
      .subscribe(cpRequests => this.controlPointRequestsResponse.set(cpRequests));

    this.listLoader.waitFor(subscription);
  }

  loadControlPointMocks(type: ControlPointRequestTypeEnum) {
    const getCPDetailSamples = () => {
      switch (type) {
        case ControlPointRequestTypeEnum.WEATHER:
          return {
            controlPoint: MOCK_WEATHER_DATA,
            eto: MOCK_ETO_DATA,
            dpv: MOCK_DPV_DATA,
            frostSurveillance: MOCK_ANTI_FROST_SURV,
          } as ControlPointWeatherHistoryResponses;

        case ControlPointRequestTypeEnum.SOIL:
          return {
            nutrientDynamics: MOCK_NUTRI_DYNAMICS,
            availableWater: MOCK_AVAILABLE_WATER,
            campaignIrrigation: MOCK_CAMPAIGN_IRRIGATION,
          } as ControlPointSoilHistoryResponses;
      }
    };
    const cpMock = type === ControlPointRequestTypeEnum.WEATHER ? MOCK_WEATHER_CONTROL_POINT_DATA : MOCK_SOIL_CONTROL_POINT_DATA;
    this.controlPointByIdResponse.set(cpMock as ControlPointDetailItem);
    this.controlPointHistoriesResponse.set(getCPDetailSamples());
  }

  /**
   * Loads control point help by mapping ViewHelpI18nMap
   * into requests
   * @param type
   */
  loadControlPointsDetailHelp(type: ControlPointRequestTypeEnum, lang: string): void {
    const getI18NMap = (type: ControlPointRequestTypeEnum) => {
      switch (type) {
        case ControlPointRequestTypeEnum.WEATHER:
          return ControlPointWeatherViewHelpI18NMap;
        case ControlPointRequestTypeEnum.SOIL:
          return ControlPointSoilViewHelpI18NMap;
      }
    };

    const stepsI18n = getI18NMap(type)
      [lang].map(i18nEntry => i18nEntry)
      .flat() as ControlPointHelpI18NMapItem[];

    const req$ = forkJoin(stepsI18n.map(stepI18n => stepI18n.ids.map(id => this.veggaFacade.getViewHelp(id, stepI18n.step).pipe(map(res => ({ ...res, ...stepI18n }))))).flat());
    req$.subscribe({
      next: viewHelp => {
        this.controlPointsViewHelpResponse.set(viewHelp);
      },
      error: err => {
        this.controlPointsViewHelpResponse.setError(err, {});
      },
    });
  }

  /**
   * Loads control point help by mapping ViewHelpI18nMap
   * into requests
   * @param type
   */
  loadControlPointsFormTypesHelp(lang: string): void {
    const [weatherId] = ControlPointFormWeatherTypeI18NMap[lang].ids;
    const [soilId] = ControlPointFormSoilTypeI18NMap[lang].ids;
    const req$ = forkJoin([this.veggaFacade.getViewHelp(weatherId), this.veggaFacade.getViewHelp(soilId)]);
    req$.subscribe({
      next: formTypesHelp => {
        this.controlPointsFormTypesHelpResponse.set(formTypesHelp);
      },
      error: err => {
        this.controlPointsFormTypesHelpResponse.setError(err, {});
      },
    });
  }

  postControlPointInquiry(controlPointInquiryRequest: ControlPointInquiryRequest): Observable<ControlPointCreationResponse> {
    return this.controlPointsApi
      .postControlPointInquiry({ controlPointInquiryRequest })
      .pipe(tap(r => this.controlPointRequestsResponse.set([...this.controlPointRequestsResponse.value, r])));
  }

  clearControlPointAvailableWaterHistoryResponse(): void {
    this.controlPointAvailableWaterHistory.clearValue();
    this.controlPointFrostSurveillanceHistory.clearValue();
  }

  clearControlPointByIdResponse(): void {
    this.controlPointByIdResponse.clear();
  }

  clearControlPointByIdError(): void {
    this.controlPointByIdResponse.clearError();
  }

  clearControlPointHistoriesResponse(): void {
    this.controlPointHistoriesResponse.clear();
  }

  clearControlPoints(): void {
    this.controlPointsDataResponse.clear();
  }

  /**
   * Joins all CP type graph history methods and injects responses
   * into single response to be consumed from client as object map key-response
   * @param type CP type
   * @param params params for the graph data request
   * @returns Object map as key -> response. Response can be also a HttpResponse
   * this happens when request has been cancelled before ending
   */
  private getControlPointHistoryRequests(
    type: string,
    { id, from, to, minAggregation }: GetControlPointDataHistoryRequest,
  ): Observable<ControlPointHistoryResponses | HttpResponse<HttpOptions>> {
    const weatherAgronomicVarsMethodMap = {
      [AgronomicVariableKey.ETO]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointEto(params, { signal: this.veggaFacade.getAbortSignal() }),
      [AgronomicVariableKey.VAPOR_PRESSURE_DEFICIT]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointDpv({ ...params, minAggregation: true }),
      [AgronomicVariableKey.DEW_POINT]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointDewPoint(params),
      [AgronomicVariableKey.ANTI_FROST_SURVEILLANCE]: (params: GetControlPointDataHistoryRequest) =>
        this.exploitationsApi.getControlPointAntiFrostSurveillance({ ...params, minAggregation: true }),
    } as AgVarMethodMap<ControlPointWeatherHistory>;

    const soilAgronomicVarsMethodMap = {
      [AgronomicVariableKey.AVAILABLE_WATER]: (params: GetControlPointDataHistoryRequest) =>
        this.exploitationsApi.getControlPointAvailableWater({ ...params, minAggregation: true }, { signal: this.veggaFacade.getAbortSignal() }),
      // minAggregation returns data from ten to ten minuts, as demands nutrient dynamic graph requirements
      [AgronomicVariableKey.NUTRIENT_DYNAMICS]: (params: GetControlPointDataHistoryRequest) =>
        this.exploitationsApi.getControlPointNutrientDynamic({ ...params, minAggregation: true }, { signal: this.veggaFacade.getAbortSignal() }),
      [AgronomicVariableKey.CAMPAIGN_IRRIGATION]: (params: GetControlPointDataHistoryRequest) => this.exploitationsApi.getControlPointCampaignIrrigation(params),
    } as AgVarMethodMap<ControlPointSoilHistory>;

    const getFilteredHistoryObservables = (agVarMethodMap: AgVarMethodMap<ControlPointHistoryResponses | HttpResponse<HttpOptions>>) => {
      return Object.keys(agVarMethodMap).map(agVar => {
        return this.controlPointByIdResponse.value.availableAgronomicVariables.includes(agVar as AgronomicVariableKey)
          ? agVarMethodMap[agVar]({ id, from, to, minAggregation }).pipe(
              filter(res => {
                // history requests are cancellable if recall, in such cases, httpclient is returning
                // a httpResponse instead
                const resBody = (res as HttpResponse<HttpOptions>).body;
                if (resBody) {
                  return resBody.status !== 'REQUEST_CANCELLED';
                }

                return true;
              }),
              catchError(() => {
                return of({ series: [] });
              }),
            )
          : of(null);
      });
    };

    switch (type) {
      case ControlPointRequestTypeEnum.WEATHER.toLowerCase():
        return forkJoin([
          this.exploitationsApi.getControlPointDataHistory({ id, from, to, minAggregation }),
          ...getFilteredHistoryObservables(weatherAgronomicVarsMethodMap as AgVarMethodMap<ControlPointHistoryResponses>),
        ]).pipe(
          map(([controlPoint, eto, dpv, dewPoint, frostSurveillance]) => ({ controlPoint, eto, dewPoint, dpv, frostSurveillance })),
        ) as Observable<ControlPointWeatherHistoryResponses>;

      case ControlPointRequestTypeEnum.SOIL.toLowerCase():
        return forkJoin([...getFilteredHistoryObservables(soilAgronomicVarsMethodMap as AgVarMethodMap<ControlPointHistoryResponses>)]).pipe(
          map(([availableWater, nutrientDynamics, campaignIrrigation]) => ({ availableWater, nutrientDynamics, campaignIrrigation })),
        ) as Observable<ControlPointSoilHistoryResponses>;
    }
  }
}
