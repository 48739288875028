export interface LegacyDeviceByListSync {
  deviceId: number;
  totalRecive: number;
  pendingRecive: number;
  totalSend: number;
  pendingSend: number;
  initConsultation: number;
  sendAll: number;
  active: boolean;
}

export interface InnerLegacyDeviceByList {
  numSerie: number;
  name: string;
  connect: number;
  irrigation: number | null;
  anomalies: number;
  type: number;
  id: number;
  lvl: number;
  blocked: boolean;
  version: number;
  breakdown: number | null;
  systemStopMalfunction: boolean;
  conditionalStopMalfunction: boolean | null;
  alarm: boolean | null;
  outService: boolean;
  generalMalfunction: boolean;
  flowMalfunction: boolean;
  counterMalfunction: boolean;
  ferlitzerMalfunction: boolean;
  filterMalfunction: boolean;
  phMalfunction: boolean;
  ceMalfunction: boolean;
  definitiveStopMalfunction: boolean;
  acumulatedDate: string;
  date: string;
  sync: LegacyDeviceByListSync;
}

export interface LegacyDeviceByList {
  device: InnerLegacyDeviceByList;
  level: string;
}

export interface Ram {
  device: number;
  id: number;
  date: string;
  breakdown: number | null;
  systemStopMalfunction: boolean;
  conditionalStopMalfunction: boolean | null;
  alarm: boolean | null;
  outService: boolean;
  generalMalfunction: boolean | null;
  flowMalfunction: boolean | null;
  counterMalfunction: boolean | null;
  ferlitzerMalfunction: boolean | null;
  filterMalfunction: boolean | null;
  phMalfunction: boolean | null;
  ceMalfunction: boolean | null;
  definitiveStopMalfunction: boolean;
  definitiveStopProgramsMalfunction: boolean | null;
  acumulatedDate: string | null;
  tensionVcc: number;
  bateryLoad: number | null;
  tensionRelay: number | null;
  tensionPanel: number | null;
  user1: boolean;
  user2: boolean;
  user3: boolean;
  bateryLvl: number | null;
  tensionBatery: number | null;
  totalErasure: number | null;
  erasureDate: string | null;
  gapMinutes: number;
  lastReception: string;
}

export interface Pk {
  deviceId: string;
  id: string;
}

export interface Header {
  device: number;
  pk: Pk;
  systemStop: boolean;
  outOfService: boolean;
  definitiveStop: boolean;
  irrigating: boolean;
  fertilizing: boolean;
  fechaConsulta: string;
  id: number;
  deviceId: number;
}

export interface Installer {
  unittype: string;
  id: number;
  modified: number;
  modifiedDate: string;
  device: number;
  lang: number;
  smsCode: number;
  paramsCode: number;
  functionsCode: number;
  timeZone: number;
  timeChange: boolean;
  startDaySummer: number;
  endDaySummer: number;
  calSolarPrograms: number | null;
  calSolarNebulization: number | null;
  calSolarIrrigSolar: number | null;
  adjustmentDayRecord: number;
  averageCEPH: number;
  formatRadInt: number;
  a7extensionSD: number;
  delayFlowDetector: number;
  stopFlowProgDetector: number;
  hourRegisterLimit: number;
  timeVolume: boolean;
  irrigDecimals: number;
  prePostIrrigForSubprog: number;
  fertilizerUnits: number;
  fertilizerDecimals: number;
  activeEndOfInitSchedule: number;
  activeEndOfEndSchedule: number;
  headerGeneralFilters: number;
  modulationFastCicle: number;
  modulationSlowCicle: number;
  modifiedBy: number;
  updateSoft: string | null;
  updateFile: string | null;
  startMonthSummer: string | null;
  endMonthSummer: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  installerSolar: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  installerSAnalog: any[];
}

export interface InOptions {
  device: number;
  deviceId: number;
  baseModel: number;
  a4000Latch: number;
  pc: boolean;
  optionWebAgronic: boolean;
  optionPlus: boolean;
  optionHidro: boolean;
  optionPivots: boolean;
  optionSolar: boolean;
  optionClimate: boolean;
  optionMonocable: boolean;
  optionRadio: boolean;
  modem: boolean;
  wifi: boolean;
  modificationDate: string;
  modifiedBy: number;
  queryDate: string;
  agroBeeL1: boolean;
  agroBeeL2: boolean;
}
export interface ConditionersDevice {
  id: number;
  name: string;
  from: string;
  function: number;
}

export interface LegacyDeviceById {
  progtype: string;
  id: number;
  irrigation: boolean;
  connected: boolean;
  numSerie: number;
  name: string;
  type: number;
  email: string | null;
  connect: number;
  socket: string | null;
  refresh_outputs: string | null;
  remoteEmail: string | null;
  idCOMPort: number;
  connectAction: number;
  connectionUser: number;
  userAccess: number;
  version: number;
  latGPS: number;
  lastAccessWeb: string;
  pwdSocket: string | null;
  ram: Ram;
  lvl: string;
  blocked: boolean | null;
  sync: string | null;
  headers: Header[];
  sDigitals: string | null;
  sMeters: string | null;
  sLogics: string | null;
  sAnalogs: string | null;
  sectors: string | null;
  programs: string | null;
  nebulitzation: string | null;
  conditioners: string | null;
  installer: Installer;
  commsGprs45: string | null;
  events45: string | null;
  sectorGroups45: string | null;
  format45: string | null;
  xStateAlarm: number;
  filters: string | null;
  options: string | null;
  meters: string | null;
  flow: string | null;
  sector: string | null;
  meterFert: string | null;
  sanalog: string | null;
  sdigital: string | null;
  gsmphone: string | null;
  lngGPS: number;
  rdmprotocol: number[];
  rdmroute: string;
  rdmalternativeRoute: string | null;
  inoptions: InOptions;
}

export type LegacyDevice = LegacyDeviceByList & LegacyDeviceById;

export const LEGACY_DEVICES_MAP = {
  2: 'A4000',
  3: 'A2500',
  4: 'ABIT',
  5: 'A7000',
  6: 'A5500',
  7: 'A4500',
};

export enum DEVICES {
  A_4000 = 2,
  A_2500 = 3,
  A_BIT = 4,
  A_7000 = 5,
  A_5500 = 6,
  A_4500 = 7,
}
